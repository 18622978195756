import type {
  StudioEmailRecipientResponse,
  StudioEmailResponse,
  StudioMeetingResponse,
  StudioMeetingTypeResponse,
} from '@/typings/globals';
import api from '@/api/marketing';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

export const useMarketingStore = defineStore('marketing', {
  state: ():State => ({}),
  getters: {},
  actions: {
    async loadMeetingTypes(): Promise<PaginatedResponse<StudioMeetingTypeResponse>> {
      return await api.getMeetingTypes();
    },
    async getMeetingType(id): Promise<StudioMeetingTypeResponse> {
      return await api.getMeetingType(id);
    },
    async getPublicMeetingType(
      { studioURL, slug }
    ): Promise<StudioMeetingTypeResponse> {
      return await api.getPublicMeetingType(studioURL, slug);
    },
    async getPublicMeeting(
      { studioURL, meetingUniqueId }
    ): Promise<StudioMeetingResponse> {
      return await api.getPublicMeeting(studioURL, meetingUniqueId);
    },
    async cancelPublicMeeting(
      { studioURL, meetingUniqueId }
    ): Promise<any> {
      return await api.cancelPublicMeeting(studioURL, meetingUniqueId);
    },
    async reschedulePublicMeeting(
      { studioURL, meetingUniqueId, newStartTime, timezone }
    ): Promise<any> {
      return await api.reschedulePublicMeeting(
        studioURL,
        meetingUniqueId,
        newStartTime,
        timezone
      );
    },
    async getPublicMeetingTypeTimeSlots(
      { studioURL, slug, timezone, startDate, endDate }
    ): Promise<StudioMeetingTypeResponse> {
      return await api.getPublicMeetingTypeTimeSlots(
        studioURL,
        slug,
        timezone,
        startDate,
        endDate
      );
    },
    async bookPublicMeetingSlot(
      { studioURL, slug, slot, timezone, bookingFullName, bookingEmail, form }
    ): Promise<StudioMeetingResponse> {
      return await api.bookPublicMeetingSlot(
        studioURL,
        slug,
        slot,
        timezone,
        bookingFullName,
        bookingEmail,
        form
      );
    },
    async deleteMeetingType(id): Promise<any> {
      return await api.deleteMeetingType(id);
    },
    async cancelMeeting(id): Promise<any> {
      return await api.cancelMeeting(id);
    },
    async rescheduleMeeting(
      { id, newStartTime, timezone }
    ): Promise<any> {
      return await api.rescheduleMeeting(id, newStartTime, timezone);
    },
    async getMeetingTypeTimeSlots(
      { id, timezone, startDate, endDate }
    ): Promise<StudioMeetingTypeResponse> {
      return await api.getMeetingTypeTimeSlots(
        id,
        timezone,
        startDate,
        endDate
      );
    },
    async duplicateMeetingType(id): Promise<any> {
      return await api.duplicateMeetingType(id);
    },
    async addMeetingType(data): Promise<StudioMeetingTypeResponse> {
      return await api.addMeetingType(data);
    },
    async updateMeetingType(
      data
    ): Promise<StudioMeetingTypeResponse> {
      return await api.updateMeetingType(data.id, data);
    },
    async getUpcomingMeetings(
      { q, page }
    ): Promise<PaginatedResponse<StudioMeetingResponse>> {
      return await api.getMeetings(page, q, 'upcoming');
    },
    async getPastMeetings(
      { q, page }
    ): Promise<PaginatedResponse<StudioMeetingResponse>> {
      return await api.getMeetings(page, q, 'past');
    },
    async getCancelledMeetings(
      { q, page }
    ): Promise<PaginatedResponse<StudioMeetingResponse>> {
      return await api.getMeetings(page, q, 'cancelled');
    },
    async loadEmails(
      payload
    ): Promise<PaginatedResponse<StudioEmailResponse>> {
      const { page, q, status, startDate, endDate } = payload;
      return await api.getEmails(page, q, status, startDate, endDate);
    },
    async getEmailRecipients(
      payload
    ): Promise<PaginatedResponse<StudioEmailRecipientResponse>> {
      const { studioEmailId, page, q, opened, clicked, unsubscribed } = payload;
      return await api.getEmailRecipients(
        studioEmailId,
        page,
        q,
        opened,
        clicked,
        unsubscribed
      );
    },
    async getEmail(id): Promise<StudioEmailResponse> {
      return await api.getEmail(id);
    },
    async updateEmail(data): Promise<StudioEmailResponse> {
      return await api.updateEmail(data.id, data);
    },
    async addEmail(data): Promise<StudioEmailResponse> {
      return await api.addEmail(data);
    },
    async deleteEmail(id): Promise<any> {
      return await api.deleteEmail(id);
    },
    async duplicateEmail(id): Promise<any> {
      return await api.duplicateEmail(id);
    },
    async sendEmail(id): Promise<StudioEmailResponse> {
      return await api.sendEmail(id);
    },
    async sendTestEmail(id): Promise<StudioEmailResponse> {
      return await api.sendTestEmail(id);
    },
    async getEmailPreview(id): Promise<StudioEmailResponse> {
      return await api.getEmailPreview(id);
    },
    async getEmailAudience(data): Promise<StudioEmailResponse> {
      return await api.getEmailAudience(data);
    },
  },
  mutations: {},
});
