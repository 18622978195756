import api from '@/api/settings';
import { defineStore } from 'pinia';

interface State {
  currencies: CurrencyResponse[] | null;
  domain: DomainResponse | null;
  pagesDomain: DomainResponse | null;
  useCustomDomainUrl: boolean;
  codeEmbed: CodeEmbedResponse | null;
  accountInfo: AccountInfoResponse | null;
  paymentInfo: PaymentInfoResponse | null;
  emailProvider: EmailProviderInfoResponse | null;
  flodeskSegments: FlodeskSegmentResponse[] | null;
  mailChimpLists: MailChimpListsResponse | null;
  convertKitTags: ConvertKitResponse | null;
  fbPixel: FBPixelResponse | null;
  googleAnalyticsCode: GoogleAnalyticsCodeResponse | null;
  zoom: ZoomInfoResponse[] | null;
  waiver: WaiverResponse | null;
  calendly: string | null;
  calendlyV2: CalendlyRequest | null;
  flodesk: FlodeskResponse | null;
  twilio: TwilioRequest | null;
  smtp: SmtpResponse | null;
  invoiceHistory: InvoiceHistoryResponse | null;
  streamingProps: StreamingProps | null;
  acuity: AcuityResponse | null;
  googleCalendars: GoogleCalendarConnectionResponse | null;
  tipJar: TipJarIntegrationResponse | null;
  marvelousBio: BioResponse | null;
  bioTemplates: BioTemplateResponse[] | null;
}

export const useSettingsStore = defineStore('settings', {
  state: ():State => ({
    currencies: null,
    domain: null,
    pagesDomain: null,
    useCustomDomainUrl: false,
    codeEmbed: {} as CodeEmbedResponse,
    accountInfo: null,
    paymentInfo: null,
    emailProvider: null,
    flodeskSegments: null,
    mailChimpLists: null,
    convertKitTags: null,
    fbPixel: null,
    googleAnalyticsCode: null,
    zoom: null,
    waiver: null,
    calendly: null,
    calendlyV2: null,
    flodesk: null,
    twilio: null,
    invoiceHistory: null,
    streamingProps: {
      videoEnabled: true,
      selectedVideoDevice: null,
      audioInputEnabled: true,
      selectedAudioInputDevice: null,
      selectedAudioOutputDevice: null,
      advancedAudioProcessing: true,
      useContainMode: true,
    },
    acuity: null,
    googleCalendars: null,
    smtp: null,
    tipJar: null,
    marvelousBio: null,
    bioTemplates: [],
  }),
  getters: {
    // emailProvider: state => state.emailProvider,
    // flodeskSegments: state => state.flodeskSegments || [],
    // mailChimpLists: state => state.mailChimpLists?.lists || [],
    // convertKitTags: state => state.convertKitTags?.tags || [],
    // isUsingFlodesk: state => state.emailProvider?.email_provider === 'flodesk',
    // isUsingConvertKit: state =>
    //   state.emailProvider?.email_provider === 'convertkit',
    // isUsingMailChimp: state =>
    //   state.emailProvider?.email_provider === 'mailchimp',
    // paymentInfo: state => state.paymentInfo,
    // flodesk: state => state.flodesk,
    // acuity: state => state.acuity,
    // googleCalendars: state => state.googleCalendars,
    // zoomConnections: state => state.zoom,
    // smtp: state => state.smtp,
    // tipJar: state => state.tipJar,
    // marvelousBio: state => state.marvelousBio,
    // bioLinks: state => state.marvelousBio?.bio_links,
    // bioTemplates: state => state.bioTemplates,
    // codeEmbed: state => state.codeEmbed,
    // domain: state => state.domain,
    // isPagesDomainValid: state => {
    //   return (
    //     state.pagesDomain?.success &&
    //     state.pagesDomain?.heroku_response?.content.acm_status ===
    //       'cert issued' &&
    //     state.pagesDomain?.heroku_response?.content.acm_status_reason === ''
    //   );
    // },
    // pagesLocationUrl: (state, getters, rootState, rootGetters) => {
    //   return getters.isPagesDomainValid
    //     ? `https://${state.pagesDomain?.domain}`
    //     : `${process.env.VUE_APP_PAGE_BUILDER_BASE_URL}/${rootGetters['user/teacherStudioUrl']}`;
    // },
    // pagesDomain: state => state.pagesDomain,
    // useCustomDomainUrl: state => state.useCustomDomainUrl,
  },
  actions: {
    // async getInvoiceHistory({ commit }): Promise<InvoiceHistoryResponse> {
    //   const data = await api.getInvoiceHistory();
    //   commit(types.SET_INVOICE_HISTORY, data);
    //   return data;
    // },

    // async getCurrencies({ commit }): Promise<void> {
    //   const result = await api.getCurrencies();
    //   commit(types.SET_CURRENCIES, result);
    // },
    // async getDomain({ commit }): Promise<DomainResponse> {
    //   const result = await api.getDomain();
    //   commit(types.SET_DOMAIN, result);
    //   return result;
    // },
    // async setDomain({ commit }, data: DomainRequest): Promise<DomainResponse> {
    //   const result = await api.setDomain(data);
    //   commit(types.SET_DOMAIN, result);
    //   return result;
    // },
    // async deleteDomain({ commit }): Promise<void> {
    //   const result = await api.deleteDomain();
    //   commit(types.SET_DOMAIN, null);
    //   return result;
    // },
    // async setUseCustomDomainUrl({ commit }, useCustomDomainUrl: boolean) {
    //   commit(types.SET_USE_CUSTOM_DOMAIN_URL, useCustomDomainUrl);
    // },
    // async getPagesDomain({ commit }): Promise<DomainResponse> {
    //   const result = await api.getPagesDomain();
    //   commit(types.SET_PAGES_DOMAIN, result);
    //   return result;
    // },
    // async setPagesDomain(
    //   { commit },
    //   data: DomainRequest
    // ): Promise<DomainResponse> {
    //   const result = await api.setPagesDomain(data);
    //   commit(types.SET_PAGES_DOMAIN, result);
    //   return result;
    // },
    // async deletePagesDomain({ commit }): Promise<void> {
    //   const result = await api.deletePagesDomain();
    //   commit(types.SET_PAGES_DOMAIN, null);
    //   return result;
    // },
    // async toggleCodeEmbed({ commit }, codeEmbedEnabled: boolean) {
    //   const codeEmbed = await api.toggleCodeEmbedEnabled(codeEmbedEnabled);
    //   commit(types.SET_CODE_EMBED, codeEmbed);
    //   return codeEmbed;
    // },
    // async updateCodeEmbedScripts({ commit }, scriptsString: string) {
    //   const codeEmbed = await api.updateCodeEmbedScripts(scriptsString);
    //   commit(types.SET_CODE_EMBED, codeEmbed);
    //   return codeEmbed;
    // },
    // async getCodeEmbed({ commit }) {
    //   const codeEmbed = await api.getCodeEmbed();
    //   commit(types.SET_CODE_EMBED, codeEmbed);
    //   return codeEmbed;
    // },
    // async getAccountInfo({ commit }): Promise<void> {
    //   const result = await api.getAccountInfo();
    //   commit(types.SET_ACCOUNT_INFO, result);
    // },
    // async getAccountSubscriptionInfo(
    //   { commit },
    //   data
    // ): Promise<AccountInfoResponse> {
    //   const result = await api.getAccountSubscriptionInfo(data);
    //   commit(types.SET_ACCOUNT_INFO, result);
    //   return result;
    // },
    // async getPaymentInfo({ commit }): Promise<PaymentInfoResponse> {
    //   const result = await api.getPaymentInfo();
    //   commit(types.SET_PAYMENT_INFO, result);
    //   return result;
    // },
    // async updatePaymentInfo(
    //   _,
    //   data: PaymentInfoRequest
    // ): Promise<PaymentInfoResponse> {
    //   const result = await api.updatePaymentInfo(data);
    //   return result;
    // },
    // async updateCurrency(
    //   _,
    //   currencyCode: string
    // ): Promise<PaymentInfoResponse> {
    //   const result = await api.updateCurrency(currencyCode);
    //   return result;
    // },
    // async connectStripe(_, code: string): Promise<PaymentInfoResponse> {
    //   const result = await api.connectStripe(code);
    //   return result;
    // },
    // async disconnectStripe(_): Promise<PaymentInfoResponse> {
    //   const result = await api.disconnectStripe();
    //   return result;
    // },
    // async getEmailProvider({ commit }): Promise<EmailProviderInfoResponse> {
    //   const result = await api.getEmailProvidersInfo();
    //   commit(types.SET_EMAIL_PROVIDER, result);
    //   return result;
    // },
    // async updateEmailProviderInfo(
    //   _,
    //   data: EmailInfoRequest
    // ): Promise<EmailProviderInfoResponse> {
    //   const result = await api.updateEmailProvidersInfo(data);
    //   return result;
    // },
    // async cancelSubscription(_, payload): Promise<void> {
    //   const result = await api.cancelSubscription(payload);
    //   return result;
    // },
    // async getFlodeskSegments({ commit }): Promise<FlodeskSegmentResponse[]> {
    //   const result = await api.getFlodeskSegments();
    //   commit(types.SET_FLODESK_SEGMENTS, result);
    //   return result;
    // },
    // async getMailChimpLists({ commit }): Promise<MailChimpListsResponse> {
    //   const result = await api.getMailChimpLists();
    //   commit(types.SET_MAIL_CHIMP_LISTS, result);
    //   return result;
    // },
    // async getConvertKitTags({ commit }): Promise<ConvertKitResponse> {
    //   const result = await api.getConvertkitTags();
    //   commit(types.SET_CONVERT_KIT_TAGS, result);
    //   return result;
    // },
    // async getFBPixelID({ commit }): Promise<string> {
    //   const result = await api.getFBPixel();
    //   commit(types.SET_FB_PIXEL, result);
    //   return result.fb_pixel_id;
    // },
    // async getGoogleAnalyticsCode({ commit }): Promise<string> {
    //   const result = await api.getGoogleAnalyticsCode();
    //   commit(types.SET_GOOGLE_ANALYTICS_CODE, result);
    //   return result.google_analytics_code;
    // },
    // async updateFBPixelID(_, FBPixel: string): Promise<FBPixelResponse> {
    //   const data = {
    //     fb_pixel_id: FBPixel,
    //   } as FBPixelResponse;
    //   const result = await api.updateFBPixel(data);
    //   return result;
    // },
    // async updateGoogleAnalyticsCode(
    //   _,
    //   code: string
    // ): Promise<GoogleAnalyticsCodeResponse> {
    //   const data = {
    //     google_analytics_code: code,
    //   } as GoogleAnalyticsCodeResponse;
    //   const result = await api.updateGoogleAnalyticsCode(data);
    //   return result;
    // },
    // async getZoomSettings({ commit }): Promise<ZoomSettingsResponse> {
    //   const result = await api.getZoomSettings();
    //   return result;
    // },
    // async updateZoomSettings(
    //   _,
    //   data: ZoomSettingsRequest
    // ): Promise<ZoomSettingsResponse> {
    //   const result = await api.updateZoomSettings(data);
    //   return result;
    // },
    // async getGoogleCalendarConnections({
    //   commit,
    // }): Promise<GoogleCalendarConnectionResponse[]> {
    //   const result = await api.getGoogleCalendarConnections();
    //   commit(types.SET_GOOGLE_CALENDARS, result);
    //   return result;
    // },
    // async addGoogleCalendarConnection(_, { code, scope }): Promise<void> {
    //   await api.addGoogleCalendarConnection(code, scope);
    // },
    // async updateGoogleCalendarConnection(
    //   _,
    //   data: GoogleCalendarRequest
    // ): Promise<void> {
    //   await api.updateGoogleCalendarConnection(data);
    // },
    // async setGoogleCalendarDefaultConnection(
    //   _,
    //   connectionId: number
    // ): Promise<void> {
    //   await api.setGoogleCalendarDefaultConnection(connectionId);
    // },
    // async refreshGoogleCalendarConnection(
    //   _,
    //   connectionId: number
    // ): Promise<void> {
    //   await api.refreshGoogleCalendarConnection(connectionId);
    // },
    // async setGoogleCalendarConnectionName(
    //   _,
    //   { connectionId, name }
    // ): Promise<void> {
    //   await api.setGoogleCalendarConnectionName(connectionId, name);
    // },
    // async removeGoogleCalendarConnection(
    //   _,
    //   connectionId: number
    // ): Promise<void> {
    //   await api.removeGoogleCalendarConnection(connectionId);
    // },
    // async getZoomConnections({ commit }): Promise<ZoomInfoResponse[]> {
    //   const result = await api.getZoomConnections();
    //   commit(types.SET_ZOOM, result);
    //   return result;
    // },
    // async addZoomConnection(_, zoomOauthCode: string): Promise<void> {
    //   await api.addZoomConnection(zoomOauthCode);
    // },
    // async updateZoomConnection(_, data: ZoomRequest): Promise<void> {
    //   await api.updateZoomConnection(data);
    // },
    // async setZoomDefaultConnection(_, zoomConnectionId: number): Promise<void> {
    //   await api.setZoomDefaultConnection(zoomConnectionId);
    // },
    // async refreshZoomConnection(_, zoomConnectionId: number): Promise<void> {
    //   await api.refreshZoomConnection(zoomConnectionId);
    // },
    // async setZoomConnectionName(_, { zoomConnectionId, name }): Promise<void> {
    //   await api.setZoomConnectionName(zoomConnectionId, name);
    // },
    // async removeZoomConnection(_, zoomConnectionId: number): Promise<void> {
    //   await api.removeZoomConnection(zoomConnectionId);
    // },
    // async getWaiver({ commit }): Promise<void> {
    //   const result = await api.getWaiver();
    //   commit(types.SET_WAIVER, result);
    // },
    // async getWaivers(_): Promise<WaiverResponse[]> {
    //   return await api.getWaivers();
    // },
    // async getWaiverSigners(_, waiverId): Promise<SignedWaiverResponse[]> {
    //   return await api.getWaiverSigners(waiverId);
    // },
    // async updateWaiver(_, data: WaiverRequest): Promise<WaiverResponse> {
    //   const result = await api.updateWaiver(data);
    //   return result;
    // },
    // async getCalendly({ commit }): Promise<string> {
    //   const result = await api.getCalendly();
    //   commit(types.SET_CALENDLY, result);
    //   return result;
    // },
    // async updateCalendly(_, calendlyApiKey: string): Promise<string> {
    //   const data = {
    //     calendly_api_key: calendlyApiKey,
    //   } as CalendlyRequest;

    //   const result = await api.updateCalendly(data);
    //   return result;
    // },
    // async getCalendlyV2({ commit }): Promise<CalendlyV2Request> {
    //   const result = await api.getCalendlyV2();
    //   commit(types.SET_CALENDLY_V2, result);
    //   return result;
    // },
    // async updateCalendlyV2(_, calendly: CalendlyV2Request): Promise<string> {
    //   const result = await api.updateCalendlyV2(calendly);
    //   return result;
    // },
    // async getFlodesk({ commit }): Promise<FlodeskResponse> {
    //   const result = await api.getFlodesk();
    //   commit(types.SET_FLODESK, result);
    //   return result;
    // },
    // async updateFlodesk({ commit }, data: FlodeskRequest): Promise<string> {
    //   const result = await api.updateFlodesk(data);
    //   commit(types.SET_FLODESK, result);
    //   return result;
    // },
    // async getTwilio({ commit }): Promise<TwilioRequest> {
    //   const result = await api.getTwilio();
    //   commit(types.SET_TWILIO, result);
    //   return result;
    // },
    // async updateTwilio(_, twilio: TwilioRequest): Promise<string> {
    //   const result = await api.updateTwilio(twilio);
    //   return result;
    // },
    // async sendSMS(_, sms: SMSRequest): Promise<string> {
    //   const result = await api.sendSMS(sms);
    //   return result;
    // },
    // async getCheckoutSettings({
    //   commit,
    // }): Promise<AccountCheckoutSettingsResponse> {
    //   const result = await api.getCheckoutSettings();
    //   return result;
    // },
    // async updateCheckoutSettings(
    //   _,
    //   data: AccountCheckoutSettingsResponse
    // ): Promise<AccountCheckoutSettingsResponse> {
    //   const result = await api.updateCheckoutSettings(data);
    //   return result;
    // },
    // async getSmtpInfo({ commit }): Promise<SmtpResponse> {
    //   const result = await api.getSmtpInfo();
    //   commit(types.SET_SMTP, result);
    //   return result;
    // },
    // async updateSmtpInfo(_, data: SmtpResponse): Promise<SmtpResponse> {
    //   const result = await api.updateSmtpInfo(data);
    //   return result;
    // },
    // async getAcuityInfo({ commit }): Promise<AcuityResponse> {
    //   const result = await api.getAcuityInfo();
    //   commit(types.SET_ACUITY, result);
    //   return result;
    // },
    // async updateAcuityInfo(
    //   _,
    //   acuityInfo: AcuityRequest
    // ): Promise<AcuityResponse> {
    //   const result = await api.updateAcuityInfo(acuityInfo);
    //   return result;
    // },
    // async getDeadlineFunnelKey(): Promise<string> {
    //   const result = await api.getDeadlineFunnelKey();
    //   return result;
    // },
    // async updateDeadlineFunnelKey(
    //   _,
    //   deadline_funnel_key: string
    // ): Promise<string> {
    //   const data = {
    //     deadline_funnel_key,
    //   } as DeadlineFunnelRequest;
    //   const result = await api.updateDeadlineFunnelKey(data);
    //   return result;
    // },
    // async getRewardfulKey(): Promise<string> {
    //   const result = await api.getRewardfulKey();
    //   return result;
    // },
    // async updateRewardfulKey(_, rewardful_api_key: string): Promise<string> {
    //   const data = {
    //     rewardful_api_key,
    //   } as RewardfulRequest;
    //   const result = await api.updateRewardfulKey(data);
    //   return result;
    // },
    // async createStudent(_, data) {
    //   return await api.createStudent(data);
    // },
    setStreamingProps(props: StreamingProps) {
      this.streamingProps = { ...this.streamingProps, ...props };
    },
    // async getTipJarInfo({ commit }) {
    //   const result = await api.getTipJarInfo();
    //   commit(types.SET_TIP_JAR_INTEGRATION, result);
    //   return result;
    // },
    // async updateTipJarInfo(_, data: TipJarIntegrationRequest) {
    //   return await api.updateTipJarInfo(data);
    // },
    // async getBioTemplates({ commit }): Promise<BioTemplateResponse> {
    //   const result = await api.getBioTemplates();
    //   commit(types.SET_BIO_TEMPLATES, result);
    //   return result;
    // },
    // async getMarvelousBioInfo({ commit }): Promise<BioResponse> {
    //   const result = await api.getMarvelousBioInfo();
    //   commit(types.SET_MARVELOUS_BIO, result);
    //   return result;
    // },
    // async updateMarvelousBioInfo(
    //   { commit },
    //   data: BioResponse
    // ): Promise<BioResponse> {
    //   const result = await api.updateMarvelousBioInfo(data);
    //   commit(types.SET_MARVELOUS_BIO, result);
    //   return result;
    // },
    // async addBioLink({ commit }, data: BioLink) {
    //   const bioLink = await api.addBioLink(data);
    //   commit(types.ADD_MARVELOUS_BIO_LINK, bioLink);
    //   return bioLink;
    // },
    // async updateBioLink({ commit }, data: BioLink) {
    //   const updatedBioLink = await api.updateBioLink(data);
    //   commit(types.UPDATE_MARVELOUS_BIO_LINK, updatedBioLink);
    //   return updatedBioLink;
    // },
    // async deleteBioLink({ commit }, id: number) {
    //   const result = await api.deleteBioLink(id);
    //   commit(types.REMOVE_MARVELOUS_BIO_LINK, id);
    //   return result;
    // },
    // async reorderBioLinks({ commit, dispatch }, links: Array<BioLink>) {
    //   await Promise.all(links.map(link => dispatch('updateBioLink', link)));
    // },
    // resetState({ commit }) {
    //   commit(types.RESET_SETTING_STATE);
    // },
  },
  // mutations: {
  //   [types.SET_CURRENCIES](state: State, data: CurrencyResponse[]) {
  //     state.currencies = data;
  //   },
  //   [types.SET_DOMAIN](state: State, data: DomainResponse) {
  //     state.domain = data;
  //   },
  //   [types.SET_PAGES_DOMAIN](state: State, data: DomainResponse) {
  //     state.pagesDomain = data;
  //   },
  //   [types.SET_USE_CUSTOM_DOMAIN_URL](
  //     state: State,
  //     useCustomDomainUrl: boolean
  //   ) {
  //     state.useCustomDomainUrl = useCustomDomainUrl;
  //   },
  //   [types.SET_CODE_EMBED](state: State, data: CodeEmbedResponse) {
  //     state.codeEmbed = data;
  //   },
  //   [types.SET_ACCOUNT_INFO](state: State, data: AccountInfoResponse) {
  //     state.accountInfo = data;
  //   },
  //   [types.SET_PAYMENT_INFO](state: State, data: PaymentInfoResponse) {
  //     state.paymentInfo = data;
  //   },
  //   [types.SET_EMAIL_PROVIDER](state: State, data: EmailProviderInfoResponse) {
  //     state.emailProvider = data;
  //   },
  //   [types.SET_FLODESK_SEGMENTS](state: State, data: FlodeskSegmentResponse[]) {
  //     state.flodeskSegments = data;
  //   },
  //   [types.SET_MAIL_CHIMP_LISTS](state: State, data: MailChimpListsResponse) {
  //     state.mailChimpLists = data;
  //   },
  //   [types.SET_CONVERT_KIT_TAGS](state: State, data: ConvertKitResponse) {
  //     state.convertKitTags = data;
  //   },
  //   [types.SET_FB_PIXEL](state: State, data: FBPixelResponse) {
  //     state.fbPixel = data;
  //   },
  //   [types.SET_GOOGLE_ANALYTICS_CODE](
  //     state: State,
  //     data: GoogleAnalyticsCodeResponse
  //   ) {
  //     state.googleAnalyticsCode = data;
  //   },
  //   [types.SET_ZOOM](state: State, data: ZoomInfoResponse[]) {
  //     state.zoom = data;
  //   },
  //   [types.SET_WAIVER](state: State, data: WaiverResponse) {
  //     state.waiver = data;
  //   },
  //   [types.SET_CALENDLY](state: State, data: string) {
  //     state.calendly = data;
  //   },
  //   [types.SET_CALENDLY_V2](state: State, data: CalendlyRequest) {
  //     state.calendlyV2 = data;
  //   },
  //   [types.SET_FLODESK](state: State, data: FlodeskResponse) {
  //     state.flodesk = data;
  //   },
  //   [types.SET_TWILIO](state: State, data: TwilioRequest) {
  //     state.twilio = data;
  //   },
  //   [types.SET_SMTP](state: State, data: SmtpResponse) {
  //     state.smtp = data;
  //   },
  //   [types.SET_ACUITY](state: State, data: AcuityResponse) {
  //     state.acuity = data;
  //   },
  //   [types.SET_GOOGLE_CALENDARS](
  //     state: State,
  //     data: GoogleCalendarConnectionResponse
  //   ) {
  //     state.googleCalendars = data;
  //   },
  //   [types.SET_INVOICE_HISTORY](state: State, data: InvoiceHistoryResponse) {
  //     state.invoiceHistory = data;
  //   },
  //   [types.SET_STREAMING_PROPS](state: State, props: StreamingProps) {
  //     state.streamingProps = { ...state.streamingProps, ...props };
  //   },
  //   [types.SET_TIP_JAR_INTEGRATION](
  //     state: State,
  //     data: TipJarIntegrationResponse
  //   ) {
  //     state.tipJar = data;
  //   },
  //   [types.SET_MARVELOUS_BIO](state: State, data: BioResponse) {
  //     state.marvelousBio = { ...state.marvelousBio, ...data };
  //   },
  //   [types.ADD_MARVELOUS_BIO_LINK](state: State, link: BioLink) {
  //     if (!state.marvelousBio) return;
  //     state.marvelousBio.bio_links = [...state.marvelousBio.bio_links, link];
  //   },
  //   [types.UPDATE_MARVELOUS_BIO_LINK](state: State, link: BioLink) {
  //     if (!state.marvelousBio) return;
  //     state.marvelousBio.bio_links = [
  //       ...state.marvelousBio.bio_links.filter(item => item.id !== link.id),
  //       link,
  //     ];
  //   },
  //   [types.REMOVE_MARVELOUS_BIO_LINK](state: State, id: number) {
  //     if (!state.marvelousBio) return;
  //     state.marvelousBio.bio_links = state.marvelousBio.bio_links.filter(
  //       item => item.id !== id
  //     );
  //   },
  //   [types.SET_BIO_TEMPLATES](state: State, data: BioTemplateResponse[]) {
  //     state.bioTemplates = data;
  //   },
  //   [types.RESET_SETTING_STATE](state: State) {
  //     Object.assign(state, {}, initialState);
  //   },
  // },
  persist: {
    paths: ['streamingProps', 'useCustomDomainUrl'],
  },
});