import { defineStore } from 'pinia';
import api from '~/api/podcasts';

interface State {
  podcasts: PodcastResponse[] | null;
  studioPodcasts: PodcastResponse[] | null;
}

export const usePodcastsStore = defineStore('podcasts', {
  state: ():State => {
    return {
      podcasts: [],
      studioPodcasts: [],
    }
  },
  actions: {
    async loadPodcasts() {
      this.podcasts = await api.getPodcasts();
      const groups = await api.getPodcasts();
      this.podcasts = groups;
      return this.podcasts;
    },
    async loadStudioPodcasts({ studioURL }) {
      return await api.loadStudioPodcasts(studioURL);
    },
    async loadStudioPodcast({ studioURL, podcastId }) {
      return await api.loadStudioPodcast(studioURL, podcastId);
    },
    async loadStudioRecentEpisodes({ studioURL }) {
      return await api.loadStudioRecentEpisodes(studioURL);
    },
    async getPodcast(podcastId: number) {
      return await api.getPodcast(podcastId);
    },
    async deletePodcast(podcastId: number) {
      return await api.deletePodcast(podcastId);
    },
    async addPodcast(podcast: PodcastResponse) {
      return await api.createPodcast(podcast);
    },
    async editPodcast(podcast) {
      return await api.updatePodcast(podcast);
    },
    async setPodcastActiveFlag({ podcastId, active }) {
      return await api.setPodcastActiveFlag(podcastId, active);
    },
    async getPodcastEpisodes(podcastId: number) {
      return await api.getPodcastEpisodes(podcastId);
    },
    async getPodcastEpisode({ podcastId, episodeId }) {
      return await api.getPodcastEpisode(podcastId, episodeId);
    },
    async addPodcastEpisode({ podcastId, episode }) {
      return await api.addPodcastEpisode(podcastId, episode);
    },
    async updatePodcastEpisode({ podcastId, episode }) {
      return await api.updatePodcastEpisode(podcastId, episode);
    },
    async publishPodcastEpisode({ podcastId, episodeId }) {
      return await api.publishPodcastEpisode(podcastId, episodeId);
    },
    async deletePodcastEpisode({ podcastId, episodeId }) {
      return await api.deletePodcastEpisode(podcastId, episodeId);
    },
    async reorderEpisodes({ podcastId, episodes }) {
      await Promise.all(
        episodes.forEach(episode =>
          this.updatePodcastEpisode({ podcastId, episode })
          // dispatch('updatePodcastEpisode', { podcastId, episode })
        )
      );
    },
    async loadMembers({ podcastId, q, page }) {
      return await api.loadMembers(podcastId, q, page);
    },
    banMember({ podcastId, memberId }) {
      return api.banMember(podcastId, memberId);
    },
    unBanMember({ podcastId, memberId }) {
      return api.unBanMember(podcastId, memberId);
    },
  },
  // mutations: {
  //   [types.SET_PODCASTS](state: State, podcasts: PodcastResponse[]) {
  //     state.podcasts = podcasts;
  //   },
  // },
});
