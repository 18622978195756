import axios from 'axios';
import { setApi } from '@/api/api';
import { useGlobalsStore, useUserStore } from '@/stores';
import Cookies from 'js-cookie';

export default defineNuxtPlugin((nuxtApp) => {
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
  });
  const accessToken = useCookie('accessToken');

  api.interceptors.request.use((config) => {

    let accessTokenValue;
    if(process.client) {
      accessTokenValue = Cookies.get('accessToken');
    } else {
      accessTokenValue = accessToken.value;
    }

    if (accessTokenValue) {
      config.headers['Authorization'] = `Token ${accessTokenValue}`;
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      const xVersion = response.headers['x-version'];
      if (xVersion) {
        useGlobalsStore().setReleaseVersion(Number(xVersion));
      }
      return response;
    }
  );
  
  setApi(api);
  nuxtApp.provide('api', api);
});