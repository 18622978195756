import { api } from './api';

const COMMUNITY_BASE_URL = 'api/community';
const COMMUNITY_USERS_URL = 'api/community-users';
const COMMUNITY_GROUPS_URL = 'api/communitygroups';
const STUDIOS_BASE_URL = 'api/studios';

async function loadComments(
  entityOwnerId: number,
  entityType: string,
  entityId: number,
  isStudio = false,
  page: number,
  commentId
): Promise<CommunityCommentResponse[]> {
  const result = await api.get(
    `${COMMUNITY_BASE_URL}/comments/${entityOwnerId}/${entityType}/${entityId}`,
    {
      params: {
        is_studio: isStudio,
        page: page,
        comment_id: commentId,
      },
    }
  );
  return result.data;
}

async function loadScheduledComments(
  entityOwnerId: number,
  entityType: string,
  entityId: number,
  isStudio = false
): Promise<CommunityCommentResponse[]> {
  const result = await api.get(
    `${COMMUNITY_BASE_URL}/comments/${entityOwnerId}/${entityType}/${entityId}/scheduled`,
    {
      params: {
        is_studio: isStudio,
      },
    }
  );
  return result.data;
}

async function searchComments(
  entityOwnerId: number,
  entityType: string,
  entityId: number,
  isStudio: boolean,
  query: string,
  page: number
): Promise<CommunityCommentResponse[]> {
  const result = await api.get(
    `${COMMUNITY_BASE_URL}/comments/${entityOwnerId}/${entityType}/${entityId}/search/`,
    {
      params: {
        is_studio: isStudio,
        query: query,
        page: page,
      },
    }
  );
  return result.data;
}

async function postComment(
  parentId: number,
  entityOwnerId: number,
  entityType: string,
  entityId: number,
  content: string,
  text: string,
  additionalData: string,
  isStudio: boolean,
  attachment: object,
  hashtag: string,
  releaseOn: Date
): Promise<CommunityCommentResponse[]> {
  const payload = {
    is_studio: isStudio,
    content: content,
    text: text,
    parent_id: parentId,
    additional_data: additionalData,
    attachment,
    hashtag,
    release_on: releaseOn,
  };
  const result = await api.post(
    `${COMMUNITY_BASE_URL}/comments/${entityOwnerId}/${entityType}/${entityId}`,
    payload
  );
  return result.data;
}

async function editComment(
  commentId: number,
  content: string,
  text: string,
  additionalData: string,
  isStudio: boolean,
  attachment: object,
  releaseOn: Date,
  hashtag: string | null
): Promise<CommunityCommentResponse[]> {
  const payload = {
    is_studio: isStudio,
    content: content,
    text: text,
    additional_data: additionalData,
    attachment,
    release_on: releaseOn,
    hashtag,
  };
  const result = await api.post(
    `${COMMUNITY_BASE_URL}/comments/${commentId}/edit`,
    payload
  );
  return result.data;
}

async function likeComment(commentId, isStudio = false) {
  const payload = {
    is_studio: isStudio,
  };
  const result = await api.post(
    `${COMMUNITY_BASE_URL}/comments/${commentId}/like`,
    payload
  );
  return result.data;
}

async function unlikeComment(commentId, isStudio = false) {
  const payload = {
    is_studio: isStudio,
  };
  const result = await api.post(
    `${COMMUNITY_BASE_URL}/comments/${commentId}/unlike`,
    payload
  );
  return result.data;
}

async function hideComment(commentId, isStudio = false) {
  const payload = {
    is_studio: isStudio,
  };
  const result = await api.post(
    `${COMMUNITY_BASE_URL}/comments/${commentId}/hide`,
    payload
  );
  return result.data;
}

async function showComment(commentId, isStudio = false) {
  const payload = {
    is_studio: isStudio,
  };
  const result = await api.post(
    `${COMMUNITY_BASE_URL}/comments/${commentId}/show`,
    payload
  );
  return result.data;
}

async function deleteComment(commentId, isStudio = false) {
  const payload = {
    is_studio: isStudio,
  };
  const result = await api.post(
    `${COMMUNITY_BASE_URL}/comments/${commentId}/delete`,
    payload
  );
  return result.data;
}

async function undeleteComment(commentId, isStudio = false) {
  const payload = {
    is_studio: isStudio,
  };
  const result = await api.post(
    `${COMMUNITY_BASE_URL}/comments/${commentId}/undelete`,
    payload
  );
  return result.data;
}

async function loadUserData(
  studioURL: string,
  uuid: string
): Promise<CommunityProfileResponse[]> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/community-users/${uuid}`
  );
  return result.data;
}

async function loadCommunityProfileData(
  isStudio = false
): Promise<CommunityCommentResponse[]> {
  const result = await api.get(`api/community/profiles`, {
    params: {
      is_studio: isStudio,
    },
  });
  return result.data;
}

async function getNotificationsToken(
  isStudio = false
): Promise<CommunityTokenResponse> {
  const result = await api.get<CommunityTokenResponse>(
    `api/community/profiletoken`,
    {
      params: {
        is_studio: isStudio,
      },
    }
  );
  return result.data;
}

async function saveCommunityProfileData(
  data: CommunityProfileResponse
): Promise<CommunityCommentResponse[]> {
  const result = await api.put(`api/community/profiles`, data);
  return result.data;
}

async function searchUsers(
  studioURL: string,
  query: string
): Promise<CommunityCommentResponse[]> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/community-users/search?q=${query}`
  );
  return result.data;
}

async function loadNotifications(
  markSeen: boolean,
  offset = 0,
  limit = 25,
  isStudio = false
): Promise<CommunityNotificationsResponse> {
  const result = await api.get(`api/community/notifications`, {
    params: {
      mark_seen: markSeen,
      offset: offset,
      limit: limit,
      is_studio: isStudio,
    },
  });
  return result.data;
}

async function markNotificationSeen(
  notificationId: boolean,
  isStudio = false
): Promise<CommunityNotificationsResponse> {
  const payload = {
    notification_id: notificationId,
    is_studio: isStudio,
  };
  const result = await api.post(`api/community/notifications`, payload);
  return result.data;
}

async function loadGiphy(
  searchURL: string,
  apiKey: string,
  searchWord: string,
  searchLimit: number,
  offset: number
): Promise<any[]> {
  const result = await api.get(
    `${searchURL}&api_key=${apiKey}&q=${searchWord}&rating=g&limit=${searchLimit}&offset=${offset}`
  );
  return result.data;
}

async function loadGroup(id: number): Promise<any> {
  const result = await api.get(`${COMMUNITY_GROUPS_URL}/${id}`);
  return result.data;
}

async function loadGroups(): Promise<any> {
  const result = await api.get(`${COMMUNITY_GROUPS_URL}`);
  return result.data;
}

async function addGroup(
  group_name: string,
  cover_photo: number,
  thumbnail_photo: number,
  products: Array<number>,
  hashtags_enabled: boolean,
  hashtags: Array<string>,
  display_members: boolean
): Promise<CommunityGroupsResponse> {
  const result = await api.post(`${COMMUNITY_GROUPS_URL}`, {
    group_name,
    cover_photo,
    thumbnail_photo,
    products,
    hashtags_enabled,
    hashtags,
    display_members,
  });
  return result.data;
}

async function editGroup(
  id: number,
  group_name: string,
  cover_photo: number,
  thumbnail_photo: number,
  products: Array<number>,
  hashtags_enabled: boolean,
  hashtags: Array<string>,
  display_members: boolean
): Promise<CommunityGroupsResponse> {
  const result = await api.put(`${COMMUNITY_GROUPS_URL}/${id}`, {
    group_name,
    cover_photo,
    thumbnail_photo,
    products,
    hashtags_enabled,
    hashtags,
    display_members,
  });
  return result.data;
}

async function editGroupDescription(
  id: number,
  about_text: string
): Promise<CommunityGroupsResponse> {
  const result = await api.patch(`${COMMUNITY_GROUPS_URL}/${id}`, {
    about_text,
  });
  return result.data;
}

async function archiveGroup(
  id: number,
  active: boolean
): Promise<CommunityGroupsResponse> {
  const result = await api.patch(`${COMMUNITY_GROUPS_URL}/${id}`, {
    active,
  });
  return result.data;
}

async function loadMembers(
  groupId: number,
  q: string | null,
  page = 1
): Promise<any> {
  const result = await api.get(
    `${COMMUNITY_GROUPS_URL}/${groupId}/members?page=${page}`,
    {
      params: { q },
    }
  );
  return result.data;
}

async function getMember(groupId: number, memberId: number): Promise<any> {
  const result = await api.get(
    `${COMMUNITY_GROUPS_URL}/${groupId}/members/${memberId}`
  );
  return result.data;
}

async function banMember(groupId: number, memberId: number): Promise<any> {
  const result = await api.post(
    `${COMMUNITY_GROUPS_URL}/${groupId}/members/${memberId}/ban`
  );
  return result.data;
}

async function unBanMember(groupId: number, memberId: number): Promise<any> {
  const result = await api.post(
    `${COMMUNITY_GROUPS_URL}/${groupId}/members/${memberId}/unban`
  );
  return result.data;
}

async function flagComment(commentId: number): Promise<any> {
  const result = await api.post(
    `${COMMUNITY_BASE_URL}/comments/${commentId}/flag`
  );
  return result.data;
}

async function unFlagComment(commentId: number): Promise<any> {
  const result = await api.post(
    `${COMMUNITY_BASE_URL}/comments/${commentId}/unflag`
  );
  return result.data;
}

async function pinComment(commentId: number): Promise<any> {
  const result = await api.post(
    `${COMMUNITY_BASE_URL}/comments/${commentId}/pin`
  );
  return result.data;
}

async function unPinComment(commentId: number): Promise<any> {
  const result = await api.post(
    `${COMMUNITY_BASE_URL}/comments/${commentId}/unpin`
  );
  return result.data;
}

async function loadFlaggedComments(
  page = 1,
  q: string | null,
  ordering: string | null
): Promise<PaginatedResponse<any>> {
  let url = `${COMMUNITY_BASE_URL}/comments/flagged?page=${page}`;
  if (q) url += `&q=${encodeURIComponent(q)}`;
  if (ordering) url += `&ordering=${encodeURIComponent(ordering)}`;

  const result = await api.get<PaginatedResponse<any>>(url);
  return result.data;
}

async function loadStudioGroups(studioURL: string): Promise<any[]> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/community-groups`
  );
  return result.data;
}

async function loadStudioGroup(
  studioURL: string,
  groupId: number
): Promise<any[]> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/community-groups/${groupId}`
  );
  return result.data;
}

async function loadCommunityGroupMembers(
  studioURL: string,
  groupId: number,
  q: string,
  page = 1,
  cancelToken
): Promise<any[]> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/community-groups/${groupId}/members?page=${page}`,
    { params: { q }, cancelToken }
  );
  return result.data;
}

async function loadStudioMember(
  studioURL: string,
  groupId: number,
  memberId: number
): Promise<any[]> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/community-groups/${groupId}/members/${memberId}`
  );
  return result.data;
}

async function createPublicChatRoom(studioURL, payload) {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/chatrooms`,
    payload
  );
  return result.data;
}

async function editPublicChatRoom(studioURL, roomId, payload) {
  const result = await api.put(
    `${STUDIOS_BASE_URL}/${studioURL}/chatrooms/${roomId}`,
    payload
  );
  return result.data;
}

async function loadChatRooms(
  studioURL: string,
  q: string,
  id: string | null,
  page = 1
): Promise<any[]> {
  const result = await api.get(`${STUDIOS_BASE_URL}/${studioURL}/chatrooms`, {
    params: {
      q,
      id,
      page,
    },
  });
  return result.data;
}

async function getCommentLikes(
  page = 1,
  id: number | null
): Promise<PaginatedResponse<any>> {
  const url = `${COMMUNITY_BASE_URL}/comments/${id}/get-likes?page=${page}`;

  const result = await api.get<PaginatedResponse<any>>(url);
  return result.data;
}

async function createPrivateChatRoom(studioURL, member) {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/chatrooms/get-private-room`,
    { member }
  );
  return result.data;
}

async function getPrivateChatRoom(studioURL, roomId) {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/chatrooms/get-private-room/${roomId}`
  );
  return result.data;
}

async function getPublicChatRoom(studioURL, roomId) {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/chatrooms/${roomId}`
  );
  return result.data;
}

async function loadChatRoomMembers(studioURL, roomId) {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/chatrooms/${roomId}/members`
  );
  return result.data;
}

async function addMemberToChatRoom(studioURL, member, roomId) {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/chatrooms/${roomId}/add-member`,
    { member }
  );
  return result.data;
}

async function removeMemberFromChatRoom(studioURL, member, roomId) {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/chatrooms/${roomId}/remove-member`,
    { member }
  );
  return result.data;
}

async function rejoinGroup(groupId, studioURL) {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/community-groups/${groupId}/rejoin-group`
  );
  return result.data;
}

async function leaveGroup(groupId, studioURL) {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/community-groups/${groupId}/leave-group`
  );
  return result.data;
}

export default {
  loadComments,
  loadScheduledComments,
  searchComments,
  postComment,
  editComment,
  likeComment,
  unlikeComment,
  loadUserData,
  loadCommunityProfileData,
  saveCommunityProfileData,
  searchUsers,
  loadNotifications,
  markNotificationSeen,
  getNotificationsToken,
  loadGiphy,
  loadGroup,
  loadGroups,
  addGroup,
  editGroup,
  archiveGroup,
  loadMembers,
  getMember,
  banMember,
  unBanMember,
  flagComment,
  unFlagComment,
  pinComment,
  unPinComment,
  loadFlaggedComments,
  hideComment,
  showComment,
  deleteComment,
  undeleteComment,
  loadStudioGroups,
  loadStudioGroup,
  loadCommunityGroupMembers,
  loadStudioMember,
  editGroupDescription,
  createPublicChatRoom,
  editPublicChatRoom,
  loadChatRooms,
  getCommentLikes,
  createPrivateChatRoom,
  getPrivateChatRoom,
  getPublicChatRoom,
  addMemberToChatRoom,
  removeMemberFromChatRoom,
  loadChatRoomMembers,
  rejoinGroup,
  leaveGroup,
};
