import { defineStore } from 'pinia';

interface State {
  filters: Record<string, any>
}

export const useFiltersStore = defineStore('filters', {
  state: ():State => ({
    filters: {},
  }),
  getters: {
    // filters: state => state.filters,
  },
  actions: {
    getFilter(key: string) {
      const filter = (this.filters || [])[key];
      if (filter) return JSON.parse(JSON.stringify(filter));
    },
    setFilter({ key, filter }: { key: string; filter: any }) {
      this.filters[key] = filter;
    },
  },
});
