import { VueFinalModal, useModal, useModalSlot, useVfm } from 'vue-final-modal'
import { h } from 'vue'

export default defineNuxtPlugin((nuxtApp) => {
  const modal = {
    show(component, props, attrs={}) {
      attrs = {
        zIndexFn: ({ index }: { index: number }) => 1040 + 2 * index,
        ...attrs,
      };

      const hmModalClasses = ['hm-modal'];
      if (attrs.contentClass) {
        if(Array.isArray(attrs.contentClass)) {
          hmModalClasses.push(...attrs.contentClass);
        } else if(typeof attrs.contentClass === 'string' && attrs.contentClass.trim() !== '') {
          hmModalClasses.push(attrs.contentClass);
        }
        delete attrs.contentClass;
      }

      const modal = useModal({
        component: VueFinalModal,
        attrs: {
          ...attrs,
        },
        slots: {
          default: useModalSlot({
            component: {
              setup() {
                return () => h('div', { class: hmModalClasses.join(' ') }, [
                  h('div', {
                    class: 'hm-modal_overlay',
                    onClick: (event: Event) => {
                      if (
                        event.target === event.currentTarget &&
                        attrs.clickToClose !== false
                      ) {
                        modal.close();
                      }
                    }
                  }),
                  h(component, {
                    ...props,
                    _close: () => modal.close(),
                  })
                ])
              }
            },
          }),
        }
      });
      const { patchOptions } = modal;
      watch(
        () => attrs,
        newVal => {
          patchOptions({ attrs: newVal });
        }
      );
      modal.open();
      return modal;
    },
    hideAll() {
      const vfm = useVfm();
      vfm.closeAll();
    }
  };
  nuxtApp.provide('modal', modal);
});