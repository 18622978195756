import _ from 'lodash-es';
import moment from 'moment';

export function formatObjectForBackend(object: any): any {
  _.forOwn(object, (value, key) => {
    if (Array.isArray(value)) {
      object[key] = _.map(value, el => el.id);
      if (_.has(value, 'id')) {
        object[key] = value['id'];
      }
    } else {
      const objectId = _.get(value, 'id', null);
      if (objectId) object[key] = objectId;
    }
  });
  return object;
}

export function prepareEventForBackend(event: any): EventResponse {
  // convert event data for backend compatibility
  if (event.days_of_week) {
    const mappedDaysOfWeek = [] as number[];

    _.forOwn(event.days_of_week, function (value, key) {
      if (value) {
        mappedDaysOfWeek.push(parseInt(key));
      }
    });
    event.days_of_week = mappedDaysOfWeek;
  }
  if (event.date) {
    const selectedDate = moment(event.date);
    if (event.start_time) {
      const selectedStartTime = moment(event.start_time, ['HH:mm']);
      selectedDate.hour(selectedStartTime.hour());
      selectedDate.minute(selectedStartTime.minute());
      event.event_start_datetime = _.clone(selectedDate.toISOString());

      selectedDate.add(event.duration_hours, 'hours');
      selectedDate.add(event.duration_minutes, 'minutes');
      event.event_end_datetime = _.clone(selectedDate.toISOString());
    }
  }
  if (event.schedule_end_date) {
    event.schedule_end_date = moment(event.schedule_end_date).format(
      'YYYY-MM-DD'
    );
  }
  if (event.event_type !== 'private_session') {
    event.student = null;
  }
  return event;
}
