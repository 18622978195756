import mixpanel from 'mixpanel-browser';

// FB Pixel
function initFBPixelIfNotExist(pixelId) {
  if (!pixelId) return;
  try {
    const pixelsByID = window.fbq?.instance?.pixelsByID || {};
    if (!pixelsByID[pixelId]) {
      window.fbq('init', pixelId);
    }
  } catch (error) {
    console.error(`Failed to init FB pixel`, error);
  }
}

function trackFbPixel(pixelId, event = 'PageView', eventData = {}) {
  if (!pixelId) return;
  initFBPixelIfNotExist(pixelId);
  try {
    window.fbq('trackSingle', pixelId, event, eventData);
  } catch (error) {
    console.error(`Failed to init FB event`, error);
  }
}

function trackFBPixelPurchase(name, amount, subscriptionType) {
  try {
    const fbpx = process.env.VUE_APP_FACEBOOK_PIXEL;
    if (fbpx) {
      trackFbPixel(fbpx, 'Purchase', {
        value: amount,
        currency: 'USD',
        plan: name,
        billing_cycle: subscriptionType,
      });
    }
  } catch (e) {
    console.error('Failed to track FB pixel purchase: ', e);
  }
}

function trackFBPixelStudioPurchase(fbpx, payload) {
  try {
    if (fbpx) {
      trackFbPixel(fbpx, 'Purchase', payload);
    }
  } catch (e) {
    console.error('Failed to track FB pixel purchase: ', e);
  }
}

function trackFBPixelStudioFreePurchase(fbpx, payload) {
  try {
    if (fbpx) {
      trackFbPixel(fbpx, 'StartTrial', payload);
    }
  } catch (e) {
    console.error('Failed to track FB pixel purchase: ', e);
  }
}

function trackFBPixelStudioFreeEventRegistration(fbpx, payload) {
  try {
    if (fbpx) {
      trackFbPixel(fbpx, 'Schedule', payload);
    }
  } catch (e) {
    console.error('Failed to track FB pixel purchase: ', e);
  }
}

// GA
function trackGATPurchase(amount) {
  window.dataLayer.push({
    event: 'purchase-conversion--' + process.env.VUE_APP_NAME,
    conversionValue: parseFloat(amount),
    currency: 'USD',
  });
}

function consoleLogEvent(eventName, data = {}) {
  if (process.env.VUE_APP_CONSOLE_LOG_ANALYTICS_EVENTS === 'true') {
    console.log(eventName, data);
  }
}

function init() {
  consoleLogEvent('analytics init');
  try {
    mixpanel.init(
      process.env.VUE_APP_MIXPANEL_KEY,
      { api_host: 'https://api-eu.mixpanel.com' },
      ''
    );
  } catch (e) {
    console.error('Analytics init failed:', e);
  }
}

function identify({ mixpanelDistinctId }) {
  consoleLogEvent('analytics identify', mixpanelDistinctId);
  try {
    if (mixpanelDistinctId) mixpanel.identify(mixpanelDistinctId);
  } catch (e) {
    console.error('Analytics identify failed:', e);
  }
}

function login(id) {
  consoleLogEvent('analytics login', id);
  try {
    mixpanel.identify(id);
  } catch (e) {
    console.error('Analytics login failed:', e);
  }
}

function register(id, traits) {
  consoleLogEvent('analytics register', { id, traits });
  try {
    mixpanel.alias(id);
    mixpanel.people.set(traits);
  } catch (e) {
    console.error('Analytics register failed:', e);
  }
}

function track(event, properties?: {}) {
  consoleLogEvent(event, properties);
  try {
    mixpanel.track(event, properties);
  } catch (e) {
    console.error('Analytics track failed:', e);
  }
}

function logout() {
  consoleLogEvent('analytics logout');
  try {
    mixpanel.reset();
  } catch (e) {
    console.error('Analytics logout failed:', e);
  }
}

function setMixpanelUTMParameters(query = {}) {
  const utmKeywords = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ].filter(key => query[key]);

  if (!utmKeywords.length) return;

  const lastTouchParams = utmKeywords.reduce(
    (par, key) => (par[`${key} [last touch]`] = query[key]),
    {}
  );

  const firstTouchParams = utmKeywords.reduce(
    (par, key) => (par[key] = query[key]),
    {}
  );

  try {
    mixpanel.people.set_once(firstTouchParams);
    mixpanel.people.set(lastTouchParams);
    mixpanel.register(lastTouchParams);
  } catch (e) {
    console.error('Analytics setMixpanelUTMParameters failed:', e);
  }
}

interface PurchaseData {
  subscription_type: string;
  plan_amount: number;
  plan_name: string;
}

function gtagRreportConversion(value) {
  window.gtag('event', 'conversion', {
    send_to: 'AW-1015702975/JUvHCPn52YACEL_LqeQD',
    value: value,
    currency: 'USD',
    transaction_id: '',
    event_callback: () => {},
  });
  return false;
}

function trackPurchase(purchaseData: PurchaseData) {
  const { subscription_type, plan_amount, plan_name } = purchaseData;

  trackGATPurchase(plan_amount);
  trackFBPixelPurchase(plan_name, plan_amount, subscription_type);
  track('studio-purchase', purchaseData);
  gtagRreportConversion(plan_amount);
}

function isScriptAlreadyIncluded(src) {
  const scripts = document.getElementsByTagName('script');
  for (let i = 0; i < scripts.length; i++)
    if ((scripts[i].getAttribute('src') || '').indexOf(src) !== -1) return true;
  return false;
}

function initGoogleAnalyticsForStudio(gtagId, studioUrl) {
  const validIdStructure = new RegExp(
    /[AW|DC|UA|G]-[0-9,a-z,A-Z]{8,10}(-[0-9]{1,})?/
  );
  const idIsValid =
    typeof gtagId === 'string' && gtagId.match(validIdStructure);
  if (!idIsValid) {
    console.error('Invalid gtagId:', gtagId);
    return;
  }
  if (!isScriptAlreadyIncluded('//www.googletagmanager.com/gtag/js')) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `//www.googletagmanager.com/gtag/js?id=${gtagId}`;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  window.gtag('config', gtagId, { groups: studioUrl });
  console.log('Loaded gtag ', gtagId);
}

function initRewardful(rewardfulApiKey) {
  if (!isScriptAlreadyIncluded('//r.wdfl.co/rw.js')) {
    const initialScript = document.createElement('script');
    initialScript.text = `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`;
    document.getElementsByTagName('head')[0].appendChild(initialScript);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.setAttribute('data-rewardful', rewardfulApiKey);
    script.src = `//r.wdfl.co/rw.js`;
    document.getElementsByTagName('head')[0].appendChild(script);
    console.log('Loaded Rewardful ', rewardfulApiKey);
  }
}

function gaTrackPageView(pagePath, studioUrl) {
  window.gtag('set', 'page_path', pagePath);
  window.gtag('event', 'page_view', { send_to: studioUrl });
}

// function gaTrackPurchase(value, currency, studioUrl) {
//   window.gtag('event', 'conversion', {
//     'value': value,
//     'currency': currency,
//     'send_to': studioUrl
//   });
// }

export {
  trackPurchase,
  trackFbPixel,
  initFBPixelIfNotExist,
  trackFBPixelStudioPurchase,
  trackFBPixelStudioFreePurchase,
  //gaTrackPurchase,
  trackFBPixelStudioFreeEventRegistration,
  init,
  identify,
  login,
  register,
  track,
  logout,
  setMixpanelUTMParameters,
  initGoogleAnalyticsForStudio,
  gaTrackPageView,
  initRewardful,
  gtagRreportConversion,
};
