import { api } from './api';
import { isBoolean } from 'lodash-es';
import type {
  CustomerEmailRecipient,
  CustomerStatsResponse,
  StudentMediaLikeResponse,
} from '@/typings/globals';

const PURCHASES_BASE_URL = 'api/purchases';
const CUSTOMERS_BASE_URL = 'api/customers';
const PAYMENT_PLANS_BASE_URL = 'api/payment-plans';
const TIP_JAR_BASE_URL = 'api/tip-jar';
const GIFTS_URL = 'api/gifts';

async function getSalesPurchasesStats(): Promise<SalesPurchaseStatsResponse> {
  const result = await api.get<SalesPurchaseStatsResponse>(
    `${PURCHASES_BASE_URL}/stats`
  );
  return result.data;
}

async function getTipJarStats(): Promise<TipJarStatsResponse> {
  const result = await api.get<TipJarStatsResponse>(
    `${TIP_JAR_BASE_URL}/stats`
  );
  return result.data;
}

async function getSalesPurchases(
  page = 1,
  q: string | null,
  ordering: string | null
): Promise<PaginatedResponse<SalesPurchaseResponse>> {
  let url = `${PURCHASES_BASE_URL}?page=${page}`;
  if (q) url += `&q=${encodeURIComponent(q)}`;
  if (ordering) url += `&ordering=${encodeURIComponent(ordering)}`;

  const result = await api.get<PaginatedResponse<SalesPurchaseResponse>>(url);
  return result.data;
}

async function getTips(
  page = 1,
  q: string | null,
  ordering: string | null,
  startDate: Date | null,
  endDate: Date | null
): Promise<PaginatedResponse<any>> {
  let url = `${TIP_JAR_BASE_URL}?page=${page}`;
  if (q) url += `&q=${encodeURIComponent(q)}`;
  if (ordering) url += `&ordering=${encodeURIComponent(ordering)}`;
  if (startDate) {
    url += `&date_from=${startDate.toISOString()}`;
  }
  if (endDate) {
    url += `&date_to=${endDate.toISOString()}`;
  }

  const result = await api.get<PaginatedResponse<any>>(url);
  return result.data;
}

async function getSalesCustomersStats(): Promise<SalesCustomerStatsResponse> {
  const result = await api.get<SalesCustomerStatsResponse>(
    `${CUSTOMERS_BASE_URL}/stats`
  );
  return result.data;
}

async function getSalesCustomers(
  page = 1,
  status: boolean | null,
  product: number | null,
  q: string | null,
  ordering: string | null
): Promise<PaginatedResponse<SalesCustomerResponse>> {
  let url = `${CUSTOMERS_BASE_URL}?page=${page}`;
  if (isBoolean(status)) url += `&status=${status}`;
  if (product) url += `&product=${product}`;
  if (q) url += `&q=${encodeURIComponent(q)}`;
  if (ordering) url += `&ordering=${ordering}`;

  const result = await api.get<PaginatedResponse<SalesCustomerResponse>>(url);
  return result.data;
}

async function getCustomerDetail(id: number): Promise<CustomerDetailResponse> {
  const result = await api.get<CustomerDetailResponse>(
    `${CUSTOMERS_BASE_URL}/${id}`
  );
  return result.data;
}

async function sendLoginLink(customerId: number): Promise<void> {
  return await api.post(`${CUSTOMERS_BASE_URL}/${customerId}/send_login_link`);
}

async function sendWaiverEmail(customerId: number): Promise<void> {
  return await api.post(`${CUSTOMERS_BASE_URL}/${customerId}/send_waiver`);
}

async function createCustomer(
  payload: CreateCustomerRequest
): Promise<SalesCustomerResponse> {
  const result = await api.post<SalesCustomerResponse>(
    CUSTOMERS_BASE_URL,
    payload
  );
  return result.data;
}

async function updateCustomer(
  payload: UpdateCustomerRequest
): Promise<SalesCustomerResponse> {
  const result = await api.put<SalesCustomerResponse>(
    `${CUSTOMERS_BASE_URL}/${payload.id}`,
    payload
  );
  return result.data;
}

async function enrollCustomer(
  customerId: number,
  payload: EnrollCustomerRequest
): Promise<void> {
  await api.post(`/v2/teachers/customers/${customerId}/enroll`, payload);
}

async function deleteCustomer(id: number): Promise<void> {
  const result = await api.delete<void>(`${CUSTOMERS_BASE_URL}/${id}`);
  return result.data;
}

async function exportCustomers(
  status: boolean | null,
  product: number | null,
  q: string | null,
  dateFrom: Date | null,
  dateTo: Date | null,
  ordering: string | null
): Promise<BlobPart> {
  const result = await api.get(`${CUSTOMERS_BASE_URL}/export_to_csv`, {
    params: {
      status,
      product,
      q,
      date_from: dateFrom?.toISOString(),
      date_to: dateTo?.toISOString(),
      ordering: ordering,
    },
  });
  return result.data;
}

async function exportOrders(q = '', ordering: string): Promise<BlobPart> {
  const result = await api.get<BlobPart>(
    `${PURCHASES_BASE_URL}/export_to_csv?q=${encodeURIComponent(
      q
    )}&ordering=${encodeURIComponent(ordering)}`
  );
  return result.data;
}

async function getCustomerNote(
  customerId,
  noteId
): Promise<StudentNoteResponse> {
  const result = await api.get<StudentNoteResponse>(
    `${CUSTOMERS_BASE_URL}/${customerId}/notes/${noteId}`
  );
  return result.data;
}

async function getCustomerNotes(
  customerId,
  page,
  q = ''
): Promise<PaginatedResponse<StudentNoteResponse>> {
  const params = new URLSearchParams({
    page,
    q,
  });
  const result = await api.get<PaginatedResponse<StudentNoteResponse>>(
    `${CUSTOMERS_BASE_URL}/${customerId}/notes?${params}`
  );
  return result.data;
}

async function addCustomerNote(
  customerId,
  noteData
): Promise<StudentNoteResponse> {
  const result = await api.post<StudentNoteResponse>(
    `${CUSTOMERS_BASE_URL}/${customerId}/notes`,
    noteData
  );
  return result.data;
}

async function updateCustomerNote(
  customerId,
  noteData
): Promise<StudentNoteResponse> {
  const result = await api.put<StudentNoteResponse>(
    `${CUSTOMERS_BASE_URL}/${customerId}/notes/${noteData.id}`,
    noteData
  );
  return result.data;
}

async function deleteCustomerNote(customerId, noteId): Promise<void> {
  const result = await api.delete<void>(
    `${CUSTOMERS_BASE_URL}/${customerId}/notes/${noteId}`
  );
  return result.data;
}

async function downloadCustomerNote(customerId, noteId): Promise<BlobPart> {
  const result = await api.get<BlobPart>(
    `${CUSTOMERS_BASE_URL}/${customerId}/notes/${noteId}/download`,
    {
      responseType: 'blob',
    }
  );
  return result.data;
}

async function getCustomerWaivers(customerId): Promise<WaiverResponse[]> {
  const result = await api.get<WaiverResponse[]>(
    `${CUSTOMERS_BASE_URL}/${customerId}/waivers`
  );
  return result.data;
}

async function getCommunityGroups(
  customerId,
  page = 1
): Promise<CommunityGroupsResponse[]> {
  const result = await api.get<CommunityGroupsResponse[]>(
    `${CUSTOMERS_BASE_URL}/${customerId}/community-groups`,
    {
      params: {
        page,
      },
    }
  );
  return result.data;
}

async function banCustomerFromCommunityGroup(
  customerId,
  communityGroupId
): Promise<void> {
  const result = await api.post<void>(
    `${CUSTOMERS_BASE_URL}/${customerId}/community-groups/${communityGroupId}/ban`
  );
  return result.data;
}

async function unbanCustomerFromCommunityGroup(
  customerId,
  communityGroupId
): Promise<void> {
  const result = await api.post<void>(
    `${CUSTOMERS_BASE_URL}/${customerId}/community-groups/${communityGroupId}/unban`
  );
  return result.data;
}

async function getCustomerMediaLikes(
  customerId: number,
  page = 1
): Promise<StudentMediaLikeResponse[]> {
  const result = await api.get<StudentMediaLikeResponse[]>(
    `${CUSTOMERS_BASE_URL}/${customerId}/media-likes`,
    {
      params: {
        page,
      },
    }
  );
  return result.data;
}

async function getCustomerEmails(
  customerId: number,
  page = 1,
  q: string,
  opened: boolean | null,
  clicked: boolean | null
): Promise<CustomerEmailRecipient[]> {
  const result = await api.get<CustomerEmailRecipient[]>(
    `${CUSTOMERS_BASE_URL}/${customerId}/emails`,
    {
      params: {
        page,
        q,
        opened: opened,
        clicked: clicked,
      },
    }
  );
  return result.data;
}

async function getCustomerPurchases(
  customerId: number,
  page = 1,
  ordering
): Promise<PaginatedResponse<PurchasesResponse>> {
  const result = await api.get<PaginatedResponse<PurchasesResponse>>(
    `${CUSTOMERS_BASE_URL}/${customerId}/purchases`,
    {
      params: {
        page,
        ordering,
      },
    }
  );
  return result.data;
}

async function getCustomerStats(
  customerId: number
): Promise<CustomerStatsResponse> {
  const result = await api.get<CustomerStatsResponse>(
    `${CUSTOMERS_BASE_URL}/${customerId}/customer_stats`
  );
  return result.data;
}

async function getCommunityProfile(
  customerId: number
): Promise<CommunityProfileResponse> {
  const result = await api.get<CommunityProfileResponse>(
    `${CUSTOMERS_BASE_URL}/${customerId}/community_profile`
  );
  return result.data;
}

async function exportTips(q = '', ordering: string): Promise<BlobPart> {
  const result = await api.get<BlobPart>(
    `${TIP_JAR_BASE_URL}/export_to_csv?q=${encodeURIComponent(
      q
    )}&ordering=${encodeURIComponent(ordering)}`
  );
  return result.data;
}

async function downloadInvoice(id: number): Promise<BlobPart> {
  const result = await api.get<BlobPart>(
    `${PURCHASES_BASE_URL}/${id}/invoice`,
    {
      responseType: 'blob',
    }
  );
  return result.data;
}

async function sendCustomerInvoice(id: number): Promise<void> {
  const result = await api.post<void>(
    `${PURCHASES_BASE_URL}/${id}/send_invoice`
  );
  return result.data;
}

async function cancelOrder(id: number) {
  return await api.post(`${PURCHASES_BASE_URL}/${id}/remove`);
}

async function pauseOrder(id: number) {
  return await api.post(`${PURCHASES_BASE_URL}/${id}/pause`);
}

async function resumeOrder(id: number) {
  return await api.post(`${PURCHASES_BASE_URL}/${id}/resume`);
}

async function updateExpirationDate(id: number, expires_at: moment.Moment) {
  const payload = {
    expires_at: expires_at.format('YYYY-MM-DD'),
  };
  return await api.post(
    `${PURCHASES_BASE_URL}/${id}/update_expiration`,
    payload
  );
}
async function updateOrder(order: SalesPurchaseResponse) {
  return await api.post(`${PURCHASES_BASE_URL}/${order.id}/modify`, order);
}

async function getPaymentPlanStats(): Promise<StudentPaymentPlanStatsResponse> {
  const result = await api.get<StudentPaymentPlanStatsResponse>(
    `${PAYMENT_PLANS_BASE_URL}/stats`
  );
  return result.data;
}

async function getPaymentPlans(
  page = 1,
  status: boolean | null,
  product: number | null,
  student: number | null,
  q: string | null,
  startDate: Date | null,
  endDate: Date | null,
  ordering: string | null
): Promise<PaginatedResponse<StudentPaymentPlanResponse>> {
  let url = `${PAYMENT_PLANS_BASE_URL}?page=${page}`;
  if (status) url += `&status=${status}`;
  if (product) url += `&product=${product}`;
  if (student) url += `&student=${student}`;
  if (q) url += `&q=${encodeURIComponent(q)}`;
  if (ordering) url += `&ordering=${ordering}`;
  if (startDate) {
    url += `&date_from=${startDate.toISOString()}`;
  }
  if (endDate) {
    url += `&date_to=${endDate.toISOString()}`;
  }

  const result = await api.get<PaginatedResponse<StudentPaymentPlanResponse>>(
    url
  );
  return result.data;
}

async function exportPaymentPlans(
  status: boolean,
  product: number,
  student: number,
  q: string,
  startDate: Date,
  endDate: Date,
  ordering: string
): Promise<BlobPart> {
  let url = `${PAYMENT_PLANS_BASE_URL}/export_to_csv?q=${encodeURIComponent(
    q
  )}`;
  if (status) url += `&status=${status}`;
  if (product) url += `&product=${product}`;
  if (student) url += `&student=${student}`;
  if (q) url += `&q=${encodeURIComponent(q)}`;
  if (ordering) url += `&ordering=${ordering}`;
  if (startDate) {
    url += `&date_from=${startDate.toISOString()}`;
  }
  if (endDate) {
    url += `&date_to=${endDate.toISOString()}`;
  }
  const result = await api.get<BlobPart>(url);
  return result.data;
}

async function getPaymentPlanInvoices(
  id: number
): Promise<StudentPaymentPlanInvoiceResponse[]> {
  const result = await api.get<StudentPaymentPlanInvoiceResponse[]>(
    `${PAYMENT_PLANS_BASE_URL}/${id}/invoices`
  );
  return result.data;
}

async function cancelPaymentPlan(id: number): Promise<void> {
  const result = await api.post<void>(`${PAYMENT_PLANS_BASE_URL}/${id}/cancel`);
  return result.data;
}

async function updateStudentPaymentPlanPaymentMethodAsTeacher(
  id: number,
  defaultPaymentMethod: string
): Promise<any> {
  const data = {
    default_payment_method: defaultPaymentMethod,
  };
  const result = await api.post<any>(
    `${PAYMENT_PLANS_BASE_URL}/${id}/payment_update`,
    data
  );
  return result.data;
}

async function getGiftsPurchases(
  page = 1,
  q: string | null,
  startDate: Date | null,
  endDate: Date | null,
  status: string | null
): Promise<PaginatedResponse<any>> {
  let url = `${GIFTS_URL}?page=${page}`;
  if (q) url += `&q=${encodeURIComponent(q)}`;
  if (startDate) {
    url += `&date_from=${startDate.toISOString()}`;
  }
  if (endDate) {
    url += `&date_to=${endDate.toISOString()}`;
  }
  if (status) {
    url += `&status=${status}`;
  }

  const result = await api.get<PaginatedResponse<any>>(url);
  return result.data;
}

async function updateGiftPurchase(id: number, data: any): Promise<any> {
  const result = await api.put<any>(`${GIFTS_URL}/${id}`, data);
  return result.data;
}

async function resendGiftEmail(id: number): Promise<any> {
  const result = await api.post<any>(`${GIFTS_URL}/${id}/resend`);
  return result.data;
}

async function getGiftsPurchasesStats() {
  const result = await api.get(`${GIFTS_URL}/stats`);
  return result.data;
}

export default {
  getSalesPurchasesStats,
  getSalesPurchases,
  getSalesCustomersStats,
  getSalesCustomers,
  getCustomerDetail,
  sendLoginLink,
  sendWaiverEmail,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  exportCustomers,
  downloadInvoice,
  sendCustomerInvoice,
  enrollCustomer,
  exportOrders,
  getCustomerNote,
  getCustomerNotes,
  addCustomerNote,
  updateCustomerNote,
  deleteCustomerNote,
  downloadCustomerNote,
  getCustomerWaivers,
  getCommunityGroups,
  banCustomerFromCommunityGroup,
  unbanCustomerFromCommunityGroup,
  getCustomerMediaLikes,
  getCustomerEmails,
  getCustomerPurchases,
  getCustomerStats,
  getCommunityProfile,
  cancelOrder,
  pauseOrder,
  resumeOrder,
  getPaymentPlanStats,
  getPaymentPlans,
  exportPaymentPlans,
  getPaymentPlanInvoices,
  cancelPaymentPlan,
  updateStudentPaymentPlanPaymentMethodAsTeacher,
  getTipJarStats,
  getTips,
  exportTips,
  getGiftsPurchases,
  updateGiftPurchase,
  resendGiftEmail,
  getGiftsPurchasesStats,
  updateExpirationDate,
  updateOrder,
};
