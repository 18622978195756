import { useUserStore } from '~/stores/user';
import { useStudioStore } from '~/stores/studio';
import { useGlobalsStore } from '~/stores/globals';
import { getEnObject } from './../i18n.config.js'
import { i18n } from './../utils/localization.js'

export default defineNuxtPlugin((nuxtApp) => {

  nuxtApp.hook('app:created', () => {
    if (process.server) {
      useUserStore().$reset();
      useStudioStore().$reset();
      useGlobalsStore().$reset();
      const en = getEnObject();
      nuxtApp.$i18n.setLocaleMessage('en', en);
      i18n.global.setLocaleMessage('en', en);
    }
  })
});