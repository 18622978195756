import { pick } from 'lodash-es';

import api from '@/api/media-libraries';

interface State {
  media: PaginatedResponse<TeacherMediaResponse> | null;
  categories: TeacherCategoryResponse[] | null;
  instructors: InstructorResponse[] | null;
}

const emptyMedia = {
  count: null,
  next: null,
  previous: null,
  results: [],
};

export const useMediaLibraryStore = defineStore('mediaLibrary', {
  state: ():State => ({
    media: null,
    categories: null,
    instructors: [],
  }),
  getters: {
    media({ media }) {
      return media || emptyMedia;
    },
    categoriesTags({ categories }): any {
      return categories?.reduce(
        (options: any, category: any) => [
          ...options,
          ...category.option_list.map(option => ({
            name: `${category.name} - ${option.name}`,
            id: option.id,
          })),
        ],
        []
      );
    },
    // canAddInstructor(state, getters, rootState, rootGetters) {
    //   if (state.instructors) {
    //     const maxInstructors = rootGetters['user/maxInstructors'];
    //     return state.instructors.length < maxInstructors;
    //   } else return false;
    // },
  },
  actions: {
    async getMedia( params): Promise<any> {
      const { page, fileType, instructor, options, q } = pick(params, [
        'page',
        'fileType',
        'instructor',
        'options',
        'q',
      ]);
      const data = await api.getTeacherMedia(
        page,
        fileType,
        instructor,
        options,
        q
      );
      this.media = data;
      return data;
    },
    async getVideoDownloadLink(userFileId): Promise<string> {
      return await api.getVideoDownloadLink(userFileId);
    },
    async duplicate(mediaId): Promise<string> {
      return await api.duplicate(mediaId);
    },
    async canUploadVideo(): Promise<any> {
      await api.canUploadVideo();
    },
    async getMediaViews(params): Promise<any> {
      const { mediaId, page, q } = pick(params, ['mediaId', 'page', 'q']);
      const data = await api.getMediaViews(mediaId, page, q);
      return data;
    },
    async getMediaViewsCSV(mediaId) {
      const data = await api.getMediaViewsCSV(mediaId);
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `media-views-${mediaId}.csv`);
    },
    async getMediaLikes(params): Promise<any> {
      const { mediaId, page, q } = pick(params, ['mediaId', 'page', 'q']);
      const data = await api.getMediaLikes(mediaId, page, q);
      return data;
    },
    async getMediaLikesCSV(mediaId) {
      const data = await api.getMediaLikesCSV(mediaId);
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `media-views-${mediaId}.csv`);
    },
    async createMedia(
      data: CreateMediaRequest
    ): Promise<TeacherMediaResponse> {
      const result = await api.createMedia(data);
      return result;
    },
    async updateMedia(
      data: UpdateMediaRequest
    ): Promise<TeacherMediaResponse> {
      const result = await api.updateMedia(data);
      return result;
    },
    async updatePlayback(
      { id, playback_start, playback_end }
    ): Promise<void> {
      await api.updatePlayback({ id, playback_start, playback_end });
    },
    async updateMediaFile({ id, data }): Promise<void> {
      await api.updateMediaFile(id, data);
    },
    async toggleFileDeletable({ id }): Promise<void> {
      await api.toggleFileDeletable(id);
    },
    async getMediaById(id): Promise<TeacherMediaResponse> {
      const result = await api.getMedia(id);
      return result;
    },
    async deleteMedia(id): Promise<void> {
      await api.deleteMedia(id);
    },
    async getCategories(): Promise<void> {
      const data = await api.getTeacherCategories();
      this.categories = data;
      return data;
    },
    async createCategory(
      data: CreateCategoryRequest
    ): Promise<TeacherCategoryResponse> {
      const result = await api.createCategory(data);
      return result;
    },
    async updateCategory(
      data: UpdateCategoryRequest
    ): Promise<TeacherCategoryResponse> {
      const result = await api.updateCategory(data);
      return result;
    },
    async deleteCategory(id: number): Promise<void> {
      const result = await api.deleteCategory(id);

      this.categories = this.categories?.filter(category => category.category_id !== id)

      return result;
    },
    async reorderCategories(
      categories: Array<TeacherCategoryResponse>
    ) {
      this.categories = categories;
      await Promise.all(
        categories.map(category => this.updateCategory(category))
      );
    },
    async getInstructors(): Promise<void> {
      const data = await api.getTeacherInstructors();
      this.instructors = data;
    },
    async createInstructor(
      data: CreateInstructorRequest
    ): Promise<InstructorResponse> {
      const result = await api.createInstructor(data);
      return result;
    },
    async deleteInstructorPhoto(id: number): Promise<void> {
      const result = await api.deleteInstructorPhoto(id);

      this.instructors = this.instructors;

      return result;
    },
    async updateInstructor(
      data: UpdateInstructorRequest
    ): Promise<InstructorResponse> {
      const result = await api.updateInstructor(data);
      return result;
    },
    async deleteInstructor(id: number): Promise<void> {
      const result = await api.deleteInstructor(id);

      this.instructors = this.instructors?.filter(instructor => instructor.id !== id);

      return result;
    },
    async reorderInstructors(
      instructors: Array<InstructorResponse>
    ) {
      await Promise.all(
        instructors.map(instructor => this.updateInstructor(instructor))
      );
    },
    async searchUnsplashImages({ query, page, pageSize }): Promise<any> {
      const result = await api.searchUnsplashImages(query, page, pageSize);
      return result;
    },
    async unsplashMarkDownload({ downloadUrl }): Promise<any> {
      const result = await api.unsplashMarkDownload(downloadUrl);
      return result;
    },
  },
  // mutations: {
  //   [types.SET_MEDIA](
  //     state: State,
  //     data: PaginatedResponse<TeacherMediaResponse>
  //   ) {
  //     state.media = data;
  //   },
  //   [types.SET_CATEGORIES](state: State, data: TeacherCategoryResponse[]) {
  //     state.categories = data;
  //   },
  //   [types.SET_INSTRUCTORS](state: State, data: InstructorResponse[]) {
  //     state.instructors = data;
  //   },
  // },
});
