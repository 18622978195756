<template>
  <div class="hm-modal-header w-100">
    <slot name="title">
      <h4>
        {{ title }}
      </h4>
    </slot>
    <span>
      <slot />
      <i
        v-if="closable"
        class="close-icon far fa-times"
        @click="$emit('close')"
      ></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'HMModalHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.hm-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $medium-gray;
  margin-bottom: 1.5rem;
  padding-top: 1rem;
}

h2 {
  margin-bottom: 0;
}

.close-icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  color: $medium-gray;
}
</style>
