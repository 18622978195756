export function hex2rgba(hex, alpha = 1) {
  if (!hex) return '';
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
}

export function hueToRgb(m1, m2, h) {
  if (h < 0) h = h + 1;
  else if (h > 1) h = h - 1;

  if (h * 6 < 1) return m1 + (m2 - m1) * h * 6;
  else if (h * 2 < 1) return m2;
  else if (h * 3 < 2) return m1 + (m2 - m1) * (2 / 3 - h) * 6;
  else return m1;
}

export function hslToRgb(h, s, l) {
  h /= 360;
  s /= 100;
  l /= 100;
  const m2 = l <= 0.5 ? l * (s + 1) : l + s - l * s;
  const m1 = l * 2 - m2;
  // @ts-ignore
  const r = parseInt(hueToRgb(m1, m2, h + 1 / 3) * 255);
  // @ts-ignore
  const g = parseInt(hueToRgb(m1, m2, h) * 255);
  // @ts-ignore
  const b = parseInt(hueToRgb(m1, m2, h - 1 / 3) * 255);
  return [r, g, b];
}

export function hex(c) {
  const s = '0123456789abcdef';
  let i = parseInt(c);
  if (i === 0 || isNaN(c)) return '00';
  i = Math.round(Math.min(Math.max(0, i), 255));
  return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
}

export function rgbToHex(rgb) {
  return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
}
