import { defineStore } from 'pinia'
// import { pick } from 'lodash-es';
// import { saveAs } from '@/utils/save-file';

import api from '@/api/sales';
// import authApi from '@/api/auth';
// import apiStudio from '@/api/studios';
// import product from '@/stores/product';

interface State {
  salesPurchases: PaginatedResponse<SalesPurchaseResponse> | null;
  salesPurchaseStat: SalesPurchaseStatsResponse | null;
  salesCustomers: PaginatedResponse<SalesCustomerResponse> | null;
  salesCustomersStats: SalesCustomerStatsResponse | null;
  paymentPlanStats: StudentPaymentPlanStatsResponse | null;
  customerDetail: CustomerDetailResponse | null;
  customerPunches: Array<StudentPunchesResponse> | null;
  tipJarStats: TipJarStatsResponse | null;
  tipJar: PaginatedResponse<TipJarResponse> | null;
  giftsPurchases: any | null;
  giftsPurchasesStats: any | null;
  customerStats: CustomerStatsResponse | null;
}

export const useSaleStore = defineStore('sale', {
  state: ():State => ({
    salesCustomers: null,
    salesCustomersStats: null,
    salesPurchases: null,
    salesPurchaseStat: null,
    customerDetail: null,
    customerPunches: null,
    paymentPlanStats: null,
    tipJarStats: null,
    tipJar: null,
    giftsPurchases: null,
    giftsPurchasesStats: null,
    customerStats: null,
  }),
  getters: {
    // salesPurchases: state => state.salesPurchases,
    // salesCustomers: state => state.salesCustomers,
    // salesPurchaseStat: state => state.salesPurchaseStat,
    // tipJarStats: state => state.tipJarStats,
    // tipJar: state => state.tipJar,
    // paymentPlanStats: state => state.paymentPlanStats,
    // giftsPurchasesStats: state => state.giftsPurchasesStats,
    // customerDetail: state => state.customerDetail,
    // customerFullAddress: state => {
    //   if (!state.customerDetail) return;
    //   const { address, city, zipcode, country } = state.customerDetail;
    //   return [address, city, zipcode, country].filter(Boolean).join(', ');
    // },
    // customerPurchases: state => state.customerDetail?.purchases,
    // customerPunches: state => state.customerPunches,
    // customerUsedPunches: state => {
    //   return state.customerPunches?.filter(punch => punch.used_on) || [];
    // },
    // customerAvailablePunches: state => {
    //   return (
    //     state.customerPunches?.filter(
    //       ({ used_on, expired }) => !used_on && !expired
    //     ).length || 0
    //   );
    // },
    // customerStats: state => state.customerStats,
  },
  actions: {
    // async getSalesPurchases(
    //   { commit },
    //   params
    // ): Promise<PaginatedResponse<SalesPurchaseResponse>> {
    //   const { page, q, ordering } = pick(params, ['page', 'q', 'ordering']);
    //   const data = await api.getSalesPurchases(page, q, ordering);
    //   commit(types.SET_SALES_PURCHASES, data);
    //   return data;
    // },
    // async getSalesPurchaseStats({ commit }) {
    //   const data = await api.getSalesPurchasesStats();
    //   commit(types.SET_SALES_PURCHASES_STATS, data);
    // },
    // async getTipJarStats({ commit }) {
    //   const data = await api.getTipJarStats();
    //   commit(types.SET_TIP_JAR_STATS, data);
    // },
    // async getTips({ commit }, params): Promise<PaginatedResponse<any>> {
    //   const { page, q, ordering, startDate, endDate } = pick(params, [
    //     'page',
    //     'q',
    //     'ordering',
    //     'startDate',
    //     'endDate',
    //   ]);
    //   const data = await api.getTips(page, q, ordering, startDate, endDate);
    //   commit(types.SET_TIPS, data);
    //   return data;
    // },
    // async getSalesCustomers({ commit }, params) {
    //   const { page, status, product, q, ordering } = pick(params, [
    //     'page',
    //     'status',
    //     'product',
    //     'q',
    //     'ordering',
    //   ]);
    //   const data = await api.getSalesCustomers(
    //     page,
    //     status,
    //     product,
    //     q,
    //     ordering
    //   );
    //   commit(types.SET_SALES_CUSTOMERS, data);
    //   return data;
    // },
    // async getSalesCustomersStats({ commit }) {
    //   const data = await api.getSalesCustomersStats();
    //   commit(types.SET_SALES_CUSTOMERS_STATS, data);
    // },
    // async getCustomerDetail({ commit }, id) {
    //   const data = await api.getCustomerDetail(id);
    //   commit(types.SET_CUSTOMER_DETAIL, data);
    //   return data;
    // },
    // async sendLoginLink(_, customerId) {
    //   return await api.sendLoginLink(customerId);
    // },
    // async sendWaiverEmail(_, customerId) {
    //   return await api.sendWaiverEmail(customerId);
    // },
    // async getCustomerPunches({ state, commit }) {
    //   const data = await authApi.studentIdPunches(state.customerDetail?.id);
    //   commit(types.SET_CUSTOMER_PUNCHES, data);
    //   return data;
    // },
    // async addCustomerPunch(
    //   { state, dispatch },
    //   { expirationDays, notificationDays, numberOfRedemptions, productId }
    // ) {
    //   await authApi.studentIdAddFreePunch(
    //     state.customerDetail?.id,
    //     expirationDays,
    //     notificationDays,
    //     numberOfRedemptions,
    //     productId
    //   );
    //   await dispatch('getCustomerPunches');
    // },
    // async removeCustomerPunch({ state, dispatch }, { productId, expiration }) {
    //   const customerId = state.customerDetail?.id;
    //   await authApi.studentIdRemovePunch(customerId, productId, expiration);
    //   await dispatch('getCustomerPunches');
    // },
    // async refundCustomerPunch({ state, dispatch }, { punch_id }) {
    //   const customerId = state.customerDetail?.id;
    //   await authApi.studentIdRefundPunch(customerId, punch_id);
    //   await dispatch('getCustomerPunches');
    // },
    // clearCustomerDetail({ commit }) {
    //   commit(types.SET_CUSTOMER_DETAIL, null);
    // },
    // clearCustomerStats({ commit }) {
    //   commit(types.SET_CUSTOMER_STATS, null);
    // },
    // async downloadInvoice(_, purchase) {
    //   return await api.downloadInvoice(purchase.id);
    // },
    // async sendCustomerInvoice(_, purchase) {
    //   return await api.sendCustomerInvoice(purchase.id);
    // },
    // async createCustomer(_, params) {
    //   const payload = pick(params, ['email', 'customer']);
    //   const data = await api.createCustomer(payload);
    //   return data;
    // },
    // async updateCustomer(_, payload) {
    //   const data = await api.updateCustomer(payload);
    //   return data;
    // },
    // async enrollCustomer(_, params) {
    //   const payload = pick(params, [
    //     'product_id',
    //     'expiration_date',
    //     'amount_paid',
    //   ]);
    //   await api.enrollCustomer(params.customer_id, payload);
    // },
    // async deleteCustomer(_, id) {
    //   const data = await api.deleteCustomer(id);
    //   return data;
    // },
    // async exportCustomers(
    //   _,
    //   { status, product, q, dateFrom, dateTo, ordering }
    // ) {
    //   const data = await api.exportCustomers(
    //     status,
    //     product,
    //     q,
    //     dateFrom,
    //     dateTo,
    //     ordering
    //   );
    //   const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    //   saveAs(blob, 'customers-export.csv');
    // },
    // async exportOrders(_, data) {
    //   const { q, ordering } = data;
    //   return await api.exportOrders(q, ordering);
    // },
    // async exportTips(_, data) {
    //   const { q, ordering } = data;
    //   return await api.exportTips(q, ordering);
    // },
    // async cancelOrder(_, { id }) {
    //   return await api.cancelOrder(id);
    // },
    // async pauseOrder(_, { id }) {
    //   return await api.pauseOrder(id);
    // },
    // async resumeOrder(_, { id }) {
    //   return await api.resumeOrder(id);
    // },
    // async updateOrder(_, order) {
    //   return await api.updateOrder(order);
    // },
    // async updateExpirationDate(_, { id, expires_at }) {
    //   return await api.updateExpirationDate(id, expires_at);
    // },
    // async getPaymentPlanStats({ commit }) {
    //   const data = await api.getPaymentPlanStats();
    //   commit(types.SET_PAYMENT_PLAN_STATS, data);
    // },
    // async getGiftsPurchases({ commit }, params) {
    //   const { page, q, startDate, endDate, status } = pick(params, [
    //     'page',
    //     'q',
    //     'startDate',
    //     'endDate',
    //     'status',
    //   ]);

    //   const data = await api.getGiftsPurchases(
    //     page,
    //     q,
    //     startDate,
    //     endDate,
    //     status
    //   );

    //   commit(types.SET_GIFTS_PURCHASES, data);
    //   return data;
    // },
    // async getGiftsPurchasesStats({ commit }) {
    //   const data = await api.getGiftsPurchasesStats();
    //   commit(types.SET_GIFTS_PURCHASES_STATS, data);
    // },
    // async getPaymentPlans(
    //   { commit },
    //   params
    // ): Promise<PaginatedResponse<StudentPaymentPlanResponse>> {
    //   const {
    //     page,
    //     q,
    //     status,
    //     product,
    //     student,
    //     startDate,
    //     endDate,
    //     ordering,
    //   } = pick(params, [
    //     'page',
    //     'q',
    //     'status',
    //     'product',
    //     'student',
    //     'startDate',
    //     'endDate',
    //     'ordering',
    //   ]);
    //   const data = await api.getPaymentPlans(
    //     page,
    //     status,
    //     product,
    //     student,
    //     q,
    //     startDate,
    //     endDate,
    //     ordering
    //   );
    //   return data;
    // },
    async getPaymentPlanInvoices(paymentPlanId) {
      return await api.getPaymentPlanInvoices(paymentPlanId);
    },
    async cancelPaymentPlan(paymentPlanId) {
      return await api.cancelPaymentPlan(paymentPlanId);
    },
    // async exportPaymentPlans(_, params) {
    //   const {
    //     q,
    //     status,
    //     product,
    //     student,
    //     startDate,
    //     endDate,
    //     ordering,
    //   } = pick(params, [
    //     'q',
    //     'status',
    //     'product',
    //     'student',
    //     'startDate',
    //     'endDate',
    //     'ordering',
    //   ]);
    //   return await api.exportPaymentPlans(
    //     status,
    //     product,
    //     student,
    //     q,
    //     startDate,
    //     endDate,
    //     ordering
    //   );
    // },
    async updateStudentPaymentPlanPaymentMethodAsTeacher(
      { id, defaultPaymentMethod }
    ) {
      return await api.updateStudentPaymentPlanPaymentMethodAsTeacher(
        id,
        defaultPaymentMethod
      );
    },
    // async getCustomerStats({ commit }, { customerId }) {
    //   const data = await api.getCustomerStats(customerId);
    //   commit(types.SET_CUSTOMER_STATS, data);
    //   return data;
    // },
    // async getCommunityProfile(_, customerId) {
    //   return await api.getCommunityProfile(customerId);
    // },
    // async getCustomerNote(_, { customerId, noteId }) {
    //   return api.getCustomerNote(customerId, noteId);
    // },
    // async getCustomerNotes(_, params) {
    //   const { customerId, page, q } = params;
    //   return api.getCustomerNotes(customerId, page, q);
    // },
    // async addCustomerNote(_, { customerId, noteData }) {
    //   return await api.addCustomerNote(customerId, noteData);
    // },
    // async updateCustomerNote(_, { customerId, noteData }) {
    //   return await api.updateCustomerNote(customerId, noteData);
    // },
    // async deleteCustomerNote(_, { customerId, noteId }) {
    //   await api.deleteCustomerNote(customerId, noteId);
    // },
    // async downloadCustomerNote(_, { customerId, noteId }) {
    //   return await api.downloadCustomerNote(customerId, noteId);
    // },
    // async getCustomerWaivers(_, { customerId }) {
    //   return await api.getCustomerWaivers(customerId);
    // },
    // async getCustomerCommunityGroups(_, { customerId, page }) {
    //   return await api.getCommunityGroups(customerId, page);
    // },
    // async banCustomerFromCommunityGroup(_, { customerId, communityGroupId }) {
    //   return await api.banCustomerFromCommunityGroup(
    //     customerId,
    //     communityGroupId
    //   );
    // },
    // async unbanCustomerFromCommunityGroup(_, { customerId, communityGroupId }) {
    //   return await api.unbanCustomerFromCommunityGroup(
    //     customerId,
    //     communityGroupId
    //   );
    // },
    // async getCustomerMediaLikes(_, { customerId, page }) {
    //   return await api.getCustomerMediaLikes(customerId, page);
    // },
    // async getCustomerEmails(_, { customerId, page, q, opened, clicked }) {
    //   return await api.getCustomerEmails(customerId, page, q, opened, clicked);
    // },
    // async getCustomerPurchases(_, { customerId, page, ordering }) {
    //   return await api.getCustomerPurchases(customerId, page, ordering);
    // },
  },
  // mutations: {
  //   [types.SET_SALES_PURCHASES](
  //     state: State,
  //     data: PaginatedResponse<SalesPurchaseResponse>
  //   ) {
  //     state.salesPurchases = data;
  //   },
  //   [types.SET_SALES_PURCHASES_STATS](
  //     state: State,
  //     data: SalesPurchaseStatsResponse
  //   ) {
  //     state.salesPurchaseStat = data;
  //   },
  //   [types.SET_TIP_JAR_STATS](state: State, data: TipJarStatsResponse) {
  //     state.tipJarStats = data;
  //   },
  //   [types.SET_TIPS](state: State, data: PaginatedResponse<TipJarResponse>) {
  //     state.tipJar = data;
  //   },
  //   [types.SET_SALES_CUSTOMERS](
  //     state: State,
  //     data: PaginatedResponse<SalesCustomerResponse>
  //   ) {
  //     state.salesCustomers = data;
  //   },
  //   [types.SET_SALES_CUSTOMERS_STATS](
  //     state: State,
  //     data: SalesCustomerStatsResponse
  //   ) {
  //     state.salesCustomersStats = data;
  //   },
  //   [types.SET_PAYMENT_PLAN_STATS](
  //     state: State,
  //     data: StudentPaymentPlanStatsResponse
  //   ) {
  //     state.paymentPlanStats = data;
  //   },
  //   [types.SET_GIFTS_PURCHASES](state: State, data) {
  //     state.giftsPurchases = data;
  //   },
  //   [types.SET_GIFTS_PURCHASES_STATS](state: State, data) {
  //     state.giftsPurchasesStats = data;
  //   },
  //   [types.SET_CUSTOMER_DETAIL](state: State, data: CustomerDetailResponse) {
  //     state.customerDetail = data;
  //   },
  //   [types.SET_CUSTOMER_PUNCHES](
  //     state: State,
  //     data: Array<StudentPunchesResponse>
  //   ) {
  //     state.customerPunches = data;
  //   },
  //   [types.SET_CUSTOMER_STATS](state: State, data: CustomerStatsResponse) {
  //     state.customerStats = data;
  //   },
  // },
});
