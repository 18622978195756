import { default as aboutQbeuyanspkMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as eventsuKVw3oV3xxMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93Lh5PUmUxprMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleQCVPJ05m87Meta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsqRQfI3Pin3Meta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93MVnp3jtv8fMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewIIZ6lPmGwzMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexqhTsXOhsjzMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93oOB5haGtlgMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexD5J1T3ENRWMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93cMSzHza4gpMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexP2cQ0z5yYyMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsZEmD4qrAjfMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordings69wS2RpgzJMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsZoq9CeCtsaMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93IlMCFr13DLMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as indexBLu9MDBMbgMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirmoPtbP4LeNuMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirmLngihvUq4AMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendarD1CemKIKDgMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/calendar.vue?macro=true";
import { default as chatkIONNCPdSRMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/chat.vue?macro=true";
import { default as aboutNdb8WpV9jdMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedteqP40b0i4Meta } from "/tmp/build_8b18bfcd/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersC43vSa2w4NMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_93y3qtrDredgMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45editQtib8wx6P2Meta } from "/tmp/build_8b18bfcd/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_939cuO7nk7DrMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as communityqHE4O6ZokVMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93F5Kiv1sM5TMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93EQt7P7y96UMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardFCFPUibvqhMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/dashboard.vue?macro=true";
import { default as indexOsEprHYz8YMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkout4N8pALOfguMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45product3nSjOnY3JxMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventsJBjxWPptBZMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93Miufy6TUt1Meta } from "/tmp/build_8b18bfcd/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexbVkOmJkIgxMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_93FqB9xVD5WFMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_93eqm51lAEzGMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as indexnR4GPB95jrMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_93SZz2MKDPgEMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joinlfoV4UNJx7Meta } from "/tmp/build_8b18bfcd/pages/[studio_url]/join.vue?macro=true";
import { default as login2yFVzTei4bMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/login.vue?macro=true";
import { default as logoutbCoU7b3IDBMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_9320snd80EhlMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_93qf8qgyMNNxMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_935Yg3wXvxvfMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favoritesZ26mfv8DVkMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studios3dwo1RN4MRMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optinRRSDboFd6AMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_931Uu3Nbr4kwMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue?macro=true";
import { default as indexjB54JSmMgjMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indexGIccwmmkwMMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as aboutpGdEG6MIYJMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as eventsxALF5SIEZ6Meta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_936iWJ9jSvaTMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45samplefGjQjsbusHMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsKxZcU1UcTHMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93vTlxMnzhRZMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewugN6hUJHgcMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexfVcLJGeRkMMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93vF5RaxgsuYMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexqhJ1Cpt9LtMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93f0r8qGxyKkMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexs6osu3DL0PMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionspnxbpkTamrMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordings4RiRp2kwouMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsmBxewgcFuAMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93HHZyZNYdoAMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as indexUx7jSUca6IMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexjQyTEsV8sdMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchasesqFZ9LDaG09Meta } from "/tmp/build_8b18bfcd/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionsIazusDaagZMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93PNfBNyZuu2Meta } from "/tmp/build_8b18bfcd/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_93WZU8x3O1B5Meta } from "/tmp/build_8b18bfcd/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as resetrVbGR0ftW7Meta } from "/tmp/build_8b18bfcd/pages/[studio_url]/reset.vue?macro=true";
import { default as shopAMwYEHbNnZMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/shop.vue?macro=true";
import { default as signuprAF3shDYelMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/signup.vue?macro=true";
import { default as live4gf9hi93fVMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tipsSqOFj9OxnKMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_93B9cWcosQOoMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compactTkIyjskYAAMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as fullyLNmF5LQluMeta } from "/tmp/build_8b18bfcd/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_93IlMCFr13DLMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_93IlMCFr13DLMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_93IlMCFr13DLMeta || {},
    alias: _91product_id_93IlMCFr13DLMeta?.alias || [],
    redirect: _91product_id_93IlMCFr13DLMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutQbeuyanspkMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: aboutQbeuyanspkMeta?.path ?? "about",
    meta: aboutQbeuyanspkMeta || {},
    alias: aboutQbeuyanspkMeta?.alias || [],
    redirect: aboutQbeuyanspkMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsuKVw3oV3xxMeta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: eventsuKVw3oV3xxMeta?.path ?? "events",
    meta: eventsuKVw3oV3xxMeta || {},
    alias: eventsuKVw3oV3xxMeta?.alias || [],
    redirect: eventsuKVw3oV3xxMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93Lh5PUmUxprMeta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_93Lh5PUmUxprMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93Lh5PUmUxprMeta || {},
    alias: _91media_id_93Lh5PUmUxprMeta?.alias || [],
    redirect: _91media_id_93Lh5PUmUxprMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleQCVPJ05m87Meta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45sampleQCVPJ05m87Meta?.path ?? "free-sample",
    meta: free_45sampleQCVPJ05m87Meta || {},
    alias: free_45sampleQCVPJ05m87Meta?.alias || [],
    redirect: free_45sampleQCVPJ05m87Meta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsqRQfI3Pin3Meta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45productsqRQfI3Pin3Meta?.path ?? "included-products",
    meta: included_45productsqRQfI3Pin3Meta || {},
    alias: included_45productsqRQfI3Pin3Meta?.alias || [],
    redirect: included_45productsqRQfI3Pin3Meta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93MVnp3jtv8fMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93MVnp3jtv8fMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93MVnp3jtv8fMeta || {},
    alias: _91media_id_93MVnp3jtv8fMeta?.alias || [],
    redirect: _91media_id_93MVnp3jtv8fMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewIIZ6lPmGwzMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: viewIIZ6lPmGwzMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewIIZ6lPmGwzMeta || {},
    alias: viewIIZ6lPmGwzMeta?.alias || [],
    redirect: viewIIZ6lPmGwzMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexqhTsXOhsjzMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indexqhTsXOhsjzMeta?.path ?? "lessons",
    meta: indexqhTsXOhsjzMeta || {},
    alias: indexqhTsXOhsjzMeta?.alias || [],
    redirect: indexqhTsXOhsjzMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93oOB5haGtlgMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93oOB5haGtlgMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93oOB5haGtlgMeta || {},
    alias: _91media_id_93oOB5haGtlgMeta?.alias || [],
    redirect: _91media_id_93oOB5haGtlgMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexD5J1T3ENRWMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexD5J1T3ENRWMeta?.path ?? "playlists/:playlist_id()",
    meta: indexD5J1T3ENRWMeta || {},
    alias: indexD5J1T3ENRWMeta?.alias || [],
    redirect: indexD5J1T3ENRWMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93cMSzHza4gpMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93cMSzHza4gpMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93cMSzHza4gpMeta || {},
    alias: _91media_id_93cMSzHza4gpMeta?.alias || [],
    redirect: _91media_id_93cMSzHza4gpMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexP2cQ0z5yYyMeta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: indexP2cQ0z5yYyMeta?.path ?? "playlists",
    meta: indexP2cQ0z5yYyMeta || {},
    alias: indexP2cQ0z5yYyMeta?.alias || [],
    redirect: indexP2cQ0z5yYyMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsZEmD4qrAjfMeta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessionsZEmD4qrAjfMeta?.path ?? "private-sessions",
    meta: private_45sessionsZEmD4qrAjfMeta || {},
    alias: private_45sessionsZEmD4qrAjfMeta?.alias || [],
    redirect: private_45sessionsZEmD4qrAjfMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordings69wS2RpgzJMeta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordings69wS2RpgzJMeta?.path ?? "recordings",
    meta: recordings69wS2RpgzJMeta || {},
    alias: recordings69wS2RpgzJMeta?.alias || [],
    redirect: recordings69wS2RpgzJMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsZoq9CeCtsaMeta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewsZoq9CeCtsaMeta?.path ?? "reviews",
    meta: reviewsZoq9CeCtsaMeta || {},
    alias: reviewsZoq9CeCtsaMeta?.alias || [],
    redirect: reviewsZoq9CeCtsaMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexBLu9MDBMbgMeta?.name ?? "buy-product-product_id",
    path: indexBLu9MDBMbgMeta?.path ?? "/buy/product/:product_id()",
    meta: indexBLu9MDBMbgMeta || {},
    alias: indexBLu9MDBMbgMeta?.alias || [],
    redirect: indexBLu9MDBMbgMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmoPtbP4LeNuMeta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirmoPtbP4LeNuMeta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirmoPtbP4LeNuMeta || {},
    alias: confirmoPtbP4LeNuMeta?.alias || [],
    redirect: confirmoPtbP4LeNuMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmLngihvUq4AMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirmLngihvUq4AMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirmLngihvUq4AMeta || {},
    alias: confirmLngihvUq4AMeta?.alias || [],
    redirect: confirmLngihvUq4AMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendarD1CemKIKDgMeta?.name ?? "calendar",
    path: calendarD1CemKIKDgMeta?.path ?? "/calendar",
    meta: calendarD1CemKIKDgMeta || {},
    alias: calendarD1CemKIKDgMeta?.alias || [],
    redirect: calendarD1CemKIKDgMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chatkIONNCPdSRMeta?.name ?? "chat",
    path: chatkIONNCPdSRMeta?.path ?? "/chat",
    meta: chatkIONNCPdSRMeta || {},
    alias: chatkIONNCPdSRMeta?.alias || [],
    redirect: chatkIONNCPdSRMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_93y3qtrDredgMeta?.name ?? "community-groups-group_id",
    path: _91group_id_93y3qtrDredgMeta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_93y3qtrDredgMeta || {},
    alias: _91group_id_93y3qtrDredgMeta?.alias || [],
    redirect: _91group_id_93y3qtrDredgMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutNdb8WpV9jdMeta?.name ?? "community-groups-group_id-about",
    path: aboutNdb8WpV9jdMeta?.path ?? "about",
    meta: aboutNdb8WpV9jdMeta || {},
    alias: aboutNdb8WpV9jdMeta?.alias || [],
    redirect: aboutNdb8WpV9jdMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedteqP40b0i4Meta?.name ?? "community-groups-group_id-feed",
    path: feedteqP40b0i4Meta?.path ?? "feed",
    meta: feedteqP40b0i4Meta || {},
    alias: feedteqP40b0i4Meta?.alias || [],
    redirect: feedteqP40b0i4Meta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersC43vSa2w4NMeta?.name ?? "community-groups-group_id-members",
    path: membersC43vSa2w4NMeta?.path ?? "members",
    meta: membersC43vSa2w4NMeta || {},
    alias: membersC43vSa2w4NMeta?.alias || [],
    redirect: membersC43vSa2w4NMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45editQtib8wx6P2Meta?.name ?? "community-profile-edit",
    path: community_45profile_45editQtib8wx6P2Meta?.path ?? "/community-profile-edit",
    meta: community_45profile_45editQtib8wx6P2Meta || {},
    alias: community_45profile_45editQtib8wx6P2Meta?.alias || [],
    redirect: community_45profile_45editQtib8wx6P2Meta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_939cuO7nk7DrMeta?.name ?? "community-profile-id",
    path: _91id_939cuO7nk7DrMeta?.path ?? "/community-profile/:id()",
    meta: _91id_939cuO7nk7DrMeta || {},
    alias: _91id_939cuO7nk7DrMeta?.alias || [],
    redirect: _91id_939cuO7nk7DrMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: communityqHE4O6ZokVMeta?.name ?? "community",
    path: communityqHE4O6ZokVMeta?.path ?? "/community",
    meta: communityqHE4O6ZokVMeta || {},
    alias: communityqHE4O6ZokVMeta?.alias || [],
    redirect: communityqHE4O6ZokVMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93F5Kiv1sM5TMeta?.name ?? "complete-registration-uuid",
    path: _91uuid_93F5Kiv1sM5TMeta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93F5Kiv1sM5TMeta || {},
    alias: _91uuid_93F5Kiv1sM5TMeta?.alias || [],
    redirect: _91uuid_93F5Kiv1sM5TMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93EQt7P7y96UMeta?.name ?? "contracts-unique_id",
    path: _91unique_id_93EQt7P7y96UMeta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93EQt7P7y96UMeta || {},
    alias: _91unique_id_93EQt7P7y96UMeta?.alias || [],
    redirect: _91unique_id_93EQt7P7y96UMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardFCFPUibvqhMeta?.name ?? "dashboard",
    path: dashboardFCFPUibvqhMeta?.path ?? "/dashboard",
    meta: dashboardFCFPUibvqhMeta || {},
    alias: dashboardFCFPUibvqhMeta?.alias || [],
    redirect: dashboardFCFPUibvqhMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexOsEprHYz8YMeta?.name ?? "event-details-event_id",
    path: indexOsEprHYz8YMeta?.path ?? "/event/details/:event_id()",
    meta: indexOsEprHYz8YMeta || {},
    alias: indexOsEprHYz8YMeta?.alias || [],
    redirect: indexOsEprHYz8YMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkout4N8pALOfguMeta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkout4N8pALOfguMeta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkout4N8pALOfguMeta || {},
    alias: checkout4N8pALOfguMeta?.alias || [],
    redirect: checkout4N8pALOfguMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45product3nSjOnY3JxMeta?.name ?? "event-details-event_id-select-product",
    path: select_45product3nSjOnY3JxMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45product3nSjOnY3JxMeta || {},
    alias: select_45product3nSjOnY3JxMeta?.alias || [],
    redirect: select_45product3nSjOnY3JxMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventsJBjxWPptBZMeta?.name ?? "events",
    path: eventsJBjxWPptBZMeta?.path ?? "/events",
    meta: eventsJBjxWPptBZMeta || {},
    alias: eventsJBjxWPptBZMeta?.alias || [],
    redirect: eventsJBjxWPptBZMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93Miufy6TUt1Meta?.name ?? "gifts-uuid",
    path: _91uuid_93Miufy6TUt1Meta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93Miufy6TUt1Meta || {},
    alias: _91uuid_93Miufy6TUt1Meta?.alias || [],
    redirect: _91uuid_93Miufy6TUt1Meta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexbVkOmJkIgxMeta?.name ?? "gifts",
    path: indexbVkOmJkIgxMeta?.path ?? "/gifts",
    meta: indexbVkOmJkIgxMeta || {},
    alias: indexbVkOmJkIgxMeta?.alias || [],
    redirect: indexbVkOmJkIgxMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_93FqB9xVD5WFMeta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_93FqB9xVD5WFMeta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_93FqB9xVD5WFMeta || {},
    alias: _91invitation_id_93FqB9xVD5WFMeta?.alias || [],
    redirect: _91invitation_id_93FqB9xVD5WFMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93eqm51lAEzGMeta?.name ?? "gifts-product-product_id",
    path: _91product_id_93eqm51lAEzGMeta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_93eqm51lAEzGMeta || {},
    alias: _91product_id_93eqm51lAEzGMeta?.alias || [],
    redirect: _91product_id_93eqm51lAEzGMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: indexnR4GPB95jrMeta?.name ?? "index",
    path: indexnR4GPB95jrMeta?.path ?? "/",
    meta: indexnR4GPB95jrMeta || {},
    alias: indexnR4GPB95jrMeta?.alias || [],
    redirect: indexnR4GPB95jrMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_93SZz2MKDPgEMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_93SZz2MKDPgEMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_93SZz2MKDPgEMeta || {},
    alias: _91invitation_unique_id_93SZz2MKDPgEMeta?.alias || [],
    redirect: _91invitation_unique_id_93SZz2MKDPgEMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joinlfoV4UNJx7Meta?.name ?? "join",
    path: joinlfoV4UNJx7Meta?.path ?? "/join",
    meta: joinlfoV4UNJx7Meta || {},
    alias: joinlfoV4UNJx7Meta?.alias || [],
    redirect: joinlfoV4UNJx7Meta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: login2yFVzTei4bMeta?.name ?? "login",
    path: login2yFVzTei4bMeta?.path ?? "/login",
    meta: login2yFVzTei4bMeta || {},
    alias: login2yFVzTei4bMeta?.alias || [],
    redirect: login2yFVzTei4bMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logoutbCoU7b3IDBMeta?.name ?? "logout",
    path: logoutbCoU7b3IDBMeta?.path ?? "/logout",
    meta: logoutbCoU7b3IDBMeta || {},
    alias: logoutbCoU7b3IDBMeta?.alias || [],
    redirect: logoutbCoU7b3IDBMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_9320snd80EhlMeta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_9320snd80EhlMeta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_9320snd80EhlMeta || {},
    alias: _91meeting_type_slug_9320snd80EhlMeta?.alias || [],
    redirect: _91meeting_type_slug_9320snd80EhlMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93qf8qgyMNNxMeta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_93qf8qgyMNNxMeta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_93qf8qgyMNNxMeta || {},
    alias: _91meeting_unique_id_93qf8qgyMNNxMeta?.alias || [],
    redirect: _91meeting_unique_id_93qf8qgyMNNxMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_935Yg3wXvxvfMeta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_935Yg3wXvxvfMeta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_935Yg3wXvxvfMeta || {},
    alias: _91meeting_unique_id_935Yg3wXvxvfMeta?.alias || [],
    redirect: _91meeting_unique_id_935Yg3wXvxvfMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesZ26mfv8DVkMeta?.name ?? "my-favorites",
    path: my_45favoritesZ26mfv8DVkMeta?.path ?? "/my-favorites",
    meta: my_45favoritesZ26mfv8DVkMeta || {},
    alias: my_45favoritesZ26mfv8DVkMeta?.alias || [],
    redirect: my_45favoritesZ26mfv8DVkMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studios3dwo1RN4MRMeta?.name ?? "my-studios",
    path: my_45studios3dwo1RN4MRMeta?.path ?? "/my-studios",
    meta: my_45studios3dwo1RN4MRMeta || {},
    alias: my_45studios3dwo1RN4MRMeta?.alias || [],
    redirect: my_45studios3dwo1RN4MRMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optinRRSDboFd6AMeta?.name ?? "optin",
    path: optinRRSDboFd6AMeta?.path ?? "/optin",
    meta: optinRRSDboFd6AMeta || {},
    alias: optinRRSDboFd6AMeta?.alias || [],
    redirect: optinRRSDboFd6AMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_931Uu3Nbr4kwMeta?.name ?? "podcasts-podcast_id-episodes-episode_id",
    path: _91episode_id_931Uu3Nbr4kwMeta?.path ?? "/podcasts/:podcast_id()/episodes/:episode_id()",
    meta: _91episode_id_931Uu3Nbr4kwMeta || {},
    alias: _91episode_id_931Uu3Nbr4kwMeta?.alias || [],
    redirect: _91episode_id_931Uu3Nbr4kwMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: indexjB54JSmMgjMeta?.name ?? "podcasts-podcast_id",
    path: indexjB54JSmMgjMeta?.path ?? "/podcasts/:podcast_id()",
    meta: indexjB54JSmMgjMeta || {},
    alias: indexjB54JSmMgjMeta?.alias || [],
    redirect: indexjB54JSmMgjMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIccwmmkwMMeta?.name ?? "podcasts",
    path: indexGIccwmmkwMMeta?.path ?? "/podcasts",
    meta: indexGIccwmmkwMMeta || {},
    alias: indexGIccwmmkwMMeta?.alias || [],
    redirect: indexGIccwmmkwMMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93HHZyZNYdoAMeta?.name ?? "product-product_id",
    path: _91product_id_93HHZyZNYdoAMeta?.path ?? "/product/:product_id()",
    meta: _91product_id_93HHZyZNYdoAMeta || {},
    alias: _91product_id_93HHZyZNYdoAMeta?.alias || [],
    redirect: _91product_id_93HHZyZNYdoAMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutpGdEG6MIYJMeta?.name ?? "product-product_id-about",
    path: aboutpGdEG6MIYJMeta?.path ?? "about",
    meta: aboutpGdEG6MIYJMeta || {},
    alias: aboutpGdEG6MIYJMeta?.alias || [],
    redirect: aboutpGdEG6MIYJMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsxALF5SIEZ6Meta?.name ?? "product-product_id-events",
    path: eventsxALF5SIEZ6Meta?.path ?? "events",
    meta: eventsxALF5SIEZ6Meta || {},
    alias: eventsxALF5SIEZ6Meta?.alias || [],
    redirect: eventsxALF5SIEZ6Meta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_936iWJ9jSvaTMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_936iWJ9jSvaTMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_936iWJ9jSvaTMeta || {},
    alias: _91media_id_936iWJ9jSvaTMeta?.alias || [],
    redirect: _91media_id_936iWJ9jSvaTMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45samplefGjQjsbusHMeta?.name ?? "product-product_id-free-sample",
    path: free_45samplefGjQjsbusHMeta?.path ?? "free-sample",
    meta: free_45samplefGjQjsbusHMeta || {},
    alias: free_45samplefGjQjsbusHMeta?.alias || [],
    redirect: free_45samplefGjQjsbusHMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsKxZcU1UcTHMeta?.name ?? "product-product_id-included-products",
    path: included_45productsKxZcU1UcTHMeta?.path ?? "included-products",
    meta: included_45productsKxZcU1UcTHMeta || {},
    alias: included_45productsKxZcU1UcTHMeta?.alias || [],
    redirect: included_45productsKxZcU1UcTHMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93vTlxMnzhRZMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93vTlxMnzhRZMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93vTlxMnzhRZMeta || {},
    alias: _91media_id_93vTlxMnzhRZMeta?.alias || [],
    redirect: _91media_id_93vTlxMnzhRZMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewugN6hUJHgcMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewugN6hUJHgcMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewugN6hUJHgcMeta || {},
    alias: viewugN6hUJHgcMeta?.alias || [],
    redirect: viewugN6hUJHgcMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexfVcLJGeRkMMeta?.name ?? "product-product_id-lessons",
    path: indexfVcLJGeRkMMeta?.path ?? "lessons",
    meta: indexfVcLJGeRkMMeta || {},
    alias: indexfVcLJGeRkMMeta?.alias || [],
    redirect: indexfVcLJGeRkMMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93vF5RaxgsuYMeta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_93vF5RaxgsuYMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93vF5RaxgsuYMeta || {},
    alias: _91media_id_93vF5RaxgsuYMeta?.alias || [],
    redirect: _91media_id_93vF5RaxgsuYMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexqhJ1Cpt9LtMeta?.name ?? "product-product_id-playlists-playlist_id",
    path: indexqhJ1Cpt9LtMeta?.path ?? "playlists/:playlist_id()",
    meta: indexqhJ1Cpt9LtMeta || {},
    alias: indexqhJ1Cpt9LtMeta?.alias || [],
    redirect: indexqhJ1Cpt9LtMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93f0r8qGxyKkMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93f0r8qGxyKkMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93f0r8qGxyKkMeta || {},
    alias: _91media_id_93f0r8qGxyKkMeta?.alias || [],
    redirect: _91media_id_93f0r8qGxyKkMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexs6osu3DL0PMeta?.name ?? "product-product_id-playlists",
    path: indexs6osu3DL0PMeta?.path ?? "playlists",
    meta: indexs6osu3DL0PMeta || {},
    alias: indexs6osu3DL0PMeta?.alias || [],
    redirect: indexs6osu3DL0PMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionspnxbpkTamrMeta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionspnxbpkTamrMeta?.path ?? "private-sessions",
    meta: private_45sessionspnxbpkTamrMeta || {},
    alias: private_45sessionspnxbpkTamrMeta?.alias || [],
    redirect: private_45sessionspnxbpkTamrMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordings4RiRp2kwouMeta?.name ?? "product-product_id-recordings",
    path: recordings4RiRp2kwouMeta?.path ?? "recordings",
    meta: recordings4RiRp2kwouMeta || {},
    alias: recordings4RiRp2kwouMeta?.alias || [],
    redirect: recordings4RiRp2kwouMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsmBxewgcFuAMeta?.name ?? "product-product_id-reviews",
    path: reviewsmBxewgcFuAMeta?.path ?? "reviews",
    meta: reviewsmBxewgcFuAMeta || {},
    alias: reviewsmBxewgcFuAMeta?.alias || [],
    redirect: reviewsmBxewgcFuAMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexUx7jSUca6IMeta?.name ?? "product",
    path: indexUx7jSUca6IMeta?.path ?? "/product",
    meta: indexUx7jSUca6IMeta || {},
    alias: indexUx7jSUca6IMeta?.alias || [],
    redirect: indexUx7jSUca6IMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexjQyTEsV8sdMeta?.name ?? "profile",
    path: indexjQyTEsV8sdMeta?.path ?? "/profile",
    meta: indexjQyTEsV8sdMeta || {},
    alias: indexjQyTEsV8sdMeta?.alias || [],
    redirect: indexjQyTEsV8sdMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesqFZ9LDaG09Meta?.name ?? "profile-purchases",
    path: purchasesqFZ9LDaG09Meta?.path ?? "/profile/purchases",
    meta: purchasesqFZ9LDaG09Meta || {},
    alias: purchasesqFZ9LDaG09Meta?.alias || [],
    redirect: purchasesqFZ9LDaG09Meta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionsIazusDaagZMeta?.name ?? "profile-redemptions",
    path: redemptionsIazusDaagZMeta?.path ?? "/profile/redemptions",
    meta: redemptionsIazusDaagZMeta || {},
    alias: redemptionsIazusDaagZMeta?.alias || [],
    redirect: redemptionsIazusDaagZMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93PNfBNyZuu2Meta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93PNfBNyZuu2Meta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93PNfBNyZuu2Meta || {},
    alias: _91quiz_id_93PNfBNyZuu2Meta?.alias || [],
    redirect: _91quiz_id_93PNfBNyZuu2Meta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93WZU8x3O1B5Meta?.name ?? "reset-password-uid-token",
    path: _91token_93WZU8x3O1B5Meta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_93WZU8x3O1B5Meta || {},
    alias: _91token_93WZU8x3O1B5Meta?.alias || [],
    redirect: _91token_93WZU8x3O1B5Meta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: resetrVbGR0ftW7Meta?.name ?? "reset",
    path: resetrVbGR0ftW7Meta?.path ?? "/reset",
    meta: resetrVbGR0ftW7Meta || {},
    alias: resetrVbGR0ftW7Meta?.alias || [],
    redirect: resetrVbGR0ftW7Meta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shopAMwYEHbNnZMeta?.name ?? "shop",
    path: shopAMwYEHbNnZMeta?.path ?? "/shop",
    meta: shopAMwYEHbNnZMeta || {},
    alias: shopAMwYEHbNnZMeta?.alias || [],
    redirect: shopAMwYEHbNnZMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signuprAF3shDYelMeta?.name ?? "signup",
    path: signuprAF3shDYelMeta?.path ?? "/signup",
    meta: signuprAF3shDYelMeta || {},
    alias: signuprAF3shDYelMeta?.alias || [],
    redirect: signuprAF3shDYelMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: live4gf9hi93fVMeta?.name ?? "streaming-event_id-live",
    path: live4gf9hi93fVMeta?.path ?? "/streaming/:event_id()/live",
    meta: live4gf9hi93fVMeta || {},
    alias: live4gf9hi93fVMeta?.alias || [],
    redirect: live4gf9hi93fVMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tipsSqOFj9OxnKMeta?.name ?? "tips",
    path: tipsSqOFj9OxnKMeta?.path ?? "/tips",
    meta: tipsSqOFj9OxnKMeta || {},
    alias: tipsSqOFj9OxnKMeta?.alias || [],
    redirect: tipsSqOFj9OxnKMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93B9cWcosQOoMeta?.name ?? "waiver-uuid",
    path: _91uuid_93B9cWcosQOoMeta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_93B9cWcosQOoMeta || {},
    alias: _91uuid_93B9cWcosQOoMeta?.alias || [],
    redirect: _91uuid_93B9cWcosQOoMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compactTkIyjskYAAMeta?.name ?? "widgets-calendars-compact",
    path: compactTkIyjskYAAMeta?.path ?? "/widgets/calendars/compact",
    meta: compactTkIyjskYAAMeta || {},
    alias: compactTkIyjskYAAMeta?.alias || [],
    redirect: compactTkIyjskYAAMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: fullyLNmF5LQluMeta?.name ?? "widgets-calendars-full",
    path: fullyLNmF5LQluMeta?.path ?? "/widgets/calendars/full",
    meta: fullyLNmF5LQluMeta || {},
    alias: fullyLNmF5LQluMeta?.alias || [],
    redirect: fullyLNmF5LQluMeta?.redirect,
    component: () => import("/tmp/build_8b18bfcd/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]