import { api } from './api';

import type {
  StudioEmailRecipientResponse,
  StudioEmailResponse,
  StudioMeetingResponse,
  StudioMeetingTypeResponse,
} from '@/typings/globals';
import { formatObjectForBackend } from '@/api/utils/converter';
import _ from 'lodash-es';

const EMAILS_BASE_URL = 'api/emails';
const MEETING_TYPES_BASE_URL = 'api/meetings-types';
const MEETINGS_BASE_URL = 'api/meetings';
const STUDIOS_BASE_URL = 'api/studios';

async function getMeetingTypes(): Promise<
  PaginatedResponse<StudioMeetingTypeResponse>
> {
  const url = `${MEETING_TYPES_BASE_URL}`;
  const result = await api.get<PaginatedResponse<StudioMeetingTypeResponse>>(
    url
  );
  return result.data;
}

async function getMeetings(
  page = 1,
  q: string,
  status: string
): Promise<PaginatedResponse<StudioMeetingResponse>> {
  const url = `${MEETINGS_BASE_URL}?page=${page}&q=${encodeURIComponent(
    q
  )}&status=${status}`;
  const result = await api.get<PaginatedResponse<StudioMeetingResponse>>(url);
  return result.data;
}

async function getMeeting(uniqueId: string): Promise<StudioMeetingResponse> {
  const url = `${MEETINGS_BASE_URL}/${uniqueId}`;
  const result = await api.get<StudioMeetingResponse>(url);
  return result.data;
}

async function getPublicMeeting(
  studioURL: string,
  uniqueId: string
): Promise<StudioMeetingResponse> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/meetings/${uniqueId}`;
  const result = await api.get<StudioMeetingResponse>(url);
  return result.data;
}

async function cancelPublicMeeting(
  studioURL: string,
  uniqueId: string
): Promise<any> {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/meetings/${uniqueId}/cancel`
  );
  return result.data;
}

async function reschedulePublicMeeting(
  studioURL: string,
  uniqueId: string,
  newStartTime: Date,
  timezone: string
): Promise<any> {
  const payload = {
    new_start_time: newStartTime.toISOString(),
    timezone: timezone,
  };
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/meetings/${uniqueId}/reschedule`,
    payload
  );
  return result.data;
}

async function getPublicMeetingType(
  studioURL: string,
  slug: string
): Promise<StudioMeetingTypeResponse> {
  const result = await api.get<StudioMeetingTypeResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/meeting-types/${slug}`
  );
  return result.data;
}

async function getPublicMeetingTypeTimeSlots(
  studioURL: string,
  meetingTypeSlug: string,
  timezone: string,
  startDate: Date,
  endDate: Date
): Promise<StudioMeetingTypeResponse> {
  const params = {
    tz: timezone,
    start: startDate?.toISOString(),
    end: endDate?.toISOString(),
  };
  const result = await api.get<StudioMeetingTypeResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/meeting-types/${meetingTypeSlug}/get_time_slots`,
    { params }
  );
  return result.data;
}

async function bookPublicMeetingSlot(
  studioURL: string,
  meetingTypeSlug: string,
  slot: Date,
  timezone: string,
  bookingFullName: string,
  bookingEmail: string,
  form: any
): Promise<StudioMeetingResponse> {
  const payload = {
    slot: slot.toISOString(),
    timezone,
    full_name: bookingFullName,
    email: bookingEmail,
    form,
  };
  const result = await api.post<StudioMeetingResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/meeting-types/${meetingTypeSlug}/book_public_meeting`,
    payload
  );
  return result.data;
}

async function getMeetingType(
  meetingTypeId: number
): Promise<StudioMeetingTypeResponse> {
  const result = await api.get<StudioMeetingTypeResponse>(
    `${MEETING_TYPES_BASE_URL}/${meetingTypeId}`
  );
  return result.data;
}

async function duplicateMeetingType(meetingTypeId: number): Promise<any> {
  const result = await api.post(
    `${MEETING_TYPES_BASE_URL}/${meetingTypeId}/duplicate`
  );
  return result.data;
}

async function deleteMeetingType(meetingTypeId: number): Promise<any> {
  const result = await api.delete(`${MEETING_TYPES_BASE_URL}/${meetingTypeId}`);
  return result.data;
}

async function cancelMeeting(id: number): Promise<any> {
  const result = await api.post(`${MEETINGS_BASE_URL}/${id}/cancel`);
  return result.data;
}

async function rescheduleMeeting(
  id: string,
  newStartTime: Date,
  timezone: string
): Promise<any> {
  const payload = {
    new_start_time: newStartTime.toISOString(),
    timezone: timezone,
  };
  const result = await api.post(
    `${MEETINGS_BASE_URL}/${id}/${id}/reschedule`,
    payload
  );
  return result.data;
}

async function addMeetingType(
  data: StudioMeetingTypeResponse
): Promise<StudioMeetingTypeResponse> {
  const payload = formatObjectForBackend(_.cloneDeep(data));
  const result = await api.post(`${MEETING_TYPES_BASE_URL}`, payload);
  return result.data;
}

async function updateMeetingType(
  id: number,
  data: StudioMeetingTypeResponse
): Promise<StudioMeetingTypeResponse> {
  const payload = formatObjectForBackend(_.cloneDeep(data));
  const result = await api.put(`${MEETING_TYPES_BASE_URL}/${id}`, payload);
  return result.data;
}

async function getMeetingTypeTimeSlots(
  meetingTypeId: number,
  timezone: string,
  startDate: Date,
  endDate: Date
): Promise<StudioMeetingTypeResponse> {
  const params = {
    tz: timezone,
    start: startDate?.toISOString(),
    end: endDate?.toISOString(),
  };
  const result = await api.get<StudioMeetingTypeResponse>(
    `${MEETING_TYPES_BASE_URL}/meeting-types/${meetingTypeId}/get_time_slots`,
    { params }
  );
  return result.data;
}

async function getEmails(
  page = 1,
  q: string,
  status: string,
  startDate: Date | null,
  endDate: Date | null
): Promise<PaginatedResponse<StudioEmailResponse>> {
  let url = `${EMAILS_BASE_URL}?page=${page}`;
  if (q) {
    url += `&q=${encodeURIComponent(q)}`;
  }
  if (status) {
    url += `&status=${status}`;
  }
  if (startDate) {
    url += `&date_from=${startDate.toISOString()}`;
  }
  if (endDate) {
    url += `&date_to=${endDate.toISOString()}`;
  }
  const result = await api.get<PaginatedResponse<StudioEmailResponse>>(url);
  return result.data;
}

async function getEmail(emailId: number): Promise<StudioEmailResponse> {
  const result = await api.get<StudioEmailResponse>(
    `${EMAILS_BASE_URL}/${emailId}`
  );
  return result.data;
}

async function getEmailAudience(
  data: StudioEmailResponse
): Promise<StudioEmailResponse> {
  const payload = formatObjectForBackend(_.cloneDeep(data));
  const result = await api.post<StudioEmailResponse>(
    `${EMAILS_BASE_URL}/${payload.id}/audience`,
    payload
  );
  return result.data;
}

async function duplicateEmail(emailId: number): Promise<any> {
  const result = await api.post(`${EMAILS_BASE_URL}/${emailId}/duplicate`);
  return result.data;
}

async function deleteEmail(emailId: number): Promise<any> {
  const result = await api.delete(`${EMAILS_BASE_URL}/${emailId}`);
  return result.data;
}

async function addEmail(
  data: StudioEmailResponse
): Promise<StudioEmailResponse> {
  const payload = formatObjectForBackend(_.cloneDeep(data));
  const result = await api.post(`${EMAILS_BASE_URL}`, payload);
  return result.data;
}

async function updateEmail(
  id: number,
  data: StudioEmailResponse
): Promise<StudioEmailResponse> {
  const payload = formatObjectForBackend(_.cloneDeep(data));
  const result = await api.put(`${EMAILS_BASE_URL}/${id}`, payload);
  return result.data;
}

async function sendEmail(id: number): Promise<any> {
  const result = await api.post(`${EMAILS_BASE_URL}/${id}/send`);
  return result.data;
}

async function sendTestEmail(id: number): Promise<any> {
  const result = await api.post(`${EMAILS_BASE_URL}/${id}/test_send`);
  return result.data;
}

async function getEmailPreview(id: number): Promise<any> {
  const result = await api.get(`${EMAILS_BASE_URL}/${id}/preview`);
  return result.data;
}

async function getEmailRecipients(
  studioEmailId: number,
  page = 1,
  q: string,
  opened: boolean | null,
  clicked: boolean | null,
  unsubscribed: boolean | null
): Promise<PaginatedResponse<StudioEmailRecipientResponse>> {
  let url = `${EMAILS_BASE_URL}/${studioEmailId}/recipients?page=${page}`;
  if (q) {
    url += `&q=${encodeURIComponent(q)}`;
  }
  if (opened) {
    url += `&opened=True`;
  }
  if (clicked) {
    url += `&clicked=True`;
  }
  if (unsubscribed) {
    url += `&unsubscribed=True`;
  }
  const result = await api.get<PaginatedResponse<StudioEmailRecipientResponse>>(
    url
  );
  return result.data;
}

export default {
  getMeetingTypes,
  getMeetingType,
  getPublicMeetingType,
  getPublicMeetingTypeTimeSlots,
  bookPublicMeetingSlot,
  duplicateMeetingType,
  deleteMeetingType,
  addMeetingType,
  updateMeetingType,
  getMeetings,
  getMeeting,
  getPublicMeeting,
  reschedulePublicMeeting,
  cancelPublicMeeting,
  cancelMeeting,
  rescheduleMeeting,
  getMeetingTypeTimeSlots,
  addEmail,
  getEmails,
  getEmail,
  getEmailAudience,
  duplicateEmail,
  deleteEmail,
  updateEmail,
  sendEmail,
  sendTestEmail,
  getEmailPreview,
  getEmailRecipients,
};
