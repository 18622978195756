import { api } from './api';

const MEDIA_BASE_URL = 'api/media';
const CATEGORIES_BASE_URL = 'api/categories';
const INSTRUCTORS_BASE_URL = 'api/instructors';
const FILES_BASE_URL = 'api/files';
const STUDIOS_BASE_URL = 'api/studios';

// Media
async function getTeacherMedia(
  page = 1,
  fileType: string | null,
  instructor: number | null,
  options: number | null,
  q: string | null
): Promise<PaginatedResponse<TeacherMediaResponse>> {
  let url = `${MEDIA_BASE_URL}?page=${page}`;
  if (fileType) url += `&file_type=${encodeURIComponent(fileType)}`;
  if (instructor) url += `&instructor=${instructor}`;
  if (options) url += `&options=${options}`;
  if (q) url += `&q=${q}`;

  const result = await api.get<PaginatedResponse<TeacherMediaResponse>>(url);
  return result.data;
}

async function getS3UploadUrl(
  filename: string,
  content_type: string
): Promise<any> {
  const url = `${FILES_BASE_URL}/s3-upload-url/`;
  const data = {
    filename,
    content_type,
  };
  const response = await api.post(url, data);
  return response.data;
}

async function getVideoDownloadLink(userFileId): Promise<any> {
  const url = `${FILES_BASE_URL}/${userFileId}/get_video_download_link`;
  const response = await api.get(url);
  return response.data;
}

async function duplicate(mediaId): Promise<any> {
  const url = `${MEDIA_BASE_URL}/${mediaId}/duplicate`;
  const response = await api.post(url);
  return response.data;
}

async function canUploadVideo(): Promise<any> {
  const url = `${FILES_BASE_URL}/upload_video`;
  const response = await api.get(url);
  return response.data;
}

async function getFile(id: string): Promise<any> {
  const url = `${FILES_BASE_URL}/${id}`;
  const response = await api.get(url);
  return response.data;
}

async function deleteFile(id: number): Promise<void> {
  await api.delete(`${FILES_BASE_URL}/${id}`);
}

function getFormDataForUpload(customOptions = {}) {
  const formData = new FormData();
  const options = {
    file: '',
    embedded_url: '',
    custom_thumbnail: '',
    ...customOptions,
  };

  Object.keys(options).map(function (key) {
    if (key === 'file' && options[key] !== '') {
      try {
        const filename = `${new Date().getTime()}-${options[key]['name']}`;
        formData.append(key, options[key], filename);
      } catch (error) {
        // if this fails in any old browser, try to fall back to default
        formData.append(key, options[key]);
      }
    } else {
      formData.append(key, options[key]);
    }
  });

  return formData;
}

async function uploadMediaFile(customOptions = {}): Promise<MediaResponse> {
  const formData = getFormDataForUpload(customOptions);
  const result = await api.post<MediaResponse>(FILES_BASE_URL, formData);
  return result.data;
}

async function uploadStudentMediaFile(
  customOptions = {},
  studioURL
): Promise<MediaResponse> {
  const formData = getFormDataForUpload(customOptions);
  const result = await api.post<MediaResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/files`,
    formData
  );
  return result.data;
}

async function updateMediaFile(id, customOptions = {}): Promise<MediaResponse> {
  const formData = new FormData();
  const options = {
    file: '',
    embedded_url: '',
    custom_thumbnail: '',
    ...customOptions,
  };

  Object.keys(options).map(function (key) {
    formData.append(key, options[key]);
  });

  const result = await api.put<MediaResponse>(
    `${FILES_BASE_URL}/${id}`,
    formData
  );
  return result.data;
}

async function patchMediaFile(id, options = {}): Promise<MediaResponse> {
  const formData = new FormData();
  Object.keys(options).map(function (key) {
    formData.append(key, options[key]);
  });

  const result = await api.patch<MediaResponse>(
    `${FILES_BASE_URL}/${id}`,
    formData
  );
  return result.data;
}

async function clearFileThumbnails(id): Promise<MediaResponse> {
  const result = await api.put<MediaResponse>(
    `${FILES_BASE_URL}/${id}/clear_thumbnails`
  );
  return result.data;
}

async function updatePlayback(data: UpdatePlaybackRequest): Promise<void> {
  await api.put<PlaybackResponse>(
    `${FILES_BASE_URL}/${data.id}/update_playback`,
    { playback_start: data.playback_start, playback_end: data.playback_end }
  );
}

async function toggleFileDeletable(fileId): Promise<MediaResponse> {
  const result = await api.put<MediaResponse>(
    `${FILES_BASE_URL}/${fileId}/toggle_deletable`,
    {}
  );
  return result.data;
}

async function createMedia(
  data: CreateMediaRequest
): Promise<TeacherMediaResponse> {
  const result = await api.post<TeacherMediaResponse>(MEDIA_BASE_URL, data);
  return result.data;
}

async function updateMedia(
  data: UpdateMediaRequest
): Promise<TeacherMediaResponse> {
  const result = await api.put<TeacherMediaResponse>(
    `${MEDIA_BASE_URL}/${data.id}`,
    data
  );
  return result.data;
}

async function getMedia(id: number): Promise<TeacherMediaResponse> {
  const result = await api.get<TeacherMediaResponse>(`${MEDIA_BASE_URL}/${id}`);
  return result.data;
}

async function deleteMedia(id: number): Promise<void> {
  await api.delete(`${MEDIA_BASE_URL}/${id}`);
}

// Categories
async function getTeacherCategories(): Promise<TeacherCategoryResponse[]> {
  const result = await api.get<TeacherCategoryResponse[]>(CATEGORIES_BASE_URL);
  return result.data;
}

async function createCategory(
  data: CreateCategoryRequest
): Promise<TeacherCategoryResponse> {
  const result = await api.post<TeacherCategoryResponse>(
    CATEGORIES_BASE_URL,
    data
  );
  return result.data;
}

async function updateCategory(
  data: UpdateCategoryRequest
): Promise<TeacherCategoryResponse> {
  const result = await api.put<TeacherCategoryResponse>(
    `${CATEGORIES_BASE_URL}/${data.category_id}`,
    data
  );
  return result.data;
}

async function deleteCategory(id: number): Promise<void> {
  await api.delete(`${CATEGORIES_BASE_URL}/${id}`);
}

// Instructors
async function getTeacherInstructors(): Promise<InstructorResponse[]> {
  const result = await api.get<InstructorResponse[]>(INSTRUCTORS_BASE_URL);
  return result.data;
}

async function createInstructor(
  data: CreateInstructorRequest
): Promise<InstructorResponse> {
  const result = await api.post<InstructorResponse>(INSTRUCTORS_BASE_URL, data);
  return result.data;
}

async function updateInstructor(
  data: UpdateInstructorRequest
): Promise<InstructorResponse> {
  const result = await api.put<InstructorResponse>(
    `${INSTRUCTORS_BASE_URL}/${data.id}`,
    data
  );
  return result.data;
}

async function deleteInstructor(id: number): Promise<void> {
  await api.delete(`${INSTRUCTORS_BASE_URL}/${id}`);
}
async function deleteInstructorPhoto(id: number): Promise<void> {
  await api.delete(`${FILES_BASE_URL}/${id}`);
}

async function searchUnsplashImages(query, page, pageSize): Promise<any> {
  const params = {
    query: query,
    page: page,
    per_page: pageSize,
  };
  const result = await api.get<any>(`${MEDIA_BASE_URL}/unsplash_search`, {
    params,
  });
  return result.data;
}

async function unsplashMarkDownload(downloadUrl): Promise<any> {
  // Unsplash TOS: every use of unsplash image must marked with a call to their download api for tracking
  // https://help.unsplash.com/en/articles/2511258-guideline-triggering-a-download
  const params = {
    download_url: downloadUrl,
  };
  const result = await api.post<any>(
    `${MEDIA_BASE_URL}/unsplash_mark_download`,
    params
  );
  return result.data;
}

async function getMediaViews(
  mediaId,
  page = 1,
  q: string | null
): Promise<PaginatedResponse<TeacherMediaResponse>> {
  let url = `${MEDIA_BASE_URL}/${mediaId}/views?page=${page}`;
  if (q) url += `&q=${q}`;

  const result = await api.get<PaginatedResponse<TeacherMediaResponse>>(url);
  return result.data;
}

async function getMediaViewsCSV(mediaId): Promise<BlobPart> {
  const url = `${MEDIA_BASE_URL}/${mediaId}/views/export_to_csv`;
  const result = await api.get<BlobPart>(url);
  return result.data;
}

async function getMediaLikes(
  mediaId,
  page = 1,
  q: string | null
): Promise<PaginatedResponse<TeacherMediaResponse>> {
  let url = `${MEDIA_BASE_URL}/${mediaId}/likes?page=${page}`;
  if (q) url += `&q=${q}`;

  const result = await api.get<PaginatedResponse<TeacherMediaResponse>>(url);
  return result.data;
}

async function getMediaLikesCSV(mediaId): Promise<BlobPart> {
  const url = `${MEDIA_BASE_URL}/${mediaId}/likes/export_to_csv`;
  const result = await api.get<BlobPart>(url);
  return result.data;
}

export default {
  getTeacherMedia,
  createMedia,
  getMedia,
  updateMedia,
  deleteMedia,
  getTeacherCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  getTeacherInstructors,
  createInstructor,
  updateInstructor,
  deleteInstructor,
  uploadMediaFile,
  uploadStudentMediaFile,
  updateMediaFile,
  patchMediaFile,
  clearFileThumbnails,
  updatePlayback,
  toggleFileDeletable,
  getS3UploadUrl,
  canUploadVideo,
  getVideoDownloadLink,
  duplicate,
  getFile,
  deleteFile,
  deleteInstructorPhoto,
  searchUnsplashImages,
  unsplashMarkDownload,
  getMediaViews,
  getMediaViewsCSV,
  getMediaLikes,
  getMediaLikesCSV,
};
