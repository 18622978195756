import Globalize from 'globalize';
import supplemental from 'cldr-data/supplemental/currencyData.json';
import likelySubtags from 'cldr-data/supplemental/likelySubtags.json';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import enCurrencies from 'cldr-data/main/en/currencies.json';
import enNumbers from 'cldr-data/main/en/numbers.json';
/*
import frCurrencies from 'cldr-data/main/fr/currencies.json';
import frNumbers from 'cldr-data/main/fr/numbers.json';
import deCurrencies from 'cldr-data/main/de/currencies.json';
import deNumbers from 'cldr-data/main/de/numbers.json';
import esCurrencies from 'cldr-data/main/es/currencies.json';
import esNumbers from 'cldr-data/main/es/numbers.json';
import hiCurrencies from 'cldr-data/main/hi/currencies.json';
import hiNumbers from 'cldr-data/main/hi/numbers.json';
import itCurrencies from 'cldr-data/main/it/currencies.json';
import itNumbers from 'cldr-data/main/it/numbers.json';
import jaCurrencies from 'cldr-data/main/ja/currencies.json';
import jaNumbers from 'cldr-data/main/ja/numbers.json';
import nlCurrencies from 'cldr-data/main/nl/currencies.json';
import nlNumbers from 'cldr-data/main/nl/numbers.json';
import ptCurrencies from 'cldr-data/main/pt/currencies.json';
import ptNumbers from 'cldr-data/main/pt/numbers.json';
import ruCurrencies from 'cldr-data/main/ru/currencies.json';
import ruNumbers from 'cldr-data/main/ru/numbers.json';
import zhCurrencies from 'cldr-data/main/zh/currencies.json';
import zhNumbers from 'cldr-data/main/zh/numbers.json';
*/
import moment from 'moment';
import i18n from '@/utils/localization';

Globalize.load(supplemental);
Globalize.load(likelySubtags);
Globalize.load(numberingSystems);

Globalize.load(
  enCurrencies
  /*
  frCurrencies,
  deCurrencies,
  esCurrencies,
  hiCurrencies,
  itCurrencies,
  jaCurrencies,
  nlCurrencies,
  ptCurrencies,
  ruCurrencies,
  zhCurrencies
  */
);
Globalize.load(
  enNumbers
  /*
  frNumbers,
  deNumbers,
  esNumbers,
  hiNumbers,
  itNumbers,
  jaNumbers,
  nlNumbers,
  ptNumbers,
  ruNumbers,
  zhNumbers
  */
);

let globalCurrency: string;

function formatCurrency(number: string, currency?: string): string {
  return Globalize.currencyFormatter(currency || globalCurrency)(
    parseFloat(number ?? 0)
  );
}

function formatNumber(number: number): string {
  return Globalize.numberFormatter()(number);
}

function setLocal(lang = 'en', currency = 'USD'): void {
  globalCurrency = currency;
  Globalize.locale(lang);
  i18n.locale = lang;
  moment.locale(lang);
}

type Format =
  | 'time'
  | 'date'
  | 'date-word'
  | 'date-word-long'
  | 'date-time'
  | 'date-time-long';
function formatDate(
  date: string,
  format: Format,
  invalidDateLabelOverride?: string
): string {
  if (invalidDateLabelOverride && !moment(date).isValid()) {
    return invalidDateLabelOverride;
  }
  switch (format) {
    case 'time':
      return moment(date).format('LT');
    case 'date':
      return moment(date).format('l');
    case 'date-word':
      return moment(date).format('ll');
    case 'date-word-long':
      return moment(date).format('dddd, ll');
    case 'date-time-long':
      return moment(date).format('dddd, lll');
    case 'date-time':
    default:
      return moment(date).format('lll');
  }
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export { formatCurrency, setLocal, formatNumber, formatDate, formatBytes };
