let api = undefined;

function canvaDesignButton() {
  const promise = new Promise((resolve, reject) => {
    const interval = setInterval(
      function () {
        if (window.Canva && window.Canva.DesignButton) {
          resolve(window.Canva.DesignButton);
          clearInterval(interval);
        }
      },
      200,
      50
    );
  });
  return promise;
}

export async function initCanvaButtonApi(apiKey) {
  const buttonApi: any = await canvaDesignButton();
  api = await buttonApi.initialize({ apiKey });
  return api;
}

export function getCanvaButtonApi() {
  return api;
}
