import { api } from './api';

const AFFILIATES_BASE_URL = 'api/affiliates';

async function trackAffiliateLinkClick(code, valid) {
  const payload = {
    ref: code,
    valid: valid,
  };
  const result = await api.post<DashboardResponse>(
    `${AFFILIATES_BASE_URL}/track-aff-click`,
    payload
  );
  return result.data;
}

async function getAffiliateStats() {
  const result = await api.get<DashboardResponse>(
    `${AFFILIATES_BASE_URL}/stats`
  );
  return result.data;
}

export default {
  trackAffiliateLinkClick,
  getAffiliateStats,
};
