import { api } from './api';

const AUTH_BASE_URL = '/auth';
const STUDENT_BASE_URL = 'api/students/me';
const STUDENTS_BASE_URL = 'api/students';
const TEACHERS_BASE_URL = 'api/teachers/me';

async function login(email: string, password: string): Promise<LoginResponse> {
  const result = await api.post<LoginResponse>(`${AUTH_BASE_URL}/login/`, {
    email,
    password,
  });
  return result.data;
}

async function magicCodeLogin(magicCode: string): Promise<LoginResponse> {
  const result = await api.post(`${AUTH_BASE_URL}/magic-code/`, {
    magic_code: magicCode,
  });
  return result.data;
}

async function googleLogin(accessToken: string): Promise<LoginResponse> {
  const result = await api.post(`${AUTH_BASE_URL}/google/`, {
    access_token: accessToken,
  });
  return result.data;
}

async function googleCustomLogin(
  accessToken: string,
  uid: string,
  email: string
): Promise<LoginResponse> {
  const result = await api.post(`${AUTH_BASE_URL}/google-custom/`, {
    access_token: accessToken,
    google_user_id: uid,
    google_user_email: email,
  });
  return result.data;
}

async function facebookLogin(accessToken: string): Promise<LoginResponse> {
  const result = await api.post(`${AUTH_BASE_URL}/facebook/`, {
    access_token: accessToken,
  });
  return result.data;
}

async function facebookCustomLogin(
  accessToken: string,
  uid: string,
  email: string
): Promise<LoginResponse> {
  const result = await api.post(`${AUTH_BASE_URL}/facebook-custom/`, {
    access_token: accessToken,
    facebook_user_id: uid,
    facebook_user_email: email,
  });
  return result.data;
}

async function appleLogin(
  uid: string,
  email: string,
  fullName: string,
  idToken: string
): Promise<LoginResponse> {
  const result = await api.post(`${AUTH_BASE_URL}/apple/`, {
    apple_user_id: uid,
    apple_user_email: email,
    full_name: fullName,
    id_token: idToken,
  });
  return result.data;
}

async function resetPassword(email: string, studioURL: string) {
  const result = await api.post(`${AUTH_BASE_URL}/password/reset/`, {
    email,
    studio_url: studioURL,
    new_format: true,
  });
  return result.data;
}

async function resetConfirm(new_password1: string, token: string, uid: string) {
  const result = await api.post(`${AUTH_BASE_URL}/password/reset/confirm/`, {
    new_password1,
    token,
    uid,
  });
  return result.data;
}

async function changePassword(
  newPassword1: string,
  newPassword2: string
): Promise<void> {
  const result = await api.post(`${AUTH_BASE_URL}/password/change/`, {
    new_password1: newPassword1,
    new_password2: newPassword2,
  });
  return result.data;
}

async function loadUserInfo(): Promise<UsersInfoResponse> {
  const result = await api.get<UsersInfoResponse>('api/usersinfo/me');
  return result.data;
}

async function meStudent(): Promise<UserResponse> {
  const result = await api.get<UserResponse>('api/students/me');
  return result.data;
}

async function updateStudent(user: UserResponse): Promise<UserResponse> {
  const result = await api.put<UserResponse>('api/students/me', user);
  return result.data;
}
async function toggleStudentEventNotifications(
  toggle: boolean
): Promise<UserResponse> {
  const result = await api.put<UserResponse>('api/students/me', {
    student: { event_notifications_enabled: toggle },
  });
  return result.data;
}
async function toggleStudentEmailNotifications(
  toggle: boolean
): Promise<UserResponse> {
  const result = await api.put<UserResponse>('api/students/me', {
    emails_enabled: toggle,
    student: {},
  });
  return result.data;
}
async function toggleStudentSMSNotifications(
  toggle: boolean
): Promise<UserResponse> {
  const result = await api.put<UserResponse>('api/students/me', {
    student: {
      sms_notifications_enabled: toggle,
    },
  });
  return result.data;
}
async function toggleStudentEmail30mins(
  toggle: boolean
): Promise<UserResponse> {
  const result = await api.put<UserResponse>('api/students/me', {
    student: { email_30min_event_notifications_enabled: toggle },
  });
  return result.data;
}
async function toggleStudentEmail1hr(toggle: boolean): Promise<UserResponse> {
  const result = await api.put<UserResponse>('api/students/me', {
    student: { email_1h_event_notifications_enabled: toggle },
  });
  return result.data;
}

async function toggleCommunityDailyDigestEmail(
  toggle: boolean
): Promise<UserResponse> {
  const result = await api.put<UserResponse>('api/students/me', {
    student: { email_community_daily_digest_enabled: toggle },
  });
  return result.data;
}

async function toggleCommunityChatNotificationsEmail(
  toggle: boolean
): Promise<UserResponse> {
  const result = await api.put<UserResponse>('api/students/me', {
    student: { email_community_chat_notifications_enabled: toggle },
  });
  return result.data;
}

async function toggleCommunityRealtimeMentionsEmail(
  toggle: boolean
): Promise<UserResponse> {
  const result = await api.put<UserResponse>('api/students/me', {
    student: { email_community_realtime_mentions_enabled: toggle },
  });
  return result.data;
}

async function togglePrivatePodcastNotificationsEmail(
  toggle: boolean
): Promise<UserResponse> {
  const result = await api.put<UserResponse>('api/students/me', {
    student: { email_private_podcast_notifications_enabled: toggle },
  });
  return result.data;
}

async function promoteStudentToTeacher(data): Promise<any> {
  const result = await api.post('api/students/me/promote', data);
  return result.data;
}

async function promoteStudentToTeacherWithCode(data): Promise<any> {
  const result = await api.post('api/students/me/code_promote', data);
  return result.data;
}

async function meTeacher(): Promise<TeacherResponse> {
  const result = await api.get<TeacherResponse>('api/teachers/me');
  return result.data;
}

async function getStudioUsersAndInvitations(): Promise<
  UsersAndInvitationsResponse
> {
  const result = await api.get<UsersAndInvitationsResponse>(
    'api/teachers/me/users_and_invitations'
  );
  return result.data;
}

async function getAffiliateInfo(): Promise<AffiliateResponse> {
  const result = await api.get<AffiliateResponse>(`api/teachers/me/affiliate`);
  return result.data;
}

async function updateAffiliateInfo(payload): Promise<AffiliateResponse> {
  const result = await api.post<AffiliateResponse>(
    `api/teachers/me/affiliate`,
    payload
  );
  return result.data;
}

async function removeTeamMember(userId): Promise<UsersAndInvitationsResponse> {
  const result = await api.post<UsersAndInvitationsResponse>(
    `api/teachers/me/remove_team_member`,
    { user_id: userId }
  );
  return result.data;
}

async function inviteAdmin(email): Promise<any> {
  const result = await api.post('api/invitations/invite_admin', { email });
  return result.data;
}

async function removeInvitation(invitationUniqueId): Promise<void> {
  await api.delete(`api/invitations/${invitationUniqueId}`);
}

async function getInvitation(
  invitationUniqueId
): Promise<StudioInvitationResponse> {
  const result = await api.get<StudioInvitationResponse>(
    `api/invitations/${invitationUniqueId}/public`
  );
  return result.data;
}

async function acceptInvitation(invitationUniqueId): Promise<any> {
  const result = await api.post<any>(
    `api/invitations/${invitationUniqueId}/accept_invitation`
  );
  return result.data;
}

async function meTeacherSetupIntent(): Promise<TeacherResponse> {
  const result = await api.post<TeacherResponse>('api/teachers/me/setupintent');
  return result.data;
}

async function teacherSubscribe(data): Promise<any> {
  const result = await api.post<any>(`api/teacher-subscribe`, data);
  return result.data;
}

async function getPlans(campaign): Promise<PlanResponse> {
  const result = await api.get<PlanResponse>('api/plans', {
    params: { campaign },
  });
  return result.data;
}

async function getCoachingProgramme(
  programmeCode
): Promise<CoachingProgrammeResponse> {
  const result = await api.get<CoachingProgrammeResponse>(`api/programmes`, {
    params: { code: programmeCode },
  });
  return result.data;
}

async function updateTeacher(
  teacher: TeacherResponse
): Promise<TeacherResponse> {
  const result = await api.put<TeacherResponse>('api/teachers/me', teacher);
  return result.data;
}

async function updateTeacherSignupInfo(
  data: SignupInfoResponse
): Promise<SignupInfoResponse> {
  const result = await api.put<SignupInfoResponse>(
    'api/teachers/me/signup_info',
    data
  );
  return result.data;
}

async function populateTeacherStudio(): Promise<void> {
  const result = await api.post<void>('api/teachers/me/populate_studio');
  return result.data;
}

async function getTeacherAnnouncement(): Promise<TeacherAnnouncementResponse> {
  const result = await api.get<TeacherAnnouncementResponse>(
    'api/teachers/me/announcement'
  );
  return result.data;
}

async function updateTeacherAnnouncement(
  data: TeacherAnnouncementResponse
): Promise<TeacherAnnouncementResponse> {
  const result = await api.put<TeacherAnnouncementResponse>(
    'api/teachers/me/announcement',
    data
  );
  return result.data;
}

async function toggleTeacherWaiver(waiverState): Promise<TeacherResponse> {
  const result = await api.put<TeacherResponse>('api/teachers/me', {
    teacher: { is_waiver_enabled: waiverState },
  });
  return result.data;
}

async function toggleTeacherInstagramFeed(
  instagramFeedState
): Promise<TeacherResponse> {
  const result = await api.put<TeacherResponse>('api/teachers/me', {
    teacher: { instagram_feed: instagramFeedState },
  });
  return result.data;
}

async function disconnectTeacherInstagram(): Promise<TeacherResponse> {
  const result = await api.put<TeacherResponse>('api/teachers/me', {
    teacher: {
      instagram: null,
      instagram_connected: false,
      instagram_feed: false,
    },
  });
  return result.data;
}

async function verifyCoupon(
  code: string,
  campaign: string | null = null
): Promise<void> {
  const result = await api.get('/api/plans/verify_coupon', {
    params: { code, campaign },
  });
  return result.data;
}

async function verifyProgrammeCoupon(
  programmeCode,
  code: string
): Promise<void> {
  const result = await api.get(`/api/programmes/verify_coupon`, {
    params: {
      code: programmeCode,
      coupon_code: code,
    },
  });
  return result.data;
}

async function verifyEmail(email: string): Promise<void> {
  const result = await api.post('api/accounts/verify-email/', {
    email,
  });
  return result.data;
}

async function verifyNewEmail(email: string): Promise<void> {
  const result = await api.post('api/accounts/verify-new-email/', {
    email,
  });
  return result.data;
}

async function captchaVerify(token: string): Promise<void> {
  const result = await api.post('v2/accounts/recaptcha_verify', { token });
  return result.data;
}

async function fetchLink(link: string, cancelToken): Promise<void> {
  const result = await api.post(
    'api/accounts/fetch-link/',
    { link },
    { cancelToken }
  );
  return result.data;
}

async function connectInstagramAccount(code): Promise<any> {
  const result = await api.patch('v2/accounts/instagram', { code });
  return result.data;
}

async function getInstagramRequestAuthURL(): Promise<any> {
  const result = await api.post('/v2/accounts/instagram');
  return result.data;
}

async function getStudioByCustomDomain(
  customDomainHost
): Promise<StudioResponse> {
  const result = await api.get(`/v2/teachers?domain=${customDomainHost}`);
  return result.data;
}

async function registerTeacher(
  data: RegisterTeacherRequest
): Promise<RegisterTeacherResponse> {
  const result = await api.post<RegisterTeacherResponse>(
    'auth/registration/teacher',
    data
  );
  return result.data;
}

async function registerExternalAffiliate(
  data: RegisterTeacherRequest
): Promise<RegisterTeacherResponse> {
  const result = await api.post<RegisterTeacherResponse>(
    'auth/registration/external-affiliate',
    data
  );
  return result.data;
}

async function registerBio(
  data: RegisterTeacherRequest
): Promise<RegisterTeacherResponse> {
  const result = await api.post<RegisterTeacherResponse>(
    'auth/registration/bio',
    data
  );
  return result.data;
}

async function codeRegister(
  data: RegisterTeacherRequest
): Promise<RegisterTeacherResponse> {
  const result = await api.post<RegisterTeacherResponse>(
    'auth/registration/code',
    data
  );
  return result.data;
}

async function register(
  data: RegisterStudentRequest
): Promise<RegisterTeacherResponse> {
  const result = await api.post<LoginResponse>('auth/registration/', data);
  return result.data;
}

async function getSetupIntent(data): Promise<any> {
  const result = await api.post<any>(`auth/registration/setupintent`, data);
  return result.data;
}

async function getUserByUUID(uuid): Promise<any> {
  const result = await api.get<any>(`api/users/${uuid}`);
  return result.data;
}

async function updateUserByUUID(uuid, user): Promise<any> {
  const result = await api.put<any>(`api/users/${uuid}`, user);
  return result.data;
}

async function completeRegistration(uuid, data): Promise<any> {
  const result = await api.post<any>(
    `api/users/${uuid}/complete_registration`,
    data
  );
  return result.data;
}

async function studentActiveSubscriptions(): Promise<
  StudentSubscriptionResponse
> {
  const result = await api.get<StudentSubscriptionResponse>(
    `${STUDENT_BASE_URL}/my_active_subscriptions`
  );
  return result.data;
}

async function cancelStudentSubscription(
  id
): Promise<StudentSubscriptionCancelResponse> {
  const result = await api.post<StudentSubscriptionCancelResponse>(
    `${STUDENT_BASE_URL}/subscription_cancel?id=${id}`
  );
  return result.data;
}

async function updateStudentSubscriptionPayment(id, tk, type): Promise<any> {
  const result = await api.post<any>(
    `${STUDENT_BASE_URL}/subscription_payment_update?id=${id}&tk=${tk}&type=${type}`
  );
  return result.data;
}

async function signWaiver(studio_id: number, waiver_id: number): Promise<void> {
  await api.post(`${STUDENT_BASE_URL}/sign_waiver`, { studio_id, waiver_id });
}

async function studentIdAttendances(
  studentId: number,
  q: string | null,
  dateFrom: Date | null,
  dateTo: Date | null,
  page: 1
): Promise<StudentPunchesResponse[]> {
  const config = {
    params: {
      page,
      q,
      date_from: dateFrom?.toISOString(),
      date_to: dateTo?.toISOString(),
      ordering: '-created',
    },
  };
  const result = await api.get<StudentPunchesResponse[]>(
    `${STUDENTS_BASE_URL}/${studentId}/attendances`,
    config
  );
  return result.data;
}

async function studentIdAttendancesExportToCSV(
  studentId: number,
  q: string | null,
  dateFrom: Date | null,
  dateTo: Date | null
): Promise<BlobPart> {
  const config = {
    params: {
      q,
      date_from: dateFrom?.toISOString(),
      date_to: dateTo?.toISOString(),
      ordering: '-created',
    },
  };
  const result = await api.get<BlobPart>(
    `${STUDENTS_BASE_URL}/${studentId}/attendances/export_to_csv`,
    config
  );
  return result.data;
}

async function studentPunches(): Promise<StudentPunchesResponse[]> {
  const result = await api.get<StudentPunchesResponse[]>(
    `${STUDENT_BASE_URL}/punches`
  );
  return result.data;
}

async function studentIdPunches(student_id): Promise<StudentPunchesResponse[]> {
  const result = await api.get<StudentPunchesResponse[]>(
    `${STUDENTS_BASE_URL}/${student_id}/punches`
  );
  return result.data;
}

async function studentIdAddFreePunch(
  student_id,
  expirationDays,
  notificationDays,
  numberOfRedemptions,
  productId
): Promise<StudentPunchesResponse> {
  const payload = {
    punchcard_expiration_days: expirationDays,
    notification_days: notificationDays,
    number_of_punches: numberOfRedemptions,
    product_id: productId,
  };
  const result = await api.post<StudentPunchesResponse>(
    `${STUDENTS_BASE_URL}/${student_id}/punches/add_free`,
    payload
  );
  return result.data;
}

async function studentIdRemovePunch(
  student_id,
  product_id,
  expiration
): Promise<StudentPunchesResponse> {
  const result = await api.post<StudentPunchesResponse>(
    `${STUDENTS_BASE_URL}/${student_id}/punches/remove`,
    { product_id, expiration }
  );
  return result.data;
}

async function studentIdRefundPunch(
  student_id,
  punch_id
): Promise<StudentPunchesResponse> {
  const result = await api.post<StudentPunchesResponse>(
    `${STUDENTS_BASE_URL}/${student_id}/punches/${punch_id}/refund`
  );
  return result.data;
}

async function studentPurchases(): Promise<StudentPurchasesResponse[]> {
  const result = await api.get<StudentPurchasesResponse[]>(
    `${STUDENT_BASE_URL}/my_purchases`
  );
  return result.data;
}

async function downloadStudentPurchaseInvoice(id: number): Promise<BlobPart> {
  const result = await api.get<BlobPart>(
    `${STUDENT_BASE_URL}/download_invoice`,
    {
      params: {
        id: id,
      },
      responseType: 'blob',
    }
  );
  return result.data;
}

async function downloadStudentSubscriptionInvoice(
  id: number
): Promise<BlobPart> {
  const result = await api.get<BlobPart>(
    `${STUDENT_BASE_URL}/download_active_invoice`,
    {
      params: {
        id: id,
      },
      responseType: 'blob',
    }
  );
  return result.data;
}
async function downloadTeacherPurchaseInvoice(id: number): Promise<BlobPart> {
  const result = await api.get<BlobPart>(
    `${TEACHERS_BASE_URL}/download_invoice`,
    {
      params: {
        id: id,
      },
      responseType: 'blob',
    }
  );
  return result.data;
}
async function htmlToPdf(html: string, title: string): Promise<BlobPart> {
  const result = await api.post<BlobPart>(
    `api/teachers/html_to_pdf`,
    {
      html,
      title,
    },
    {
      responseType: 'blob',
    }
  );
  return result.data;
}

export default {
  login,
  resetPassword,
  resetConfirm,
  changePassword,
  updateStudent,
  togglePrivatePodcastNotificationsEmail,
  promoteStudentToTeacher,
  promoteStudentToTeacherWithCode,
  magicCodeLogin,
  googleLogin,
  googleCustomLogin,
  facebookLogin,
  facebookCustomLogin,
  appleLogin,
  loadUserInfo,
  meStudent,
  meTeacher,
  getStudioUsersAndInvitations,
  removeTeamMember,
  inviteAdmin,
  removeInvitation,
  getInvitation,
  acceptInvitation,
  meTeacherSetupIntent,
  teacherSubscribe,
  getPlans,
  getCoachingProgramme,
  verifyCoupon,
  verifyProgrammeCoupon,
  verifyEmail,
  verifyNewEmail,
  captchaVerify,
  fetchLink,
  getInstagramRequestAuthURL,
  connectInstagramAccount,
  registerTeacher,
  registerExternalAffiliate,
  registerBio,
  codeRegister,
  register,
  getSetupIntent,
  updateTeacher,
  updateTeacherSignupInfo,
  populateTeacherStudio,
  getTeacherAnnouncement,
  updateTeacherAnnouncement,
  toggleTeacherWaiver,
  toggleTeacherInstagramFeed,
  disconnectTeacherInstagram,
  getUserByUUID,
  updateUserByUUID,
  completeRegistration,
  studentActiveSubscriptions,
  signWaiver,
  studentPunches,
  studentIdPunches,
  studentIdAddFreePunch,
  studentIdRemovePunch,
  studentIdRefundPunch,
  toggleStudentEventNotifications,
  toggleStudentEmailNotifications,
  toggleStudentSMSNotifications,
  toggleStudentEmail30mins,
  toggleStudentEmail1hr,
  cancelStudentSubscription,
  updateStudentSubscriptionPayment,
  studentPurchases,
  downloadStudentPurchaseInvoice,
  downloadStudentSubscriptionInvoice,
  downloadTeacherPurchaseInvoice,
  studentIdAttendances,
  studentIdAttendancesExportToCSV,
  getStudioByCustomDomain,
  getAffiliateInfo,
  updateAffiliateInfo,
  toggleCommunityDailyDigestEmail,
  toggleCommunityChatNotificationsEmail,
  toggleCommunityRealtimeMentionsEmail,
  htmlToPdf,
};
