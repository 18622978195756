import * as cheerio from 'cheerio';

export function injectScript(id, scriptString) {
  try {
    if (document.getElementById(id)) {
      console.log(`Script with id ${id} already exists`);
      return;
    }
    const scriptElement = document
      .createRange()
      .createContextualFragment(scriptString);

    const scriptContainer = document.createElement('div');
    scriptContainer.setAttribute('id', id);
    scriptContainer.appendChild(scriptElement);

    document.body.appendChild(scriptContainer);
  } catch (e) {
    console.error('Failed to insert script ', scriptString);
    console.error('Error: ', e);
  }
}

export function parseHeadContent(htmlString) {
  const $ = cheerio.load(htmlString);
  const headObject = {
    meta: [],
    link: [],
    style: [],
    script: [],
    noscript: []
  };

  $('meta').each((i, el) => {
    headObject.meta.push({
      name: $(el).attr('name'),
      content: $(el).attr('content')
    });
  });

  $('link').each((i, el) => {
    headObject.link.push({
      rel: $(el).attr('rel'),
      href: $(el).attr('href')
    });
  });

  $('style').each((i, el) => {
    headObject.style.push({
      children: $(el).text(),
      type: $(el).attr('type') || 'text/css'
    });
  });

  $('script').each((i, el) => {
    headObject.script.push({
      innerHTML: $(el).html(),
      src: $(el).attr('src'),
      type: $(el).attr('type') || 'text/javascript',
      async: $(el).attr('async') !== undefined,
      defer: $(el).attr('defer') !== undefined
    });
  });

  $('noscript').each((i, el) => {
    headObject.noscript.push({
      innerHTML: $(el).html()
    });
  });

  return headObject;
}
