import _ from 'lodash-es';
import { defineStore } from 'pinia';
import api from '~/api/events';
import authApi from '~/api/auth';
import { pick } from 'lodash-es';
import { formattedEvent, getAddToGoogleCalendarLink } from '@/utils/calendar';
import moment from 'moment';
import { saveAs } from '@/utils/save-file';
import { SET_TEACHER_CALENDAR_SELECTED_DATE } from '../mutation-types';
import { useUserStore } from './user';

interface State {
  event: EventResponse;
  events: EventResponse[] | any[];
  eventsLoading: boolean;
  selectedEventType: string | null;
  currentViewType: string | null;
  teacherCalendarSelectedDate: Date | null;
}

export const useEventStore = defineStore('event', {
  state: ():State => ({
    event: {} as EventResponse,
    events: [],
    eventsLoading: false,
    selectedEventType: null,
    currentViewType: 'dayGridMonth',
    teacherCalendarSelectedDate: null,
  }),
  getters: {
    hasEventEnded: state => {
      const { event_end_datetime } = state.event;
      if (!event_end_datetime) return false;
      return moment().isAfter(event_end_datetime);
    },
    eventId: state => state.event?.id,
    eventDetails: (state) => {
      if (!state.event.id) return null;
      const start = state.event.event_start_datetime;
      const end = state.event.event_end_datetime;
      const isLoggedIn = !!useUserStore().accessToken;
      return formattedEvent(state.event, start, end, isLoggedIn);
    },
    eventInstructors: state => state.event?.instructors,
    eventSubstituteInstructors: state => state.event?.substitute_instructors,
    registerWithAttended: state => {
      const { event_end_datetime, registrations } = state.event;
      if (!event_end_datetime) return [];
      const hasEnded = moment().isAfter(event_end_datetime);
      const registrationStatus = hasEnded ? 'no-show' : 'registered';
      const all = [];
      registrations?.forEach(item => {
        let status = registrationStatus;
        if (item.reason === 'recording') {
          status = item.reason;
        }
        if (item.attended) {
          status = 'attended';
        }
        // @ts-ignore
        all.push({ status, item });
      });
      return all;
    },
    registerWithAttendedNumber: state => {
      if (!state.event?.registrations) return 0;
      return state.event.registrations.length;
    },
    registerWithAttendedDesc: state => {
      if (!state.event?.registrations) return '';
      if (state.event.registration_limit > 0) {
        return `${state.event.registrations.length}/${state.event.registration_limit}`;
      } else {
        return `${state.event.registrations.length}`;
      }
    },
    zoomJoinUrl: state => (state.event ? state.event.zoom_join_url : null),
    streamingSessionId: state =>
      state.event ? state.event.streaming_session_id : null,
    streamingToken: state => (state.event ? state.event.streaming_token : null),
    streamChatToken: state =>
      state.event ? state.event.stream_chat_token : null,
    chatChannelId: state => (state.event ? state.event.chat_channel_id : null),
    eventName: state => (state.event ? state.event.event_name : null),
    getFilteredEvents: state =>
      state.events?.filter(
        event =>
          !state.selectedEventType ||
          event.event_type == state.selectedEventType
      ),
    eventProducts: state => {
      return _.uniqBy(
        [...(state.event.all_products || []), ...(state.event.products || [])],
        product => product.id
      );
    },
    addToGoogleCalendarLink: state => {
      if (!state.event.id) return null;
      const start = state.event.event_start_datetime;
      const end = state.event.event_end_datetime;
      return getAddToGoogleCalendarLink(start, end, state.event);
    },
  },
  actions: {
    setCurrentEvent(event: EventResponse): void {
      this.event = event;
    },
    setTeacherCalendarSelectedDate(date: Date) {
      this.teacherCalendarSelectedDate = date;
    },
    // STUDENT
    async getStudioEventDetails(
      { studioURL, eventId, accessCode }
    ): Promise<void> {
      // this retrieves event data for student
      const event = await api.getStudioEventDetails(
        studioURL,
        eventId,
        accessCode
      );
      this.event = event;
    },
    async getStudioEventBasic(
      { studioURL, eventId }
    ): Promise<any> {
      const event = await api.getStudioEventBasic(studioURL, eventId);
      return event;
    },
    async registerForEvent(
      { studioURL, eventId }
    ): Promise<EventResponse> {
      const result = await api.registerForEvent(studioURL, eventId);
      this.event = result;
      return result;
    },
    async registerForEventWaitlist(
      { studioURL, eventId }
    ): Promise<EventResponse> {
      const result = await api.registerForEventWaitlist(studioURL, eventId);
      this.event = result;
      return result;
    },
    async registerForRecording(
      { studioURL, eventId }
    ): Promise<EventResponse> {
      const result = await api.registerForRecording(studioURL, eventId);
      this.event = result;
      return result;
    },
    async registerForFreeEvent(
      { studioURL, eventId, email, first_name, last_name }
    ): Promise<EventResponse> {
      const result = await api.registerForFreeEvent(
        studioURL,
        eventId,
        email,
        first_name,
        last_name
      );
      this.event = result;
      return result;
    },
    async unregisterFromEvent(
      { studioURL, eventId }
    ): Promise<EventResponse> {
      const result = await api.unregisterFromEvent(studioURL, eventId);
      this.event = result;
      return result;
    },
    async unregisterFromEventWaitlist(
      { studioURL, eventId }
    ): Promise<EventResponse> {
      const result = await api.unregisterFromEventWaitlist(studioURL, eventId);
      this.event = result;
      return result;
    },
    async markEventAttended(
      { studioURL, eventId, accessCode }
    ): Promise<void> {
      const result = await api.markEventAttended(
        studioURL,
        eventId,
        accessCode
      );
      return result;
    },
    async teacherMarkEventAttended(
      { eventId, eventRegistrationId }
    ): Promise<void> {
      const result = await api.teacherMarkEventAttended(
        eventId,
        eventRegistrationId
      );

      const registration = this.event.registrations.find(
        r => r.id === eventRegistrationId
      );
      if (registration) {
        registration.attended = true;
      }

      return result;
    },
    async teacherRemoveEventAttendance(
      { eventId, eventRegistrationId }
    ): Promise<void> {
      const result = await api.teacherRemoveEventAttendance(
        eventId,
        eventRegistrationId
      );
      const registration = this.event.registrations.find(
        r => r.id === eventRegistrationId
      );
      if (registration) {
        registration.attended = false;
      }
      return result;
    },
    async customerMarkEventAttended(
      { eventId, eventRegistrationId }
    ): Promise<void> {
      const result = await api.teacherMarkEventAttended(
        eventId,
        eventRegistrationId
      );
      return result;
    },
    async coustomerRemoveEventAttendance(
      { eventId, eventRegistrationId }
    ): Promise<void> {
      const result = await api.teacherRemoveEventAttendance(
        eventId,
        eventRegistrationId
      );
      return result;
    },
    async refundCustomerPunch({ customerId, punchId }): Promise<any> {
      const result = await authApi.studentIdRefundPunch(customerId, punchId);
      return result;
    },
    // TEACHER
    async getEventDetails(eventId): Promise<EventResponse> {
      this.event = null;
      // this retrieves event data for student
      const event = await api.getEventDetails(eventId);
      this.event = event;
      return event;
    },
    async getEvents(params): Promise<EventResponse[]> {
      commit(types.SET_EVENTS_LOADING, true);
      commit(types.SET_EVENTS, []);
      const { fromDate, toDate } = pick(params, ['fromDate', 'toDate']);
      const result = await api.getCalendarEvents(fromDate, toDate);
      this.events = result;
      this.eventsLoading = false;
      return result;
    },
    async createEvent(data: EventResponse): Promise<EventResponse> {
      const result = await api.createEvent(data);
      return result;
    },
    async updateEvent(data: EventResponse): Promise<EventResponse> {
      const result = await api.updateEvent(data);
      return result;
    },
    async deleteEvent(): Promise<void> {
      const result = await api.deleteEvent(this.event.id);
      this.event = null;
      return result;
    },
    async cancelEvent(): Promise<EventResponse> {
      this.event.is_cancelled = true;
      const event = await api.updateEvent(this.event);
      this.event = event;
      return event;
    },
    async startStreamingSession(eventId): Promise<EventResponse> {
      const event = await api.startStreamingSession(eventId);
      this.event = event;
      return event;
    },
    async stopStreamingSession(eventId): Promise<EventResponse> {
      const event = await api.stopStreamingSession(eventId);
      this.event = event;
      return event;
    },
    async startSessionRecording(eventId): Promise<EventResponse> {
      const response = await api.startSessionRecording(eventId);
      return response;
    },
    async stopSessionRecording(eventId): Promise<EventResponse> {
      const response = await api.stopSessionRecording(eventId);
      return response;
    },
    async createZoomMeeting(eventId): Promise<EventResponse> {
      const event = await api.createZoomMeeting(eventId);
      this.event = event;
      return event;
    },
    async scheduleZoomMeeting(eventId): Promise<EventResponse> {
      const event = await api.scheduleZoomMeeting(eventId);
      this.event = event;
      return event;
    },
    async refetchZoomMeeting(eventId): Promise<EventResponse> {
      const event = await api.getZoomMeeting(eventId);
      this.event = event;
      return event;
    },
    async endZoomMeeting(eventId): Promise<EventResponse> {
      const event = await api.endZoomMeeting(eventId);
      this.event = event;
      return event;
    },
    async markZoomMeetingStarted(eventId): Promise<EventResponse> {
      const event = await api.markZoomMeetingStarted(eventId);
      this.event = event;
      return event;
    },
    async deleteAllRegistrations(eventId: number): Promise<void> {
      const registrations = await api.deleteAllRegistrations(eventId);
      this.event.registrations = [];
      return result;
    },
    async emailAllRegistrants(
      { eventId, subject, body }
    ): Promise<void> {
      const result = await api.emailAllRegistrants(eventId, subject, body);
      return result;
    },
    async emailOneRegistrant(
      { eventId, registrationId, subject, body }
    ): Promise<void> {
      const result = await api.emailOneRegistrant(
        eventId,
        registrationId,
        subject,
        body
      );
      return result;
    },
    async emailWaitlistRegistrants(
      { eventId, subject, body }
    ): Promise<void> {
      const result = await api.emailWaitlistRegistrants(eventId, subject, body);
      return result;
    },
    async emailAllAttendees(
      { eventId, subject, body }
    ): Promise<void> {
      const result = await api.emailAllAttendees(eventId, subject, body);
      return result;
    },
    async emailOneUser({ userId, subject, body }): Promise<void> {
      const result = await api.emailOneUser(userId, subject, body);
      return result;
    },
    async addEventRegistration(
      { eventId, studentId, allowFree, allowOverflow }
    ): Promise<void> {
      const result = await api.addEventRegistration(
        eventId,
        studentId,
        allowFree,
        allowOverflow
      );
      return result;
    },
    async addEventWaitlistRegistration(
      { eventId, studentId, allowFree }
    ): Promise<void> {
      const result = await api.addEventWaitlistRegistration(
        eventId,
        studentId,
        allowFree
      );
      return result;
    },
    async removeEventRegistration(
      { eventId, registrationId }
    ): Promise<void> {
      const result = await api.removeEventRegistration(eventId, registrationId);
      return result;
    },
    async removeEventWaitlistRegistration(
      { eventId, registrationId }
    ): Promise<void> {
      const result = await api.removeEventWaitlistRegistration(
        eventId,
        registrationId
      );
      return result;
    },
    setCurrentViewType(currentViewType) {
      this.currentViewType = currentViewType;
    },
    async exportEventRegistrationEmails({ eventId }) {
      const data = await api.exportEventRegistrationEmails(eventId);
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'event-emails.csv');
    },
    async exportEventWaitlistRegistrationEmails({ eventId }) {
      const data = await api.exportEventWaitlistRegistrationEmails(eventId);
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'waitlist-emails.csv');
    },
    async getEventRecordings(eventId): Promise<EventRecordingResponse[]> {
      return await api.getEventRecordings(eventId);
    },
    async addEventRecording({ eventId, fileId }) {
      return await api.addEventRecording(eventId, fileId);
    },
    async deleteEventRecording({ eventId, recordingId }) {
      return await api.deleteEventRecording(eventId, recordingId);
    },
    async registerFromWaitlistToEvent(
      { eventId, waitlistRegistrationId, allowFree, allowOverflow }
    ) {
      return await api.registerFromWaitlistToEvent(
        eventId,
        waitlistRegistrationId,
        allowFree,
        allowOverflow
      );
    },
  },
  // mutations: {
  //   [types.SET_EVENT](state: State, event: EventResponse) {
  //     state.event = event;
  //   },
  //   [types.SET_EVENT_CANCELLED](state: State, cancelled: boolean) {
  //     state.event.is_cancelled = cancelled;
  //   },
  //   [types.SET_EVENT_REGISTRATIONS](
  //     state: State,
  //     registrations: RegistrationResponse[]
  //   ) {
  //     state.event.registrations = registrations;
  //   },
  //   [types.SET_EVENTS](state: State, events: EventResponse[]) {
  //     state.events = events;
  //   },
  //   [types.SET_SELECTED_EVENT_TYPE](state: State, selectedEventType: string) {
  //     state.selectedEventType = selectedEventType;
  //   },
  //   [types.SET_CURRENT_VIEW_TYPE](state: State, currentViewType: string) {
  //     state.currentViewType = currentViewType;
  //   },
  //   [types.SET_EVENTS_LOADING](state: State, loading: boolean) {
  //     state.eventsLoading = loading;
  //   },
  //   [types.SET_TEACHER_CALENDAR_SELECTED_DATE](state: State, date: Date) {
  //     state.teacherCalendarSelectedDate = date;
  //   },
  //   [types.SET_REGISTRATION_ATTENDED](
  //     state: State,
  //     eventRegistrationId: number
  //   ) {
  //     const registration = state.event.registrations.find(
  //       r => r.id === eventRegistrationId
  //     );
  //     if (registration) {
  //       registration.attended = true;
  //     }
  //   },
  //   [types.REMOVE_REGISTRATION_ATTENDED](
  //     state: State,
  //     eventRegistrationId: number
  //   ) {
  //     const registration = state.event.registrations.find(
  //       r => r.id === eventRegistrationId
  //     );
  //     if (registration) {
  //       registration.attended = false;
  //     }
  //   },
  // },
});
