export function getCurrentUrl(url?: URL) {
  if(url) {
    const { host, protocol, pathname } = url;
    const uri = encodeURI(`${protocol}//${host}${pathname}`);
    return uri;
  }

  if (!window) {
    return;
  }
  const host = window.location.hostname;
  const protocol = window.location.protocol;
  const path = window.location.pathname;
  const uri = encodeURI(`${protocol}//${host}${path}`);
  return uri;
}

export function replaceURLWithCurrentDomain(srcURL: URL) {
  const url = new URL(srcURL.origin);
  if (
    url.hostname.indexOf('beta.namastream') !== -1 ||
    url.hostname.indexOf('beta.heymarv') !== -1 ||
    url.hostname.indexOf('beta-studio.heymarv') !== -1
  ) {
    url.hostname = srcURL.hostname;
  } else if (url.hostname.indexOf('namastream') !== -1) {
    url.hostname = 'app.namastream.com';
  } else if (url.hostname.indexOf('heymarvelous') !== -1) {
    url.hostname = 'app.heymarvelous.com';
  } else {
    url.hostname = srcURL.hostname;
  }
  return url.href;
}

export function replaceRedirectURLWithCurrentDomain(srcURL) {
  const currentUrl = new URL(window.location.href);
  const url = new URL(srcURL);
  if (currentUrl.hostname.indexOf('namastream') !== -1) {
    url.hostname = currentUrl.hostname;
  } else if (currentUrl.hostname.indexOf('heymarvelous') !== -1) {
    url.hostname = currentUrl.hostname;
  } else {
    url.hostname = window.location.hostname;
  }
  return url.href;
}

export function isOfficialDomain(host) {
  return (
    host.indexOf('namastream') !== -1 ||
    host.indexOf('heymarv') !== -1
  );
}

export function withHttp(url: string) {
  return url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schemma, nonSchemmaUrl) =>
    schemma ? match : `http://${nonSchemmaUrl}`
  )
};