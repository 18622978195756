import { defineStore } from 'pinia'
import api from '~/api/products';
// import formatProduct from '@/utils/format-products';
import ProductState from '@/utils/typings/enums/ProductState';
// import { cloneDeep, flow, pick, sortBy } from 'lodash-es';
// import { LESSON_START_TYPE_AFTER_PREVIOUS_LESSON } from '@/utils/products';
import { useUserStore } from './user';
import type {LessonResponse} from "~/globals";

interface ProductsFilter {
  q: string | null;
  productState: ProductState | null;
}

interface ProductForm extends CreateProductRequest, UpdateProductRequest {
  cover_file: PhotoResponse;
  thumbnail_file: PhotoResponse;
  content: number[];
  sales_page: ProductSalesPageResponse;
  custom1: string;
  custom2: string;
  custom3: string;
  wish_price_enabled: boolean;
  min_wish_price: number;
}

interface State {
  product: ProductDetailResponse | null;
  productLessons: LessonResponse[] | null;
  productMedia: ProductMediaResponse[] | null;
  productMediaDetail: MediaDetailResponse | null;
  options: FilterCategoryResponse[] | null;
  studioInstructors: InstructorResponse[] | null;
  productMediaNextPage?: string;
  teacherProducts: ProductResponse[] | null;
  teacherProduct: ProductDetailResponse | null;
  teacherCoupons: PaginatedResponse<TeacherCouponsResponse> | null;
  teacherTaxRates: TeacherTaxRateResponse[] | null;
  productTags: TeacherProductTagResponse[] | null;
  teacherCoupon: TeacherCouponsResponse | null;
  teacherCouponsStats: CouponsStatsResponse | null;
  teacherProductsWithPrivateSessions: ProductResponse[] | null;
  teacherProductsFilter: ProductsFilter;
  productForm: ProductForm | null;
  playlists: PlaylistResponse[] | null;
  playlist: PlaylistResponse | null;
}

export const useProductStore = defineStore('product', {
  state: ():State => {
    return {
      product: null,
      productLessons: null,
      productMedia: null,
      options: null,
      studioInstructors: null,
      productMediaDetail: null,
      teacherProducts: null,
      teacherProduct: null,
      teacherProductsWithPrivateSessions: [],
      teacherTaxRates: null,
      productTags: null,
      teacherCoupons: null,
      teacherCoupon: null,
      teacherCouponsStats: null,
      teacherProductsFilter: {
        q: null,
        productState: null,
      },
      productForm: null,
      playlists: [],
      playlist: null,
    };
  },
  getters: {
    // product: state => state.product,
    productId: state => (state.product ? state.product.id : null),
    productType: state => (state.product ? state.product.product_type : null),
    productTitle: state => (state.product ? state.product.product_name : null),
    productDesciption: state =>
      state.product ? state.product.product_description : null,
    productDesciptionNew: state =>
      state.product ? state.product.product_description_new : null,
    productImage: state =>
      state.product && state.product.cover_file
        ? state.product.cover_file.storage_url
        : null,
    productInstructors: state =>
      state.product ? state.product.instructors : null,
    // productMedia: state => state.productMedia,
    productCommentsEnabled: state => state.product?.comments_enabled,
    // previousProductMediaId: state => currentProductMediaId => {
    //   if (!currentProductMediaId || !state.productMedia) return;
    //   const indexOfCurrentProductMedia = state.productMedia.findIndex(
    //     media => media.id == currentProductMediaId
    //   );
    //   const previousProductMediaIndex =
    //     (indexOfCurrentProductMedia + state.productMedia.length - 1) %
    //     state.productMedia.length;
    //   return state.productMedia[previousProductMediaIndex]?.id;
    // },
    // nextProductMediaId: state => currentProductMediaId => {
    //   if (!currentProductMediaId || !state.productMedia) return;
    //   const indexOfCurrentProductMedia = state.productMedia.findIndex(
    //     media => media.id == currentProductMediaId
    //   );
    //   const nextProductMediaIndex =
    //     (indexOfCurrentProductMedia + 1) % state.productMedia.length;
    //   return state.productMedia[nextProductMediaIndex]?.id;
    // },
    // productMediaDetail: state =>
    //   state.productMediaDetail ? state.productMediaDetail : null,
    // productMediaFilterOptions: state => state.options,
    // studioInstructors: state => state.studioInstructors,
    // hasProductMediaNextPage: state => state.productMediaNextPage != undefined,
    // productEvents: state => (state.product ? state.product.events : null),
    // teacherProducts: state => {
    //   if (!state.teacherProducts) return state.teacherProducts;
    //   const q = state.teacherProductsFilter.q?.toLowerCase();
    //   let results = state.teacherProducts.filter(p =>
    //     p.product_name.toLowerCase().includes(q ?? '')
    //   );
    //   const productState = state.teacherProductsFilter.productState;
    //   if (productState)
    //     results = results.filter(p => p.productState === productState);
    //   return results;
    // },
    // teacherProductsExcludeTypePunchCard: (state, getters) => {
    //   if (!state.teacherProducts) return [];
    //   return getters['allActiveTeacherProducts'].filter(
    //     p => p.product_type !== 'punch_card'
    //   );
    // },
    // teacherProduct: state => state.teacherProduct,
    // teacherTaxRates: state => state.teacherTaxRates,
    // productTags: state => state.productTags,
    // teacherCouponsStats: state => state.teacherCouponsStats,
    // teacherCoupon: state => state.teacherCoupon,
    // productForm: state => state.productForm,
    // allTeacherProducts: state => state.teacherProducts,
    // allActiveTeacherProducts: state =>
    //   (state.teacherProducts || []).filter(p => !p.archived),
    // allUpsellableTeacherProducts: (state, getters) =>
    //   getters.allActiveTeacherProducts.filter(
    //     p => p.product_type !== 'private_space' && p.pricing_type === 'fixed'
    //   ),
    // teacherProductsWithPrivateSessions: state =>
    //   (state.teacherProductsWithPrivateSessions || []).filter(p => !p.archived),
    // bundlableProducts: state =>
    //   (state.teacherProducts || []).filter(
    //     p =>
    //       [
    //         'media_library',
    //         'course',
    //         'event_only',
    //         'punch_card',
    //         'private_coaching',
    //       ].indexOf(p.product_type) !== -1 && p.published
    //   ),
    // productsCanAddMediaTo: state =>
    //   (state.teacherProducts || []).filter(
    //     p =>
    //       ['media_library', 'private_coaching', 'private_space'].indexOf(
    //         p.product_type
    //       ) !== -1 && p.published
    //   ),
    canViewProductContent: state => state.product?.can_view_content.can_view,
    cantViewProductContentReason: state =>
      state.product?.can_view_content.reason,
    // productSalesPage: state => state.teacherProduct?.sales_page,
    // salesPageItems: state => {
    //   const salesPageItemsKeys = [
    //     'introduction',
    //     'solution',
    //     'sign_up_banner',
    //     'testimonial',
    //     'instructors',
    //     'results',
    //     'bonus',
    //     'faq',
    //     'included_content',
    //     'introduction',
    //     'included_events',
    //     'included_lessons',
    //   ];

    //   return flow(
    //     sales_page => pick(sales_page, salesPageItemsKeys),
    //     sales_page =>
    //       Object.keys(sales_page).map(key => ({ key, data: sales_page[key] })),
    //     items => items.filter(item => item.data?.enabled),
    //     items => items.map(item => cloneDeep(item)),
    //     items => sortBy(items, 'data.position')
    //   )(state.product?.sales_page);
    // },
    // playlists: state => state.playlists,
    // playlist: state => state.playlist,
  },
  actions: {
    // student
    async getProductDetail({ studioURL, productId }: { studioURL: string, productId: number }) {
      if(parseInt(productId) !== this.product?.id) {
        this.product = null;
      }
      const product = await api.getProductDetail(studioURL, productId);
      this.product = product;
      return product;
    },
    async getProductBasic({ studioURL, productId }: { studioURL: string, productId: number }) {
      return await api.getProductBasic(studioURL, productId);
    },
    async fetchProductDetail({ studioURL, productId }) {
      const product = await api.getProductDetail(studioURL, productId);
      return product;
    },
    async getProductForPurchase({ studioURL, productId }) {
      return await api.getProductForPurchase(studioURL, productId);
    },
    async refreshProductDetail({ studioURL, productId }) {
      const product = await api.getProductDetail(studioURL, productId);
      this.product = product;
    },
    async purchaseFreeProduct({ studioURL, productId, reCaptchaToken }) {
      const product = await api.purchaseFreeProduct(
        studioURL,
        productId,
        reCaptchaToken
      );
      return product;
    },
    async purchaseProduct(payload) {
      const {
        coupon,
        studioURL,
        productId,
        recurringOption,
        daypass,
        isPaymentPlan,
        paymentPlanId,
        customAmount,
        useSavedCard,
        reCaptchaToken,
        rewardfulReferralId,
      } = payload;
      const product = await api.purchaseProduct(
        coupon,
        studioURL,
        productId,
        recurringOption,
        daypass,
        isPaymentPlan,
        reCaptchaToken,
        paymentPlanId,
        customAmount,
        useSavedCard,
        rewardfulReferralId
      );
      if (['punch_card', 'bundle'].indexOf(product.product_type) !== -1) {
        useUserStore().studentPunches(null);
      }
      return product;
    },
    async purchaseUpsellProduct(payload) {
      const {
        studioURL,
        productId,
        upsellProductType,
        rewardfulReferralId,
      } = payload;

      const product = await api.purchaseUpsellProduct(studioURL, productId);
      if (['punch_card', 'bundle'].indexOf(upsellProductType) !== -1) {
        useUserStore().studentPunches(null);
      }
      return product;
    },
    async purchaseProductAsGift(payload) {
      const {
        defaultPaymentMethod,
        stripeCustomerId,
        coupon,
        studioURL,
        productId,
        recurringOption,
        daypass,
        isPaymentPlan,
        reCaptchaToken,
        paymentPlanId,
        customAmount,
        giftPayload,
        rewardfulReferralId,
      } = payload;
      const product = await api.purchaseProductAsGift(
        defaultPaymentMethod,
        stripeCustomerId,
        coupon,
        studioURL,
        productId,
        recurringOption,
        daypass,
        isPaymentPlan,
        reCaptchaToken,
        paymentPlanId,
        customAmount,
        giftPayload,
        rewardfulReferralId
      );
      return product;
    },
    async validateStudentCoupon({ studioURL, productId, couponCode }) {
      const product = await api.validateStudentCoupon(
        studioURL,
        productId,
        couponCode
      );
      return product;
    },
    async verifyIntent(
      { studioURL, productId, paymentIntentId, setupIntentId }
    ) {
      await api.verifyIntent(
        studioURL,
        productId,
        paymentIntentId,
        setupIntentId
      );
    },
    async createStripeSetupIntent({ studioURL, credentials }) {
      const setupIntent = await api.createStripeSetupIntent(
        studioURL,
        credentials
      );
      return setupIntent;
    },
    async getStripeSetupIntent({ studioURL, params }) {
      const setupIntent = await api.getStripeSetupIntent(studioURL, params);
      return setupIntent;
    },
    async createGiftStripeSetupIntent({ studioURL, customerEmail }) {
      const setupIntent = await api.createGiftStripeSetupIntent(
        studioURL,
        customerEmail
      );
      return setupIntent;
    },
    async getProductMediaPaginated(payload) {
      const {
        studioURL,
        productId,
        options,
        instructor,
        mediaType,
        q,
        page,
      } = payload;
      const media = await api.getProductMediaPaginated(
        studioURL,
        productId,
        options,
        instructor,
        mediaType,
        q,
        page
      );
      this.setProductMedia(media);
      return media;
    },
    setProductMedia(productMedia: PaginatedResponse<ProductMediaResponse>) {
      this.productMedia = productMedia.results;
      this.productMediaNextPage = productMedia.next;
    },
    async getProductLessons({ studioURL, productId }) {
      if (this.productLessons?.length) {
        if (this.productLessons[0].product == productId) {
            return this.productLessons
        }
      }
      this.productLessons = []
      this.productLessons = await api.getProductLessons(studioURL, productId);
      return this.productLessons
    },
    async getProductLesson({ studioURL, productId, lessionId }) {
      return await api.getProductLesson(studioURL, productId, lessionId);
    },
    // async getProductMedia({ commit }, payload) {
    //   const {
    //     studioURL,
    //     productId,
    //     options,
    //     instructor,
    //     mediaType,
    //     q,
    //   } = payload;
    //   const media = await api.getProductMedia(
    //     studioURL,
    //     productId,
    //     options,
    //     instructor,
    //     mediaType,
    //     q
    //   );
    //   commit(types.SET_PRODUCT_MEDIA, media);
    //   return media;
    // },
    // async loadMoreProductMedia({ commit, state }, payload) {
    //   if (!state.productMediaNextPage) throw 'Empty page';
    //   const {
    //     studioURL,
    //     productId,
    //     options,
    //     instructor,
    //     mediaType,
    //     q,
    //   } = payload;
    //   const media = await api.getProductMedia(
    //     studioURL,
    //     productId,
    //     options,
    //     instructor,
    //     mediaType,
    //     q,
    //     state.productMediaNextPage
    //   );
    //   commit(types.APPEND_PRODUCT_MEDIA, media);
    //   return media;
    // },
    // async getProductMediaDetail(_, { studioURL, productId, mediaId }) {
    //   return await api.getProductMediaDetail(studioURL, productId, mediaId);
    // },
    async getProductPlaylistMediaDetail(
      { studioURL, productId, playlistId, mediaId }
    ) {
      return await api.getProductPlaylistMediaDetail(
        studioURL,
        productId,
        playlistId,
        mediaId
      );
    },
    // async getWaitlistEmails(_, payload) {
    //   const { productId, page } = payload;
    //   return await api.getWaitlistEmails(productId, page);
    // },
    // async exportWaitlistEmails(_, productId) {
    //   const data = await api.exportWaitlistEmails(productId);
    //   const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    //   saveAs(blob, 'waitlist-email-export.csv');
    // },
    // async clearWaitlistEmails(_, productId) {
    //   return await api.clearWaitlistEmails(productId);
    // },
    async getStudentPlaylists({ studioURL, productId }) {
      const playlists = await api.getStudentPlaylists(studioURL, productId);
      this.playlists = playlists;
      return playlists;
    },
    async getStudentPlaylist(payload) {
      console.log('get student playlist');
      const { studioURL, productId, playlistId } = payload;
      const playlist = await api.getStudentPlaylist(
        studioURL,
        productId,
        playlistId
      );
      this.playlist = playlist;
      return playlist;
    },
    async getStudentProductsReviews(payload) {
      const { studioURL, productId, page } = payload;
      const reviews = await api.getStudentProductsReviews(
        studioURL,
        productId,
        page
      );
      return reviews;
    },
    async studentSubmitReview(payload) {
      const { studioURL, productId, review, rating } = payload;
      await api.studentSubmitReview(studioURL, productId, review, rating);
    },
    async trackProductView({ studioURL, productId }) {
      await api.trackProductView(studioURL, productId);
    },
    async checkLessonAsCompleted(payload) {
      const { studioURL, productId, lessonId } = payload;
      await api.checkLessonAsCompleted(studioURL, productId, lessonId);
  
      if (this.productLessons) {
        const lessons = this.productLessons;
        const lesson = lessons.find(l => l.id == lessonId);
        if (lesson) {
          lesson.completed = true;
          const nextLessonIndex = lessons.indexOf(lesson) + 1;
          if (nextLessonIndex < lessons.length) {
            const nextLesson = lessons[nextLessonIndex];
            if (
              nextLesson &&
              nextLesson.locked &&
              nextLesson.lesson_start_type ===
                LESSON_START_TYPE_AFTER_PREVIOUS_LESSON
            ) {
              nextLesson.locked = false;
            }
          }
        }
      }
    },
    async checkLessonAsIncompleted(payload) {
      const { studioURL, productId, lessonId } = payload;
      await api.checkLessonAsIncompleted(studioURL, productId, lessonId);
      if (this.productLessons) {
        const lessons = this.productLessons;
        const lesson = lessons.find(l => l.id == lessonId);
        if (lesson) {
          lesson.completed = false;
          const nextLessonIndex = lessons.indexOf(lesson) + 1;
          if (nextLessonIndex < lessons.length) {
            const nextLesson = lessons[nextLessonIndex];
            if (
              nextLesson &&
              !nextLesson.locked &&
              nextLesson.lesson_start_type ===
                LESSON_START_TYPE_AFTER_PREVIOUS_LESSON
            ) {
              nextLesson.locked = true;
            }
          }
        }
      }
    },
    updateLessonQuizStatus(payload) {
      const lesson = this.productLessons.find(
        lesson => lesson.id === payload.lesson_id
      );
      if (lesson) {
        lesson.quiz_status = payload.status;
      }
    },
    async likeMedia(payload): Promise<boolean> {
      const { studioURL, productId, mediaId } = payload;
      return await api.likeMedia(studioURL, productId, mediaId);
    },
    async getFilterCategories(payload: { studioURL: string }) {
      const { studioURL } = payload;
      const options = await api.getFilterCategories(studioURL);
      this.options = options.sort(o => o.position);
    },
    async getStudioInstructors(payload: { studioURL: string }) {
      const { studioURL } = payload;
      const instructors = await api.getStudioInstructors(studioURL);
      this.studioInstructors = instructors.sort(o => o.position);
    },
    async getStudioInstructor(payload) {
      const { studioURL, instructorId } = payload;
      const instructor = await api.getStudioInstructor(studioURL, instructorId);
      return instructor;
    },
    async getPendingContracts({ studioURL, productId }) {
      return await api.getPendingContracts(studioURL, productId);
    },
    async canAddPrivateSession(
      { studioURL, productId }
    ): Promise<CanAddPrivateSessionResponse> {
      return await api.canAddPrivateSession(studioURL, productId);
    },
    async getPrivateSchedulingTimeSlots(
      { studioURL, productId, startDate, endDate, timezone }
    ): Promise<any> {
      return await api.getPrivateSchedulingTimeSlots(
        studioURL,
        productId,
        startDate,
        endDate,
        timezone
      );
    },
    async bookPrivateSchedulingSlot(
      { studioURL, productId, slot, timezone }
    ): Promise<any> {
      return await api.bookPrivateSchedulingSlot(
        studioURL,
        productId,
        slot,
        timezone
      );
    },
  },
});
