interface StudioConfig {
  applicationName: string;
  teamEmail: string;
  supportEmail: string;
  signupCampaign: string | null;
  homepageURL: string;
  helpURL: string;
  termsOfUseURL: string;
  privacyPolicyURL: string;
  affiliateBookletURL: string;
  customWaiverHelpURL: string;
  paypalMembershipCancelHelpURL: string;
  'studio-footer-logo': string;
  'admin-logo': string;
  'admin-logo-height': string;
  'admin-icon-logo': string;
  'report-students-and-activity-img': string;
  'report-sales-img': string;
  'empty-customers': string;
  'empty-instructors': string;
  'settings-instructors-img': string;
  'studio-invite-img': string;
  'product-membership-img': string;
  'product-classcard-img': string;
  'page-404': string;
  'studio-expired-img': string;
  'login-img': string;
}

const namastreamConfig: StudioConfig = {
  applicationName: 'Namastream',
  teamEmail: 'team@namastream.com',
  supportEmail: 'support@namastream.com',
  signupCampaign: null,
  homepageURL: 'https://namastream.com/',
  helpURL: 'https://help.namastream.com/',
  termsOfUseURL: 'https://namastream.com/terms-of-use/',
  privacyPolicyURL: 'https://namastream.com/privacy-policy/',
  affiliateBookletURL:
    'https://heymarvelous.com/s/marvelous-Affiliate-Program-BOOKLET.pdf',
  customWaiverHelpURL:
    ' https://help.namastream.com/en/articles/4260858-custom-waivers',
  paypalMembershipCancelHelpURL:
    'https://help.namastream.com/en/articles/2519896-how-do-students-paying-with-paypal-cancel-their-membership',
  'studio-footer-logo': '/assets/images/namastream-primary-logo_charcoal.svg',
  'admin-logo': '/assets/images/namastream-primary-logo_charcoal.svg',
  'admin-logo-height': '17px',
  'admin-icon-logo': '/assets/images/namastream-icon-logo.svg',
  'report-students-and-activity-img': '/assets/images/settings/branding.svg',
  'report-sales-img': '/assets/images/settings/account_info.svg',
  'empty-customers': 'empty-customers',
  'empty-instructors': 'empty-media-library',
  'settings-instructors-img': '/assets/images/settings/instructors.svg',
  'studio-invite-img': '/assets/images/stretching.svg',
  'product-membership-img': '/assets/images/product/membership.svg',
  'product-classcard-img': '/assets/images/product/class-card.svg',
  'page-404': 'no-show',
  'studio-expired-img': '/assets/images/expired-studio.svg',
  'login-img': '/assets/images/3-yoga-sitting.svg',
};

const heymarvelousConfig: StudioConfig = {
  applicationName: 'Marvelous',
  teamEmail: 'team@heymarvelous.com',
  supportEmail: 'support@namastream.com',
  signupCampaign: 'heymarvelous',
  homepageURL: 'https://heymarvelous.com/',
  helpURL: 'https://help.heymarvelous.com/',
  termsOfUseURL: 'https://heymarvelous.com/terms',
  privacyPolicyURL: 'https://heymarvelous.com/privacy',
  affiliateBookletURL:
    'https://heymarvelous.com/s/marvelous-Affiliate-Program-BOOKLET.pdf',
  customWaiverHelpURL:
    'https://help.heymarvelous.com/en/articles/4260858-custom-waivers',
  paypalMembershipCancelHelpURL:
    'https://help.heymarvelous.com/en/articles/2519896-how-do-students-paying-with-paypal-cancel-their-membership',
  'studio-footer-logo': '/assets/images/marvelous-primary-logo_charcoal.png',
  'admin-logo': '/assets/images/hey-marvelous-logo.svg',
  'admin-logo-height': '30px',
  'admin-icon-logo': '/assets/images/hey-marvelous-rainbow.png',
  'report-students-and-activity-img': '/assets/images/woman-sitting.svg',
  'report-sales-img': '/assets/images/woman-meditating.svg',
  'empty-customers': 'empty-customers',
  'empty-instructors': 'jands-webinar-bw',
  'settings-instructors-img': '/assets/images/settings/jands-webinar.svg',
  'studio-invite-img': '/assets/images/hm-celebration.svg',
  'product-membership-img': '/assets/images/product/membership-hm.svg',
  'product-classcard-img': '/assets/images/product/class-card-hm.svg',
  'page-404': 'no-show-hm',
  'studio-expired-img': '/assets/images/expired-studio-hm.svg',
  'login-img': '/assets/images/login-hm.svg',
};

const allConfigs = {
  namastream: namastreamConfig,
  heymarvelous: heymarvelousConfig,
};

export function getConfig(url: URL): StudioConfig {
  const appConfig = process.env.VUE_APP_CONFIG;
  if (appConfig) {
    return allConfigs[appConfig];
  } else {
    if (url?.host.toLowerCase().indexOf('namastream') !== -1) {
      return namastreamConfig;
    } else {
      return heymarvelousConfig;
    }
  }
}
