export default defineNuxtRouteMiddleware((to, from) => {

    const podcastEpisodeMatch = to.path.match(/^\/podcasts\/(\d+)\/episode\/(\d+)$/)
    if (podcastEpisodeMatch) {
        const [, podcastId, episodeId] = podcastEpisodeMatch
        return navigateTo(`/podcasts/${podcastId}/episodes/${episodeId}`, { redirectCode: 301 })
    }

    const lessonPlayMatch = to.path.match(/^\/product\/(\d+)\/lesson\/(\d+)\/play\/(\d+)$/)
    if (lessonPlayMatch) {
        const [, productId, lessonId, playId] = lessonPlayMatch
        return navigateTo(`/product/${productId}/lessons/${lessonId}/play/${playId}`, { redirectCode: 301 })
    }

    const studioLessonPlayMatch = to.path.match(/^\/(\w+)\/product\/(\d+)\/lesson\/(\d+)\/play\/(\d+)$/)
    if (studioLessonPlayMatch) {
        const [, studioUrl, productId, lessonId, playId] = studioLessonPlayMatch
        return navigateTo(`${studioUrl ? '/'+studioUrl : ''}/product/${productId}/lessons/${lessonId}/play/${playId}`, { redirectCode: 301 })
    }
})