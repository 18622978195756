import edjsHTML from 'editorjs-html';
import DOMPurify from 'isomorphic-dompurify';

const hasOwnProperty = Object.prototype.hasOwnProperty;
export function hasOwn(obj, key) {
  return hasOwnProperty.call(obj, key);
}

export function sanitize(dirtyHtml) {
  return DOMPurify.sanitize(dirtyHtml, {
    ADD_TAGS: ['iframe', 'div'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  });
}

export function makeid(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function imageBlockParser(block) {
  const imgHTML = `<img src="${
    block.data?.url || block.data?.file?.url
  }" class="mb-3 ${block.data?.stretched ? 'w-100' : ''}"/>`;
  if (block.data?.caption) {
    return `
      <a href="${block.data?.caption}" target="_blank">
        ${imgHTML}
      </a>
    `;
  } else {
    return imgHTML;
  }
}

function breakLineParser(block) {
  if (block.data.divider) {
    return '<hr/>';
  } else {
    return '<br/>';
  }
}

function checklistParser(block) {
  let val = '<ul class="fa-ul ml-0">';
  for (const item of block.data.items) {
    val += `<li class="mb-3 align-items-center">
            <i class="${
              item.checked ? 'fas fa-check' : 'far fa'
            }-circle c-brand-color ms-2"></i>
            ${item.text}
            </li>`;
  }
  val += '</ul>';
  return val;
}

function tableParser(block) {
  let val = '<table class="table table-bordered">';
  for (const row of block.data.content) {
    val += '<tr class="h5">';
    for (const data of row) {
      val += `<td> ${data} </td>`;
    }
    val += '</tr>';
  }
  val += '</table>';
  return val;
}

function alignableParagraphParser(block) {
  if (block.data.alignment) {
    return `<p class="text-${block.data.alignment}">${block.data.text}</p>`;
  } else if (block.tunes?.alignmentTuneTool?.alignment) {
    const alignment = block.tunes?.alignmentTuneTool?.alignment;
    return `<p class="text-${alignment}">${block.data.text}</p>`;
  } else {
    return `<p>${block.data.text}</p>`;
  }
}

function headerParser(block) {
  if (block.tunes?.alignmentTuneTool?.alignment) {
    const alignment = block.tunes?.alignmentTuneTool?.alignment;
    return `<h${block.data.level} class="text-${alignment}">${block.data.text}</h${block.data.level}>`;
  } else {
    return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
  }
}

function embedParser(block) {
  const embed = `<div class="embed"><iframe width="${block.data?.width}"
                         height="${block.data?.height}"
                         src="${block.data?.embed}"
                         frameBorder="0">  
                 </iframe></div>`;
  return embed;
}

export function convertAlignableParagraphToParagraph(jsonString) {
  return jsonString.replaceAll('alignableParagraph', 'paragraph');
}

export function renderHtmlFromEditorJS(jsonData) {
  if (!jsonData) return '';
  try {
    const jsonValue = JSON.parse(jsonData);
    const customParsers = {
      image: imageBlockParser,
      breakLine: breakLineParser,
      alignableParagraph: alignableParagraphParser,
      paragraph: alignableParagraphParser,
      checklist: checklistParser,
      header: headerParser,
      table: tableParser,
      embed: embedParser,
    };
    const edjsParser = edjsHTML(customParsers);
    const parsedHTML = edjsParser.parse(jsonValue);
    return sanitize(parsedHTML.join(''));
  } catch (error) {
    console.error('Failed to parse JSON: ', jsonData);
  }
  return '';
}

export function trim(str) {
  if (!str) {
    return '';
  }
  return str.trim();
}

export function cleanedFileName(filename) {
  if (!filename) {
    return '';
  }
  const reg = /^[0-9]+-/;
  return filename.match(reg) ? filename.replace(reg, '') : filename;
}

// export function getAvatarURL(fullName, background, color, fontSize, size) {
//   return encodeURI(
//     `https://avatar.oxro.io/avatar.svg?name=${fullName}&background=${background.replace(
//       '#',
//       ''
//     )}&color=${color.replace(
//       '#',
//       ''
//     )}&fontSize=${fontSize}&width=${size.replace(
//       'px',
//       ''
//     )}&height=${size.replace('px', '')}`
//   );
// }

function generateSVG({
  background,
  color = '#fff',
  name = 'O',
  length = 2,
  width = 500,
  height = 500,
  fontSize = 250,
  rounded = false,
  caps = true,
  bold = false,
}) {
  // Input validation
  background =
    background && /^[0-9A-F]{6}$/i.test(background) ? '#' + background : null;

  const words = name.split(/[\s-]+/).slice(0, length);

  let letters = '';

  for (const word of words) {
    if (/^\d$/.test(word)) {
      letters += word;
    } else {
      const first = word[0];
      letters += /^\d$/.test(first) ? '' : first;
    }
  }

  if (!background) {
    //If not set or defined, pick a random sexy color.
    const colors = [
      '#e53935',
      '#d81b60',
      '#8e24aa',
      '#5e35b1',
      '#3949ab',
      '#1e88e5',
      '#039be5',
      '#00acc1',
      '#00897b',
      '#43a047',
      '#7cb342',
      '#c0ca33',
      '#fdd835',
      '#ffb300',
      '#fb8c00',
      '#f4511e',
      '#6d4c41',
      '#757575',
      '#546e7a',
    ];
    const randomColorKey =
      parseInt(
        name
          .split('')
          .reduce((a, b) => (a << 5) - a + b.charCodeAt(0), 0)
          .toString(16)
          .slice(-8),
        16
      ) % colors.length;
    background = colors[randomColorKey];
  }

  if (caps) {
    letters = letters.toUpperCase();
  } else {
    letters = letters.toLowerCase();
  }

  const style = bold ? 'font-weight:700;' : '';

  return `<svg style="${style}" width="${width}px" height="${height}px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs></defs><rect x="0" y="0" width="500" height="500" rx="${rounded}" style="fill:${background}"/><text x="50%" y="50%" dy=".1em" fill="${color}" text-anchor="middle" dominant-baseline="middle" style="font-family: Poppins, sans-serif; font-size: ${fontSize}px; line-height: 1">${letters}</text></svg>`;
}

export function getAvatarURL(fullName, background, color, fontSize, size) {
  const svgString = generateSVG({
    name: fullName,
    background: background.replace('#', ''),
    color: color,
    fontSize: fontSize,
    width: size.replace('px', ''),
    height: size.replace('px', ''),
  });
  return `data:image/svg+xml,${encodeURIComponent(svgString)}`;
}

export function adjustColor(color, amount) {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, color =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
}

export function removeTrailingSlash(str) {
  return str.replace(/\/+$/, '');
}

export function slugify(s) {
  return `${s.trim().replace(/[^0-9a-z-]/gi, '')}`;
}

export function priceWithTax(price, taxRate) {
  if (!taxRate) return parseFloat(price);

  return (
    parseFloat(price) +
    Math.round(parseFloat(price) * (parseFloat(taxRate) / 100.0) * 100) / 100
  );
}
