import { defineStore } from 'pinia';
import api from '@/api/community';
import _ from 'lodash-es';

const findComment = (comments, commentId) => {
  for (let i = 0; i < comments.length; i++) {
    if (comments[i].id === commentId) {
      return {
        commentsArray: comments,
        comment: comments[i],
      };
    }
    const found = findComment(comments[i].replies, commentId);
    if (found) return found;
  }
  return null;
};

interface State {
  numberOfNotifications: number;
  numberOfStudioNotifications: number;
  numberOfChatNotifications: number;
  numberOfStudioChatNotifications: number;
  notificationsToken: string | null;
  studioNotificationsToken: string | null;
  notifications: CommunityNotificationsResponse;
  studioNotifications: CommunityNotificationsResponse;
  studentCommunityProfileData: null | Object;
  notificationsLimit: number;
  notificationsOffset: number;
  studioNotificationsOffset: number;
  canLoadMoreNotifications: boolean;
  canLoadMoreStudioNotifications: boolean;
  canLoadMoreGiphies: boolean;
  interactAsStudio: boolean;
  teacherGroups: CommunityGroupsResponse[] | null;
  studioGroups: CommunityGroupsResponse[] | null;
  currentGroup: CommunityGroupsResponse | null;
  comments: CommunityCommentResponse[] | null;
  studioMembers: StudioCommunityMembersResponse[] | null;
  chatClient: object | null;
}

export const useCommunityStore = defineStore('community', {
  state: ():State => {
    return {
      numberOfNotifications: 0,
      numberOfStudioNotifications: 0,
      numberOfChatNotifications: 0,
      numberOfStudioChatNotifications: 0,
      notificationsToken: null,
      studioNotificationsToken: null,
      notifications: {} as CommunityNotificationsResponse,
      studioNotifications: {} as CommunityNotificationsResponse,
      studentCommunityProfileData: null,
      notificationsLimit: 10,
      notificationsOffset: 0,
      studioNotificationsOffset: 0,
      canLoadMoreNotifications: false,
      canLoadMoreStudioNotifications: false,
      canLoadMoreGiphies: false,
      interactAsStudio: false,
      teacherGroups: [],
      studioGroups: [],
      currentGroup: null,
      comments: [],
      studioMembers: [],
      chatClient: null,
    };
  },
  getters: {},
  actions: {
    commentAdded(comment) {
      if (!comment.parent) {
        this.comments = [comment, ...this.comments];
      } else {
        const foundParentObj = findComment(this.comments, comment.parent);
        if (!foundParentObj) return;
        if (!foundParentObj.comment) return;
        foundParentObj.comment.replies = [
          comment,
          ...foundParentObj.comment.replies,
        ];
        this.comments = _.cloneDeep(this.comments);
      }
    },
    disconnectChatClient() {
      if (this.chatClient) {
        try {
          // @ts-ignore
          this.chatClient.disconnect();
        } catch (error) {
          console.error(error);
        }
      }
    },
    async loadComments(
      { entityOwnerId, entityType, entityId, page, commentId }
    ) {
      if (page === 1) {
        this.comments = [];
      }
      const comments = await api.loadComments(
        entityOwnerId,
        entityType,
        entityId,
        this.interactAsStudio,
        page,
        commentId
      );
      if (page > 1) {
        // @ts-ignore
        this.comments = [...this.comments, ...comments];
      } else {
        this.comments = comments;
      }
      return this.comments;
    },
    // async loadScheduledComments(
    //   { commit, state },
    //   { entityOwnerId, entityType, entityId }
    // ) {
    //   const comments = await api.loadScheduledComments(
    //     entityOwnerId,
    //     entityType,
    //     entityId,
    //     state.interactAsStudio
    //   );
    //   return comments;
    // },
    async searchComments(
      { entityOwnerId, entityType, entityId, query, page }
    ) {
      if (page === 1) {
        this.comments = [];
      }
      const comments = await api.searchComments(
        entityOwnerId,
        entityType,
        entityId,
        this.interactAsStudio,
        query,
        page
      );
      if (page > 1) {
        // @ts-ignore
        this.comments = [...this.comments, ...comments];
      } else {
        this.comments = comments;
      }
      return this.comments;
    },
    async postComment(
      {
        parentId,
        entityOwnerId,
        entityType,
        entityId,
        content,
        text,
        additionalData,
        isStudio,
        attachment,
        hashtag,
        releaseOn,
      }
    ) {
      const comment = await api.postComment(
        parentId,
        entityOwnerId,
        entityType,
        entityId,
        content,
        text,
        additionalData,
        isStudio,
        attachment,
        hashtag,
        releaseOn
      );

      this.commentAdded(comment);
      return comment;
    },
    async editComment(
      {
        commentId,
        content,
        text,
        additionalData,
        isStudio,
        attachment,
        releaseOn,
        hashtag,
      }
    ) {
      const comment = await api.editComment(
        commentId,
        content,
        text,
        additionalData,
        isStudio,
        attachment,
        releaseOn,
        hashtag
      );

      const foundObj = findComment(this.comments, comment.id);
      if (!foundObj) return;
      if (!foundObj.comment || !foundObj.commentsArray) return;

      const index = foundObj.commentsArray.indexOf(foundObj.comment);
      foundObj.commentsArray.splice(index, 1, { ...comment });
      this.comments = _.cloneDeep(this.comments);

      return comment;
    },
    likeComment({ commentId }) {
      return api.likeComment(commentId, this.interactAsStudio);
    },
    unlikeComment({ commentId }) {
      return api.unlikeComment(commentId, this.interactAsStudio);
    },
    hideComment({ commentId }) {
      return api.hideComment(commentId, this.interactAsStudio);
    },
    showComment({ commentId }) {
      return api.showComment(commentId, this.interactAsStudio);
    },
    async deleteComment({ commentId }) {
      const comment = await api.deleteComment(
        commentId,
        this.interactAsStudio
      );
      const foundObj = findComment(this.comments, comment.id);
      if (!foundObj) return;
      if (!foundObj.comment || !foundObj.commentsArray) return;

      const index = foundObj.commentsArray.indexOf(foundObj.comment);
      foundObj.commentsArray.splice(index, 1);
      this.comments = _.cloneDeep(this.comments);

      return comment;
    },
    undeleteComment({ commentId }) {
      return api.undeleteComment(commentId, this.interactAsStudio);
    },
    deleteFlaggedComment({ commentId, isStudio }) {
      return api.deleteComment(commentId, isStudio);
    },
    loadUserData({ studioURL, uuid }) {
      return api.loadUserData(studioURL, uuid);
    },
    loadMyCommunityProfileData() {
      return api.loadCommunityProfileData(this.interactAsStudio);
    },
    async loadStudentCommunityProfileData() {
      this.studentCommunityProfileData = await api.loadCommunityProfileData(false);
      return this.studentCommunityProfileData;
    },
    clearStudentCommunityProfileData() {
      this.studentCommunityProfileData = null;
    },
    saveMyCommunityProfileData(data) {
      return api.saveCommunityProfileData(data);
    },
    async getNotificationsToken() {
      const tokenResponse: CommunityTokenResponse = await api.getNotificationsToken(
        false
      );
      this.setNotificationsToken(tokenResponse.token);
      return tokenResponse;
    },
    setNotificationsToken(token) {
      this.notificationsToken = token;
    },
    async getStudioNotificationsToken() {
      const tokenResponse: CommunityTokenResponse = await api.getNotificationsToken(
        true
      );
      this.setStudioNotificationsToken(tokenResponse.token);
      return tokenResponse;
    },
    setStudioNotificationsToken(token) {
      this.studioNotificationsToken = token;
    },
    searchUsers({ studioURL, query }) {
      return api.searchUsers(studioURL, query);
    },
    async markNotificationSeen({ notificationId, isStudio }) {
      await api.markNotificationSeen(notificationId, isStudio);
      if (isStudio) {
        this.setNumberOfStudioNotifications(this.numberOfStudioNotifications - 1);
      } else {
        this.setNumberOfNotifications(this.numberOfNotifications - 1);
      }
    },
    async loadNotifications({ markSeen }) {
      this.notificationsOffset = 0;
      this.notifications = {};
      const notifications = await api.loadNotifications(
        markSeen,
        this.notificationsOffset,
        this.notificationsLimit,
        false
      );
      this.notifications = notifications;
      this.numberOfNotifications = Math.max(notifications.unseen, 0);
      this.canLoadMoreNotifications = notifications.results.length > 0;
      return this.notifications;
    },
    async loadNextNotifications({ markSeen }) {
      this.notificationsOffset = this.notificationsOffset + this.notificationsLimit;

      const notifications = await api.loadNotifications(
        markSeen,
        this.notificationsOffset,
        this.notificationsLimit,
        false
      );
      this.notifications = {
        ...this.notifications,
        results: [
          ...(this.notifications?.results || []),
          ...(notifications?.results || []),
        ],
      };
      this.numberOfNotifications = Math.max(notifications.unseen, 0);
      this.canLoadMoreNotifications = notifications.results.length > 0;
      return this.notifications;
    },
    async loadStudioNotifications({ markSeen }) {
      this.studioNotificationsOffset = 0;
      this.studioNotifications = {};
      const notifications = await api.loadNotifications(
        markSeen,
        this.studioNotificationsOffset,
        this.notificationsLimit,
        true
      );
      this.studioNotifications = notifications;
      this.numberOfStudioNotifications = Math.max(notifications.unseen, 0);
      this.canLoadMoreStudioNotifications = notifications.results.length > 0;
      return this.studioNotifications;
    },
    setNumberOfChatNotifications(numberOfNotifications) {
      this.numberOfChatNotifications = Math.max(numberOfNotifications, 0);
    },
    // setNumberOfStudioChatNotifications(
    //   { commit, state },
    //   numberOfNotifications
    // ) {
    //   commit(
    //     types.SET_NUMBER_OF_STUDIO_CHAT_NOTIFICATIONS,
    //     numberOfNotifications
    //   );
    // },
    async loadNextStudioNotifications({ markSeen }) {
      this.studioNotificationsOffset = this.studioNotificationsOffset + this.notificationsLimit;
      const notifications = await api.loadNotifications(
        markSeen,
        this.studioNotificationsOffset,
        this.notificationsLimit,
        true
      );

      this.studioNotifications = {
        ...this.studioNotifications,
        results: [
          ...(this.studioNotifications?.results || []),
          ...(notifications?.results || []),
        ],
      };

      this.numberOfStudioNotifications = Math.max(notifications.unseen, 0);
      this.canLoadMoreStudioNotifications = notifications.results.length > 0;
      return this.notifications;
    },
    processNotificationsCallback(notificationsData) {
      const newNotifications = notificationsData.new?.length;
      const deletedNotifications = notificationsData.deleted?.length;
      if (newNotifications > 0) {
        this.setNumberOfNotifications(this.numberOfNotifications + newNotifications);
      }
      if (deletedNotifications > 0) {
        this.setNumberOfNotifications(this.numberOfNotifications - deletedNotifications);
      }
    },
    setNumberOfNotifications(numberOfNotifications) {
      this.numberOfNotifications = Math.max(numberOfNotifications, 0);
    },
    processStudioNotificationsCallback(notificationsData) {
      const newNotifications = notificationsData.new?.length;
      const deletedNotifications = notificationsData.deleted?.length;
      if (newNotifications > 0) {
        this.setNumberOfStudioNotifications(this.numberOfStudioNotifications + newNotifications);
      }
      if (deletedNotifications > 0) {
        this.setNumberOfStudioNotifications(this.numberOfStudioNotifications - deletedNotifications);
      }
    },
    setNumberOfStudioNotifications(numberOfNotifications) {
      this.numberOfStudioNotifications = Math.max(numberOfNotifications, 0);
    },
    async loadGiphy(
      { searchURL, apiKey, searchWord, searchLimit, offset }
    ) {
      const giphies = (await api.loadGiphy(
        searchURL,
        apiKey,
        searchWord,
        searchLimit,
        offset
      )) as any;
      this.canLoadMoreGiphies = giphies?.data?.length > 0;
      return giphies;
    },
    loadGroup({ id }) {
      return api.loadGroup(id);
    },
    // async loadGroups({ commit, state }) {
    //   commit(types.SET_TEACHER_GROUPS, []);
    //   const groups = await api.loadGroups();
    //   commit(types.SET_TEACHER_GROUPS, groups);
    //   return state.teacherGroups;
    // },
    // addGroup(
    //   _,
    //   {
    //     group_name,
    //     cover_photo,
    //     thumbnail_photo,
    //     products,
    //     hashtags_enabled,
    //     hashtags,
    //     display_members,
    //   }
    // ) {
    //   return api.addGroup(
    //     group_name,
    //     cover_photo,
    //     thumbnail_photo,
    //     products,
    //     hashtags_enabled,
    //     hashtags,
    //     display_members
    //   );
    // },
    // editGroup(
    //   _,
    //   {
    //     id,
    //     group_name,
    //     cover_photo,
    //     thumbnail_photo,
    //     products,
    //     hashtags_enabled,
    //     hashtags,
    //     display_members,
    //   }
    // ) {
    //   return api.editGroup(
    //     id,
    //     group_name,
    //     cover_photo,
    //     thumbnail_photo,
    //     products,
    //     hashtags_enabled,
    //     hashtags,
    //     display_members
    //   );
    // },
    // editGroupDescription({ id, about_text }) {
    //   return api.editGroupDescription(id, about_text);
    // },
    // archiveGroup({ id, active }) {
    //   return api.archiveGroup(id, active);
    // },
    // loadMembers({ groupId, q, page }) {
    //   return api.loadMembers(groupId, q, page);
    // },
    // getMember({ groupId, memberId }) {
    //   return api.getMember(groupId, memberId);
    // },
    // banMember({ groupId, memberId }) {
    //   return api.banMember(groupId, memberId);
    // },
    // unBanMember({ groupId, memberId }) {
    //   return api.unBanMember(groupId, memberId);
    // },
    flagComment({ commentId }) {
      return api.flagComment(commentId);
    },
    unFlagComment({ commentId }) {
      return api.unFlagComment(commentId);
    },
    pinComment({ commentId }) {
      return api.pinComment(commentId);
    },
    unPinComment({ commentId }) {
      return api.unPinComment(commentId);
    },
    loadFlaggedComments({ page, q, ordering }) {
      return api.loadFlaggedComments(page, q, ordering);
    },
    setInteractAsStudio(asStudio: boolean) {
      this.interactAsStudio = asStudio;
    },
    async loadStudioGroups({ studioURL }) {
      this.studioGroups = [];
      const groups = await api.loadStudioGroups(studioURL);
      this.studioGroups = groups;
      return this.studioGroups;
    },
    async loadStudioGroup({ studioURL, groupId }) {
      const group = await api.loadStudioGroup(studioURL, groupId);
      this.currentGroup = group;
      return group;
    },
    async loadCommunityGroupMembers(
      { studioURL, groupId, q, page, cancelToken }
    ) {
      this.studioMembers = [];
      const members = await api.loadCommunityGroupMembers(
        studioURL,
        groupId,
        q,
        page,
        cancelToken
      );
      this.studioMembers = members;
      return this.studioMembers;
    },
    loadStudioMember({ studioURL, groupId, memberId }) {
      return api.loadStudioMember(studioURL, groupId, memberId);
    },
    createPublicChatRoom({ studioURL, payload }) {
      return api.createPublicChatRoom(studioURL, payload);
    },
    editPublicChatRoom({ studioURL, roomId, payload }) {
      return api.editPublicChatRoom(studioURL, roomId, payload);
    },
    addMemberToChatRoom({ studioURL, member, roomId }) {
      return api.addMemberToChatRoom(studioURL, member, roomId);
    },
    createPrivateChatRoom({ studioURL, member }) {
      return api.createPrivateChatRoom(studioURL, member);
    },
    getPrivateChatRoom({ studioURL, roomId }) {
      return api.getPrivateChatRoom(studioURL, roomId);
    },
    getPublicChatRoom({ studioURL, roomId }) {
      return api.getPublicChatRoom(studioURL, roomId);
    },
    loadChatRoomMembers({ studioURL, roomId }) {
      return api.loadChatRoomMembers(studioURL, roomId);
    },
    removeMemberFromChatRoom({ studioURL, member, roomId }) {
      return api.removeMemberFromChatRoom(studioURL, member, roomId);
    },
    async loadChatRooms({ studioURL, query, id, page }) {
      return api.loadChatRooms(studioURL, query, id, page);
    },
    communityChatClient({ chatClient }) {
      this.chatClient = chatClient;
      return this.chatClient;
    },
    // async getCommentLikes(params): Promise<PaginatedResponse<any>> {
    //   const data = await api.getCommentLikes(params.page, params.id);
    //   return data;
    // },
    rejoinGroup({ groupId, studioURL }) {
      return api.rejoinGroup(groupId, studioURL);
    },
    leaveGroup({ groupId, studioURL }) {
      return api.leaveGroup(groupId, studioURL);
    },
  },
  // mutations: {
  //   [types.SET_NOTIFICATIONS](
  //     state: State,
  //     notifications: CommunityNotificationsResponse
  //   ) {
  //     state.notifications = notifications;
  //   },
  //   [types.SET_NEXT_NOTIFICATIONS](
  //     state: State,
  //     notifications: CommunityNotificationsResponse
  //   ) {
  //     state.notifications = {
  //       ...state.notifications,
  //       results: [
  //         ...(state.notifications?.results || []),
  //         ...(notifications?.results || []),
  //       ],
  //     };
  //   },
  //   [types.SET_STUDIO_NOTIFICATIONS](
  //     state: State,
  //     notifications: CommunityNotificationsResponse
  //   ) {
  //     state.studioNotifications = notifications;
  //   },
  //   [types.SET_NEXT_STUDIO_NOTIFICATIONS](
  //     state: State,
  //     notifications: CommunityNotificationsResponse
  //   ) {
  //     state.studioNotifications = {
  //       ...state.studioNotifications,
  //       results: [
  //         ...(state.studioNotifications?.results || []),
  //         ...(notifications?.results || []),
  //       ],
  //     };
  //   },
  //   [types.SET_NOTIFICATIONS_OFFSET](
  //     state: State,
  //     notificationsOffset: number
  //   ) {
  //     state.notificationsOffset = notificationsOffset;
  //   },
  //   [types.SET_STUDIO_NOTIFICATIONS_OFFSET](
  //     state: State,
  //     notificationsOffset: number
  //   ) {
  //     state.studioNotificationsOffset = notificationsOffset;
  //   },
  //   [types.SET_CAN_LOAD_MORE_NOTIFICATIONS](
  //     state: State,
  //     canLoadMoreNotifications: boolean
  //   ) {
  //     state.canLoadMoreNotifications = canLoadMoreNotifications;
  //   },
  //   [types.SET_CAN_LOAD_MORE_STUDIO_NOTIFICATIONS](
  //     state: State,
  //     canLoadMoreNotifications: boolean
  //   ) {
  //     state.canLoadMoreStudioNotifications = canLoadMoreNotifications;
  //   },
  //   [types.SET_NUMBER_OF_NOTIFICATIONS](
  //     state: State,
  //     numberOfNotifications: number
  //   ) {
  //     state.numberOfNotifications = Math.max(numberOfNotifications, 0);
  //   },
  //   [types.SET_NUMBER_OF_STUDIO_NOTIFICATIONS](
  //     state: State,
  //     numberOfNotifications: number
  //   ) {
  //     state.numberOfStudioNotifications = Math.max(numberOfNotifications, 0);
  //   },
  //   [types.SET_NUMBER_OF_CHAT_NOTIFICATIONS](
  //     state: State,
  //     numberOfNotifications: number
  //   ) {
  //     state.numberOfChatNotifications = Math.max(numberOfNotifications, 0);
  //   },
  //   [types.SET_NUMBER_OF_STUDIO_CHAT_NOTIFICATIONS](
  //     state: State,
  //     numberOfNotifications: number
  //   ) {
  //     state.numberOfStudioChatNotifications = Math.max(
  //       numberOfNotifications,
  //       0
  //     );
  //   },
  //   [types.SET_NOTIFICATIONS_TOKEN](state: State, token: string) {
  //     state.notificationsToken = token;
  //   },
  //   [types.SET_STUDIO_NOTIFICATIONS_TOKEN](state: State, token: string) {
  //     state.studioNotificationsToken = token;
  //   },
  //   [types.SET_CAN_LOAD_MORE_GIPHY](state: State, canLoadMoreGiphies: boolean) {
  //     state.canLoadMoreGiphies = canLoadMoreGiphies;
  //   },
  //   [types.SET_INTERACT_AS_STUDIO](state: State, interactAsStudio: boolean) {
  //     state.interactAsStudio = interactAsStudio;
  //   },
  //   [types.SET_TEACHER_GROUPS](
  //     state: State,
  //     groups: CommunityGroupsResponse[]
  //   ) {
  //     state.teacherGroups = groups;
  //   },
  //   [types.SET_STUDIO_GROUPS](state: State, groups: CommunityGroupsResponse[]) {
  //     state.studioGroups = groups;
  //   },
  //   [types.SET_STUDIO_GROUP](state: State, group: CommunityGroupsResponse) {
  //     state.currentGroup = group;
  //   },
  //   [types.SET_COMMENTS](state: State, comments: CommunityCommentResponse[]) {
  //     state.comments = comments;
  //   },
  //   [types.SET_STUDIO_MEMBERS](
  //     state: State,
  //     members: StudioCommunityMembersResponse[]
  //   ) {
  //     state.studioMembers = members;
  //   },
  //   [types.SET_CHAT_CLIENT](state: State, chatClient: object) {
  //     state.chatClient = chatClient;
  //   },
  //   [types.COMMENT_ADDED](state: State, comment) {
  //     if (!comment.parent) {
  //       state.comments = [comment, ...state.comments];
  //     } else {
  //       const foundParentObj = findComment(state.comments, comment.parent);
  //       if (!foundParentObj) return;
  //       if (!foundParentObj.comment) return;
  //       foundParentObj.comment.replies = [
  //         comment,
  //         ...foundParentObj.comment.replies,
  //       ];
  //       state.comments = _.cloneDeep(state.comments);
  //     }
  //   },
  //   [types.COMMENT_EDITED](state: State, comment) {
  //     const foundObj = findComment(state.comments, comment.id);
  //     if (!foundObj) return;
  //     if (!foundObj.comment || !foundObj.commentsArray) return;

  //     const index = foundObj.commentsArray.indexOf(foundObj.comment);
  //     foundObj.commentsArray.splice(index, 1, { ...comment });
  //     state.comments = _.cloneDeep(state.comments);
  //   },
  //   [types.COMMENT_DELETED](state: State, comment) {
  //     const foundObj = findComment(state.comments, comment.id);
  //     if (!foundObj) return;
  //     if (!foundObj.comment || !foundObj.commentsArray) return;

  //     const index = foundObj.commentsArray.indexOf(foundObj.comment);
  //     foundObj.commentsArray.splice(index, 1);
  //     state.comments = _.cloneDeep(state.comments);
  //   },
  // },
  persist: {
    paths: ['interactAsStudio'],
  },
});
