import Cookies from 'js-cookie';

export default () => {
  try {
    const vuexLocalStorage = localStorage.getItem('vuex');
    if(vuexLocalStorage) {
      const vuex = JSON.parse(vuexLocalStorage);
      if(vuex.user && vuex.user.accessToken) {
        Cookies.set('accessToken', vuex.user.accessToken);
        localStorage.removeItem('vuex');
        window.location.reload();
      }
    }
  } catch (e) {
    console.error('Error while migrating legacy Vuex to cookies', e);
  }
}