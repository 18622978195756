interface State {
  isLoading: boolean;
  isScrolling: boolean;
  selectedMenu: string;
  isMainNavOpen: boolean;
  isStudioMobileProfileMenuOpen: boolean;
  isDirty: boolean;
  formSaveFun: Promise<void> | null;
  studioUrl: string | null; // for custom domains, we store the studioUrl in the store when router is loaded
  affiliateCode: string | null;
  showRefreshModal: boolean;
  backgroundImage: MediaResponse | null;
  showBioUpgrade: boolean;
  showLoginModal: boolean;
  isPWA: boolean;
  showInstallPWA: boolean;
  showCookieNotice: boolean;
  previewTemplate: string | null;
  releaseVersion: null | number;
}
export const useGlobalsStore = defineStore('globals', {
  state: () => ({ 
    isLoading: false,
    isScrolling: false,
    selectedMenu: 'Dashboard',
    isMainNavOpen: false,
    isStudioMobileProfileMenuOpen: false,
    isDirty: false,
    formSaveFun: null,
    studioUrl: null,
    affiliateCode: null,
    showRefreshModal: false,
    backgroundImage: null,
    showBioUpgrade: true,
    showLoginModal: false,
    isPWA: false,
    showInstallPWA: true,
    showCookieNotice: true,
    previewTemplate: null,
    releaseVersion: null,
  }) as State,
  getters: {
  //   backgroundImage(state, getters, rootState, rootGetters) {
  //     const teacherBio = rootGetters['studio/marvelousBio'];
  //     return teacherBio?.image;
  //   },
    showUserbackInStudio(): boolean {
      if (process.env.VUE_APP_USERBACK_DISPLAY_IN_STUDIO !== 'true')
        return false;
      return !this.isPWA;
    },
  },
  actions: {
    setShowRefreshModal(show: boolean) {
      this.showRefreshModal = show;
    },
    setShowLoginModal(show: boolean) {
      this.showLoginModal = show;
    },
    setLoading(isLoading: boolean) {
      this.isLoading = isLoading;
    },
    setIsScrolling(isScrolling: boolean) {
      this.isScrolling = isScrolling;
    },
    setSelectedMenu(selectedMenu: string) {
      this.selectedMenu = selectedMenu;
    },
    resetState() {
      // dispatch('user/resetState', null, { root: true });
      // dispatch('studio/resetState', null, { root: true });
      // dispatch('product/resetState', null, { root: true });
      // dispatch('setting/resetState', null, { root: true });
    },
    openMainNav(isMainNavOpen = true) {
      this.isMainNavOpen = isMainNavOpen;
    },
    openStudioMobileProfileMenu(isStudioMobileProfileMenuOpen = true) {
      this.isStudioMobileProfileMenuOpen = isStudioMobileProfileMenuOpen;
    },
    setDirty(isDirty: boolean) {
      this.isDirty = isDirty;
    },
    setFormSaveFun(fun: any) {
      this.formSaveFun = fun;
    },
    setStudioUrl(studioUrl: string) {
      this.studioUrl = studioUrl;
    },
    setAffiliateCode(affiliateCode: string) {
      this.affiliateCode = affiliateCode;
    },
    setShowBioUpgrade(show: boolean) {
      this.showBioUpgrade = show;
    },
    setIsPWA(isPWA: boolean) {
      this.isPWA = isPWA;
    },
    setShowInstallPWA(show: boolean) {
      this.showInstallPWA = show;
    },
    setShowCookieNotice(show: boolean) {
      this.showCookieNotice = show;
    },
    setPreviewTemplate(previewTemplate: string) {
      this.previewTemplate = previewTemplate;
    },
    setReleaseVersion(version: number) {
      if (this.releaseVersion == null) {
        this.releaseVersion = version;
      } else if (this.releaseVersion < version) {
        this.setShowRefreshModal(true);
      }
    },
  },
  persist: {
    paths: ['showInstallPWA', 'isPWA', 'showCookieNotice'],
  },
});
