import { api } from './api';

const BASE_PODCASTS_API_URL = 'api/podcasts';
const STUDIOS_BASE_URL = 'api/studios';

async function getPodcasts(): Promise<PodcastResponse[]> {
  const url = `${BASE_PODCASTS_API_URL}`;
  const result = await api.get<PodcastResponse[]>(url);
  return result.data;
}

async function loadStudioPodcasts(
  studioURL: string
): Promise<PodcastResponse[]> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/podcasts`;
  const result = await api.get<PodcastResponse[]>(url);
  return result.data;
}

async function loadStudioPodcast(
  studioURL: string,
  podcastId: number
): Promise<PodcastResponse[]> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/podcasts/${podcastId}`;
  const result = await api.get<PodcastResponse[]>(url);
  return result.data;
}

async function loadStudioRecentEpisodes(
  studioURL: string
): Promise<PodcastResponse[]> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/podcasts/recent_episodes`;
  const result = await api.get<PodcastResponse[]>(url);
  return result.data;
}

async function getPodcast(podcastId: number): Promise<PodcastResponse> {
  const result = await api.get<PodcastResponse>(
    `${BASE_PODCASTS_API_URL}/${podcastId}`
  );
  return result.data;
}

async function deletePodcast(podcastId: number): Promise<any> {
  const result = await api.delete<any>(`${BASE_PODCASTS_API_URL}/${podcastId}`);
  return result.data;
}

async function createPodcast(
  podcast: PodcastResponse
): Promise<PodcastResponse> {
  const result = await api.post(BASE_PODCASTS_API_URL, podcast);
  return result.data;
}

async function updatePodcast(
  podcast: PodcastResponse
): Promise<PodcastResponse> {
  const result = await api.put(
    `${BASE_PODCASTS_API_URL}/${podcast.id}`,
    podcast
  );
  return result.data;
}

async function setPodcastActiveFlag(
  podcastId: number,
  active: boolean
): Promise<CommunityGroupsResponse> {
  const result = await api.patch(`${BASE_PODCASTS_API_URL}/${podcastId}`, {
    active,
  });
  return result.data;
}

async function getPodcastEpisodes(
  podcastId: number
): Promise<PodcastContentResponse[]> {
  const result = await api.get<PodcastContentResponse[]>(
    `${BASE_PODCASTS_API_URL}/${podcastId}/content`
  );
  return result.data;
}

async function getPodcastEpisode(
  podcastId: number,
  episodeId: number
): Promise<PodcastContentResponse> {
  const result = await api.get<PodcastContentResponse>(
    `${BASE_PODCASTS_API_URL}/${podcastId}/content/${episodeId}`
  );
  return result.data;
}

async function addPodcastEpisode(
  podcastId: number,
  episode
): Promise<PodcastContentResponse> {
  const result = await api.post<PodcastContentResponse>(
    `${BASE_PODCASTS_API_URL}/${podcastId}/content`,
    episode
  );
  return result.data;
}

async function updatePodcastEpisode(
  podcastId: number,
  episode
): Promise<PodcastContentResponse> {
  const result = await api.patch<PodcastContentResponse>(
    `${BASE_PODCASTS_API_URL}/${podcastId}/content/${episode.id}`,
    episode
  );
  return result.data;
}

async function publishPodcastEpisode(
  podcastId: number,
  episodeId: number
): Promise<PodcastContentResponse> {
  const result = await api.post<PodcastContentResponse>(
    `${BASE_PODCASTS_API_URL}/${podcastId}/content/${episodeId}/publish`
  );
  return result.data;
}

async function deletePodcastEpisode(
  podcastId: number,
  episodeId: number
): Promise<any> {
  const result = await api.delete<any>(
    `${BASE_PODCASTS_API_URL}/${podcastId}/content/${episodeId}`
  );
  return result.data;
}

async function loadMembers(
  podcastId: number,
  q: string | null,
  page = 1
): Promise<any> {
  const result = await api.get(
    `${BASE_PODCASTS_API_URL}/${podcastId}/members`,
    {
      params: { page, q },
    }
  );
  return result.data;
}

async function banMember(podcastId: number, memberId: number): Promise<any> {
  const result = await api.post(
    `${BASE_PODCASTS_API_URL}/${podcastId}/members/${memberId}/ban`
  );
  return result.data;
}

async function unBanMember(podcastId: number, memberId: number): Promise<any> {
  const result = await api.post(
    `${BASE_PODCASTS_API_URL}/${podcastId}/members/${memberId}/unban`
  );
  return result.data;
}

export default {
  getPodcasts,
  loadStudioPodcasts,
  loadStudioPodcast,
  loadStudioRecentEpisodes,
  getPodcast,
  deletePodcast,
  createPodcast,
  updatePodcast,
  setPodcastActiveFlag,
  getPodcastEpisodes,
  getPodcastEpisode,
  addPodcastEpisode,
  updatePodcastEpisode,
  publishPodcastEpisode,
  deletePodcastEpisode,
  loadMembers,
  banMember,
  unBanMember,
};
