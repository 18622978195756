<template>
  <div class="container py-5">
    <div class="text-center mt-5 pt-5">
      <h1 class="mt-5 mb-4">{{ $t('STUDIO.TEXT.404_TITLE') }}</h1>
      <p>{{ $t('STUDIO.TEXT.404_TEXT') }}</p>
    </div>
    <div class="text-center">
      <button
        @click="$router.go(-2)"
        type="button"
        name="button"
        class="btn btn-brand"
      >
        {{ $t('GENERAL.BUTTON.GO_BACK') }}
      </button>
    </div>
  </div>
</template>

<script></script>

<style lang="scss" scoped></style>
