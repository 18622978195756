import {
  trackFbPixel,
  gaTrackPageView,
} from '@/services/analytics';
import { useStudioStore } from '@/stores';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()

  router.afterEach((to, from) => {
    const studioURL = nuxtApp.$studioURL;

    const studioFbPixelId = useStudioStore().currentStudio?.fb_pixel_id;

    if (studioFbPixelId) trackFbPixel(studioFbPixelId);
    gaTrackPageView(to.path, studioURL);
  })
})