<template>
  <button
    class="btn"
    :class="`btn-${color} ${computedSize}`"
    :type="type"
  >
    <span class="d-flex justify-content-start align-items-center">
      <HMSpinner class="spinner" v-if="loading" />
      <slot></slot>
    </span>
  </button>
</template>

<script>
import HMSpinner from './HMSpinner.vue';

export default {
  name: 'HMButton',
  comments: {
    HMSpinner,
  },
  props: {
    type: {
      type: String,
      default: 'button',
    },
    color: {
      type: String,
      default: 'none',
    },
    size: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick(event) {
      this.$emit('click', event);
    },
  },
  computed: {
    computedSize() {
      return this.size ? `btn-${this.size}` : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.spinner {
  height: 20px;
  width: 20px;
  margin-right: 0.5rem;
}
</style>

<style lang="scss">
@import '@/styles/components-variables.scss';

.btn {
  &.btn-outline-white {
    background-color: $white;
    &:hover {
      background-color: $coral;
      color: $white;
      .hm-icon-button {
        color: $white;
      }
    }
    .hm-icon-button {
      color: $coral;
    }
  }

  &.btn-charcoal {
    background-color: $charcoal;
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &.btn-outline-gray {
    color: $dark-gray;
    border-color: $light-gray;
    &:hover {
      background-color: $light-gray;
    }
  }

  &.btn-outline-mustard {
    color: $white;
    background-color: $mustard;
    border-color: $light-gray;
    &:hover {
      color: $white;
      background-color: darken($mustard, 5%);
    }
  }

  &.btn-charcoal {
    color: $white;
    background-color: $charcoal;
    border-color: $charcoal;
    &:hover {
      color: $white;
      background-color: darken($charcoal, 5%);
    }
  }

  &.btn-gray {
    color: $charcoal;
    border-color: $light-gray;
    background-color: $light-gray;
    &:hover {
      background-color: darken($light-gray, 5%);
      border-color: darken($light-gray, 5%);
    }
  }

  &.btn-outline-light-gray {
    color: $dark-gray;
    border-color: $light-gray;
    background-color: $light-gray;
    &:hover {
      background-color: $medium-gray;
    }
  }

  &.btn-zoom {
    border-color: #2d8bff;
    background-color: #2d8bff;
    color: $white;
    &:hover {
      background-color: darken(#2d8bff, 5%);
      color: $white;
    }
    &.end {
      border-color: $medium-gray;
      background-color: $white;
      color: $dark-gray;
      &:hover {
        background-color: $light-gray;
      }
    }
  }
  &.btn-paypal {
    border-color: #ffcc00;
    background-color: #ffcc00;
    color: $charcoal;
    &:hover {
      background-color: darken(#ffcc00, 5%);
      color: $charcoal;
    }
    &.end {
      border-color: $medium-gray;
      background-color: $white;
      color: $dark-gray;
      &:hover {
        background-color: $light-gray;
      }
    }
  }

  &.btn-brand,
  &.btn-white-border {
    border-color: var(--brand-color, $coral);
    background-color: var(--brand-color, $coral);
    color: $white;
    &:hover {
      filter: brightness(95%);
      color: $white;
    }
    &.end {
      border-color: $medium-gray;
      background-color: $white;
      color: $dark-gray;
      &:hover {
        background-color: $light-gray;
      }
    }
  }
  &.btn-white-border {
    border: 1px solid $white !important;
  }
  &.btn-brand-opacity-05 {
    background-color: var(--brand-color-with-opacity-05, $coral);
    color: var(--brand-color, $coral);
    &:hover {
      filter: brightness(95%);
      color: var(--brand-color, $coral);
    }
  }
  &.btn-brand-opacity-03 {
    background-color: var(--brand-color-with-opacity-03, $coral);
    color: var(--brand-color, $coral);
    &:hover {
      filter: brightness(95%);
      color: var(--brand-color, $coral);
    }
  }
  &.btn-brand-opacity-01 {
    background-color: var(--brand-color-with-opacity-01, $coral);
    color: var(--brand-color, $coral);
    &:hover {
      filter: brightness(95%);
      color: var(--brand-color, $coral);
    }
  }
  &.btn-outline-gray {
    color: $dark-gray;
    border-color: $light-gray;
    background-color: $white;
    &:hover {
      background-color: darken($white, 5%);
    }
  }
  &.btn-outline-brand {
    color: var(--brand-color, $coral);
    border-color: var(--brand-color, $coral);
    background-color: $white;
    &:hover {
      filter: brightness(95%);
      color: var(--brand-color, $coral);
    }
  }
  &.btn-social {
    background-color: white;
    border-color: $light-gray;
    color: $charcoal;
    &:hover {
      background-color: darken($lightest-gray, 5%);
      color: $charcoal;
    }
  }
  &.btn-fb {
    background-color: #679ff2;
    color: white;
    &:hover {
      background-color: darken(#679ff2, 5%);
      color: white;
    }
  }
  &.btn-g {
    background-color: $coral;
    color: white;
    &:hover {
      background-color: darken($coral, 5%);
      color: white;
    }
  }
  &.btn-a {
    background-color: black;
    color: white;
    &:hover {
      color: white;
    }
  }
  &.btn-selected {
    background-color: $light-green;
    color: white;
    &:hover {
      color: white;
    }
  }
  &.btn-light-green {
    background-color: $light-green;
    color: $charcoal;
    &:hover {
      color: $charcoal;
    }
  }
  &.btn-mustard {
    background-color: $mustard;
    color: white;
    &:hover {
      color: white;
    }
  }
  &.btn-selected-incorrect {
    background-color: $coral;
    color: white;
    &:hover {
      color: white;
    }
  }
  &.btn-minimal-gray {
    color: $dark-gray;
    &:hover {
      color: $dark-gray;
    }
  }
}
</style>
