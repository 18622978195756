import { api } from './api';

const STUDIOS_BASE_URL = 'api/studios';

async function getQuiz(studioURL: String, id: Number): Promise<SimpleQuizResponse> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/quizzes/${id}`
  );
  return result.data;
}

async function viewQuiz(studioURL: String, id: Number): Promise<void> {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/quizzes/${id}/view`
  );
  return result.data;
}

async function getSolvedQuizzes(studioURL: String): Promise<StudentSolvedQuizResponse> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/solved-quizzes`
  );
  return result.data;
}

async function getSolvedQuiz(
  studioURL: String,
  id: Number
): Promise<StudentSolvedQuizResponse> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/solved-quizzes/${id}`
  );
  return result.data;
}

async function startQuiz(
  studioURL: String,
  quiz_id: Number
): Promise<StudentSolvedQuizResponse> {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/solved-quizzes/start`,
    { quiz_id }
  );
  return result.data;
}

async function retakeQuiz(studioURL: String, quiz_id: Number): Promise<void> {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/solved-quizzes/retake`,
    { quiz_id }
  );
  return result.data;
}

async function finishQuiz(studioURL: String, id: Number): Promise<void> {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/solved-quizzes/${id}/finish`
  );
  return result.data;
}

async function answerQuizQuestion(
  studioURL: String,
  solvedQuizId: Number,
  data: StudentQuizAnswerRrequest
): Promise<void> {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/solved-quizzes/${solvedQuizId}/answer`,
    data
  );
  return result.data;
}

async function addSolvedQuizAnswerFile(
  studioURL: String,
  solvedQuizId: Number,
  solvedQuizAnswerId: Number,
  file_id: Number,
): Promise<void> {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/solved-quizzes/${solvedQuizId}/answers/${solvedQuizAnswerId}/upload`,
    { file_id }
  );
  return result.data;
}

async function quizInfo(studioURL: String, quizId: Number): Promise<SimpleQuizResponse> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/quizzes/info/${quizId}`
  );
  return result.data;
}

export default {
  getQuiz,
  viewQuiz,
  getSolvedQuizzes,
  getSolvedQuiz,
  startQuiz,
  retakeQuiz,
  finishQuiz,
  answerQuizQuestion,
  addSolvedQuizAnswerFile,
  quizInfo,
};
