<template>
  <div>
    <span class="d-block" v-if="title">
      {{ title }}
    </span>
    <small class="d-block" v-if="text">
      {{ text }}
    </small>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
  },
};
</script>
