import moment from 'moment';
import _ from 'lodash-es';
import i18n from '@/utils/localization';

function formattedEvent(
  event: EventResponse,
  date_start,
  date_end,
  isLoggedIn = true
): CalendarEvent {
  date_start = moment(date_start);
  date_end = moment(date_end);
  const end_of_week_data = moment(date_start).weekday(6);
  const now = moment();
  const dayDescription = date_start.calendar({
    lastDay: `[${i18n.t('GENERAL.CALENDAR.YESTERDAY')}]`,
    sameDay: `[${i18n.t('GENERAL.CALENDAR.TODAY')}]`,
    nextDay: `[${i18n.t('GENERAL.CALENDAR.TOMORROW')}]`,
    nextWeek: 'dddd',
    lastWeek: `[${i18n.t('GENERAL.CALENDAR.LAST')}] dddd`,
    sameElse: 'MMM D',
  });

  // workaround for events ending on seemingly same time,
  // because of different daylight savings settings of start and end dates
  if (date_end.isSameOrBefore(date_start)) {
    date_end.add({ hours: 1, minutes: 0 });
  }

  const startMinus1h = moment(date_start).subtract({ hours: 1, minutes: 0 });
  const isEventTime = now.isBetween(date_start, date_end);
  const isBeforeEvent = now.isBefore(date_start);
  const isExpired = now.isAfter(date_end);

  const recordingAvailable = event.recordings?.length > 0;

  let usedSessionText: string | null = null;
  if (event.number_of_included_sessions) {
    usedSessionText = `${event.used_sessions || 0} of ${
      event.number_of_included_sessions
    } sessions used`;
  } else if (event.used_sessions > 0) {
    usedSessionText = `Session #${event.used_sessions}`;
  }

  const isStarted: boolean =
    !!event.streaming_session_id ||
    (!!event.zoom_join_url && event.zoom_meeting_started);
  const isWaiting: boolean = isEventTime && !isStarted;
  const accessNotAllowed: boolean =
    !event.has_access &&
    (event.available_punches === 0 || !event.is_punchable_event);
  const canAccessWithPunchCard =
    !event.has_access &&
    event.is_punchable_event &&
    event.available_punches > 0;

  const statuses = {
    cancelled: event.is_cancelled,
    expired: isExpired,
    'no-access': accessNotAllowed,
    'classcard-access': canAccessWithPunchCard,
    'session-started': isStarted,
    'is-before-event': isBeforeEvent,
    'waiting-to-start': isWaiting,
    none: true,
  };

  const status = Object.entries(statuses).find(([status, state]) => state);

  return {
    ...event,
    id: event.id,
    access_code: event.access_code,
    classcards: event.classcards,
    is_cancelled: event.is_cancelled,
    event_start_datetime: event.event_start_datetime,
    event_end_datetime: event.event_end_datetime,
    event_name: event.event_name,
    event_type: event.event_type,
    date_start: date_start.format(),
    date_end: date_end.format(),
    duration_hours: event.duration_hours,
    duration_minutes: event.duration_minutes,
    event_description: event.event_description,
    event_description_new: event.event_description_new,
    typeDescription: 'REFACTOR FOR MOBILE',
    typeDescriptionShort: 'REFACTOR FOR MOBILE',
    usedSessionText,
    time: `${date_start.format('h:mm')} - ${date_end.format('h:mm a')}`,
    dayDescription,
    dayId: date_start.format('YYYYMMDD'),
    instructors: event.instructors || [],
    weekId: end_of_week_data.format('YYYYww'),
    unix: date_start.unix(),
    status: status && status.length > 0 ? status[0] : null,
    allDay: false,
    event: event,
    streaming_session_id: event.streaming_session_id,
    zoom_join_url: event.zoom_join_url,
    has_access: event.has_access,
    streaming_token: event.streaming_token,
    is_free_event: event.is_free_event,
    require_first_name: event.require_first_name,
    require_last_name: event.require_last_name,
    is_registered: event.is_registered,
    is_waitlist_registered: event.is_waitlist_registered,
    is_register_allowed: event.is_register_allowed,
    is_unregister_allowed: event.is_unregister_allowed,
    is_punchable_event: event.is_punchable_event,
    recordings: event.recordings,
    registration_required: event.registration_required,
    registration_limit: event.registration_limit,
    hide_registration_info: event.hide_registration_info,
    registration_close: event.registration_close,
    unregistration_close: event.unregistration_close,
    registration_closes_minutes_before_start:
      event.registration_closes_minutes_before_start,
    unregistration_closes_minutes_before_start:
      event.unregistration_closes_minutes_before_start,
    number_of_registrations: event.number_of_registrations,
    number_of_waitlist_registrations: event.number_of_waitlist_registrations,
    isExpired: isExpired,
    isStarted: isStarted,
    isWaiting: isWaiting,
    isBeforeEvent: isBeforeEvent,
    accessNotAllowed: accessNotAllowed,
  } as CalendarEvent;
}

function generateCalendarDays(
  calendarEvents: CalendarEvent[],
  number: number
): CalendarWeeksDay[] {
  const all = _.chain(calendarEvents).groupBy('dayId').sortBy('dayId').value();
  const days: CalendarWeeksDay[] = [];
  let day = moment();
  if (all && all.length > 0) day = moment(all[0][0].weekId, 'YYYYww');
  _.times(number, () => {
    const dayId = day.format('YYYYMMDD');
    const event = all.find(d => d[0].dayId == dayId);
    days.push({
      dayId,
      day: day.format('D'),
      weekDay: day.format('dddd'),
      weekDayShort: day.format('dd'),
      weekId: day.format('YYYYww'),
      monthId: day.format('MM'),
      month: day.format('MMMM'),
      eventsInDay: event ? event.length : 0,
      isDisabled: !event,
      isUpcoming: true,
    });
    day.add(1, 'day');
  });
  return days;
}

function getCalendarWeek(week: CalendarEventsByWeek): CalendarWeek {
  const today = moment();
  const day = moment(week.weekId, 'YYYYww');
  const weekDays: CalendarWeeksDay[] = [];
  _.times(7, () => {
    const dayId = day.format('YYYYMMDD');
    const eventsInDay = week.weekEvents.filter(event => event.dayId === dayId)
      .length;

    weekDays.push({
      dayId,
      day: day.format('D'),
      weekDay: day.format('dddd'),
      weekDayShort: day.format('dd'),
      weekId: day.format('YYYYww'),
      monthId: day.format('MM'),
      month: day.format('MMMM'),
      eventsInDay,
      isDisabled: !eventsInDay,
      isUpcoming: day.isSameOrAfter(today, 'day'),
    });
    day.add(1, 'day');
  });
  const last = _.last(weekDays);
  return {
    month: last ? last.month : '',
    weekId: week.weekId,
    weekDays,
  };
}

function generateCalendarWeeks(weeks: CalendarEventsByWeek[]): CalendarWeek[] {
  return weeks.map(getCalendarWeek);
}

function groupCalendarEventsByWeek(
  calendarEvents: CalendarEvent[]
): CalendarEventsByWeek[] {
  const all = _.chain(calendarEvents)
    .groupBy('weekId')
    .sortBy('weekId')
    .map(weekEvents => {
      const endOfWeekDate = moment(weekEvents[0].weekId, 'YYYYww').endOf(
        'week'
      );
      return {
        weekId: weekEvents[0].weekId,
        weekTitle: endOfWeekDate.format('MMM'),
        weekEvents,
      } as CalendarEventsByWeek;
    })
    .value();

  return all;
}

function groupCalendarEventsByDay(
  calendarEvents: CalendarEvent[]
): CalendarEventsByDay[] {
  const all = _.chain(calendarEvents)
    .groupBy('dayId')
    .sortBy('dayId')
    .map(
      dayEvents =>
        ({
          dayId: dayEvents[0].dayId,
          weekId: dayEvents[0].weekId,
          dayTitle: dayEvents[0].dayDescription,
          dayEvents,
        } as CalendarEventsByDay)
    )
    .value();

  return all;
}

function isSameDay(date1, date2) {
  return moment(date1).isSame(moment(date2), 'day');
}

function generateCalendarEvents(event): CalendarEvent[] {
  const allEvents: CalendarEvent[] = [];
  const startDate = moment(event.event_start_datetime);
  const endDate = moment(event.event_end_datetime);

  allEvents.push(formattedEvent(event, startDate, endDate, true));

  return allEvents;
}

function generateAllCalendarEvents(events: EventResponse[]): CalendarEvent[] {
  return _.chain(events)
    .map(event => generateCalendarEvents(event))
    .flatten()
    .sortBy('unix')
    .value();
}

function canShowMoreEvents(events: EventResponse[] = [], timeSpanDays = 0) {
  if (events && events.length)
    return events
      .map(event => {
        const endDate = moment(event.event_end_datetime);
        const maxDate = moment()
          .endOf('day')
          .add('days', timeSpanDays)
          .endOf('week');
        return maxDate < endDate;
      })
      .some(isMore => isMore);
  return false;
}

function getAddToGoogleCalendarLink(date_start, date_end, event) {
  const eventName = event.event_name;
  const accessCodeParam = event.access_code
    ? `?access_code=${event.access_code}`
    : '';
  const eventLink = `${window.location.origin}/${event.shop_url}/event/details/${event.id}${accessCodeParam}`;

  // dates
  const dateFormat = 'YYYYMMDDTHHmmss';
  const start = moment(date_start).format(dateFormat);
  const end = moment(date_end).format(dateFormat);
  const eventEndDate = moment(event.event_end_datetime).format(dateFormat);

  // generate url
  const uri = new URL('https://www.google.com/calendar/render');
  const searchParams: any = {
    action: 'TEMPLATE',
    text: eventName,
    details: `<b>${eventName}</b>\n\n${eventLink}`,
    dates: start + '/' + end,
  };

  if (event.event_repeat_type === 'daily') {
    searchParams.recur = `RRULE:FREQ=DAILY;UNTIL=${eventEndDate};INTERVAL=${event.event_daily_repeat};`;
  } else if (event.event_repeat_type === 'weekly') {
    const byDayDays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    const byDay = event.event_weekly_repeat
      .split(',')
      .map(dayIndex => byDayDays[dayIndex])
      .join(',');

    searchParams.recur = `RRULE:FREQ=WEEKLY;UNTIL=${eventEndDate};BYDAY=${byDay};`;
  }

  Object.keys(searchParams).map(function (key) {
    uri.searchParams.append(key, searchParams[key]);
  });

  return uri.href;
}

export {
  generateCalendarWeeks,
  generateCalendarEvents,
  groupCalendarEventsByDay,
  groupCalendarEventsByWeek,
  canShowMoreEvents,
  generateAllCalendarEvents,
  formattedEvent,
  generateCalendarDays,
  getAddToGoogleCalendarLink,
  isSameDay,
};
