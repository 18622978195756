<template>
  <div>
    <HMModalHeader :title="title" :closable="closable" @close="close" />
    <h1 v-if="subtitle" class="mb-4">
      {{ subtitle }}
    </h1>
    <p v-if="text" class="mb-4">
      {{ text }}
    </p>
    <p v-if="htmlContent" class="mb-4" v-html="htmlContent"></p>
    <form @submit.prevent="onConfirm">
      <HMModalFooter
        :submitButton="confirmButton"
        :loading="inProgress"
        :disabled="inProgress"
        :discardButton="discardButton"
        @close="onDiscard"
      />
    </form>
  </div>
</template>

<script>
import i18n from '@/utils/localization';

export default {
  name: 'HMDialog',
  props: {
    title: {
      type: String,
      default() {
        return i18n.t('GENERAL.TITLE.CONFIRM');
      },
    },
    subtitle: {
      type: String,
    },
    text: {
      type: String,
      default: '',
    },
    htmlContent: {
      type: String,
      default: '',
    },
    confirmButton: {
      type: String,
      default() {
        return i18n.t('GENERAL.BUTTON.CONFIRM');
      },
    },
    discardButton: {
      type: String,
      default() {
        return i18n.t('GENERAL.BUTTON.GO_BACK');
      },
    },
    confirm: null,
    discard: null,
    onClose: null,
    closable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inProgress: false,
    };
  },
  methods: {
    async onConfirm() {
      this.inProgress = true;
      await this.confirm();
      this.inProgress = false;
      this.$attrs._close()
    },
    async onDiscard() {
      if (this.discard) {
        this.inProgress = true;
        await this.discard();
        this.inProgress = false;
      }
      this.$attrs._close()
    },
    close() {
      if (this.onClose) this.onClose();
      this.$attrs._close()
    },
  },
};
</script>

<style></style>
