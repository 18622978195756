export const LESSON_START_TYPE_AFTER_PURCHASE = 'after_purchase';
export const LESSON_START_TYPE_AFTER_PREVIOUS_LESSON = 'after_previous_lesson';
export const LESSON_START_TYPE_AFTER_SPECIFIC_DAYS = 'after_specific_days';
export const LESSON_START_TYPE_AFTER_SPECIFIC_DATE = 'after_specific_date';
import LessonType from '@/utils/typings/enums/LessonType';

export function mediaTypes() {
  return [
    { value: 'other', displayCode: 'GENERAL.LABEL.DOCUMENTS' },
    { value: 'video', displayCode: 'GENERAL.LABEL.VIDEO' },
    { value: 'audio', displayCode: 'GENERAL.LABEL.AUDIO' },
  ];
}

export function getProductTypeTranslations(productType) {
  switch (productType) {
    case 'private_space':
      return 'PRODUCTS.LABELS.PRIVATE_SPACE';
    case 'course':
      return 'PRODUCTS.LABELS.COURSE';
    case 'media_library':
      return 'PRODUCTS.LABELS.ON_DEMAND_LIBRARY';
    case 'bundle':
      return 'PRODUCTS.LABELS.BUNDLE';
    case 'punch_card':
      return 'PRODUCTS.LABELS.CLASSCARD';
    case 'event_only':
      return 'PRODUCTS.LABELS.INPERSONONLY';
    case 'private_coaching':
      return 'PRODUCTS.LABELS.PRIVATE_COACHING';
  }
  return 'PRODUCTS.LABELS.ON_DEMAND_LIBRARY';
}

export function getLessonType(lesson) {
  if (lesson?.is_quiz_lesson) {
    return LessonType.QUIZ;
  } else if (!lesson?.is_quiz_lesson && lesson?.content?.length !== 0) {
    return LessonType.MEDIA;
  } else if (!lesson?.is_quiz_lesson && lesson?.content?.length === 0) {
    return LessonType.TEXT;
  }
}

export function getDefaultAvailability() {
  return {
    timezone: 'Europe/Berlin',
    rules: {
      sunday: {
        enabled: false,
        intervals: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
      },
      monday: {
        enabled: true,
        intervals: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
      },
      tuesday: {
        enabled: true,
        intervals: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
      },
      wednesday: {
        enabled: true,
        intervals: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
      },
      thursday: {
        enabled: true,
        intervals: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
      },
      friday: {
        enabled: true,
        intervals: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
      },
      saturday: {
        enabled: false,
        intervals: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
      },
    },
  };
}

export function parseDuration(duration) {
  // Regular expressions to match different duration formats
  const hourMinuteRegex = /(\d+)\s*(?:h|hr|hrs)(?:\s*(\d+)\s*(?:min|m)?)?/i;
  const minuteOnlyRegex = /^(\d+)\s*(?:min|m)?$/i;

  // Initialize variables to hold the parsed values
  let hours = 0;
  let minutes = 0;

  // Check if the duration matches the hour and minute format
  const hourMinuteMatch = duration.match(hourMinuteRegex);
  if (hourMinuteMatch) {
    if (hourMinuteMatch[1]) {
      hours = parseInt(hourMinuteMatch[1], 10);
    }
    if (hourMinuteMatch[2]) {
      minutes = parseInt(hourMinuteMatch[2], 10);
    }
  } else {
    // Check if the duration matches the minute-only format
    const minuteOnlyMatch = duration.match(minuteOnlyRegex);
    if (minuteOnlyMatch) {
      minutes = parseInt(minuteOnlyMatch[1], 10);
    }
  }

  // Calculate the total duration in minutes
  const totalMinutes = hours * 60 + minutes;

  return totalMinutes;
}

export function getRecurringOptions() {
  return {
    annually: {
      order: 0,
      translation: 'PRODUCTS.LABELS.ANNUAL_SUBSCRIPTION',
      priceTranslation: 'PRODUCTS.LABELS.PRICE_PER_YEAR',
      label: 'year',
      payPalPeriod: 'Y',
      billingInterval: 1,
    },
    biannually: {
      order: 1,
      translation: 'PRODUCTS.LABELS.BIANNUAL_SUBSCRIPTION',
      priceTranslation: 'PRODUCTS.LABELS.PRICE_PER_BIANNUAL',
      label: 'quarter',
      payPalPeriod: 'M',
      billingInterval: 6,
    },
    quarterly: {
      order: 2,
      translation: 'PRODUCTS.LABELS.QUARTERLY_SUBSCRIPTION',
      priceTranslation: 'PRODUCTS.LABELS.PRICE_PER_QUARTER',
      label: 'quarter',
      payPalPeriod: 'M',
      billingInterval: 3,
    },
    monthly: {
      order: 3,
      translation: 'PRODUCTS.LABELS.MONTHLY_SUBSCRIPTION',
      priceTranslation: 'PRODUCTS.LABELS.PRICE_PER_MONTH',
      label: 'month',
      payPalPeriod: 'M',
      billingInterval: 1,
    },
    weekly: {
      order: 4,
      translation: 'PRODUCTS.LABELS.WEEKLY_SUBSCRIPTION',
      priceTranslation: 'PRODUCTS.LABELS.PRICE_PER_WEEK',
      label: 'week',
      payPalPeriod: 'W',
      billingInterval: 1,
    },
    daily: {
      order: 5,
      translation: 'PRODUCTS.LABELS.DAILY_SUBSCRIPTION',
      priceTranslation: 'PRODUCTS.LABELS.PRICE_PER_DAY',
      label: 'day',
      payPalPeriod: 'D',
      billingInterval: 1,
    },
  };
}