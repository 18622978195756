import type { AxiosInstance } from 'axios';
let api: AxiosInstance;

function setApi(apiInstance: AxiosInstance) {
  api = apiInstance;
}

export {
  api,
  setApi,
};
