// import { connect } from 'getstream';
import { useCommunityStore } from '@/stores';
import { StreamChat } from 'stream-chat';

export async function streamChatLoaded() {
  return new Promise((resolve, reject) => {
    const interval = setInterval(
      function () {
        if (window.StreamChat) {
          resolve(window.StreamChat);
          clearInterval(interval);
        }
      },
      200,
      50
    );
  });
}

export async function connectChatUser(id, name, image, chatToken) {
  window.StreamChat = StreamChat;
  const chatClient = StreamChat.getInstance(
    // @ts-ignore
    process.env.VUE_APP_STREAM_CHAT_API_KEY
  );
  useCommunityStore().disconnectChatClient()
  useCommunityStore().communityChatClient({ chatClient })

  const member = await chatClient.connectUser({ id, name, image }, chatToken);
  // @ts-ignore
  useCommunityStore().setNumberOfChatNotifications(member.me.unread_channels)

  chatClient.on(event => {
    // console.log(event)
    // if (event.total_unread_count !== undefined) {
    //   console.log(event.total_unread_count);
    // }
    if (event.unread_channels !== undefined) {
      useCommunityStore().setNumberOfChatNotifications(event.unread_channels)
    }
  });
}

export async function subscribeStudioToCommunityNotifications(
  token,
  communityProfileId,
  cb = data => {}
) {
  console.log('subscribeStudioToCommunityNotifications -> token:', token);
  console.log(
    'subscribeStudioToCommunityNotifications -> communityProfileId:',
    communityProfileId
  );
  // @ts-ignore
  const apiKey: string = process.env.VUE_APP_STREAM_CHAT_API_KEY;
  // @ts-ignore
  const appId: string = process.env.VUE_APP_STREAM_APP_ID;
  // console.log('stream connection apiKey = ', apiKey);
  // console.log('stream connection appId = ', appId);
  const { connect } = await import('getstream');
  const client = connect(apiKey, null, appId);
  const notificationFeed = client.feed(
    'notification',
    communityProfileId,
    token
  );

  function successCallback() {
    console.log('Studio community notifications listener successfuly set up');
  }

  function failCallback(data) {
    console.log(
      'Failed to set up studio community notifications listener: ',
      data
    );
  }

  notificationFeed.subscribe(cb).then(successCallback, failCallback);
}

export async function subscribeToCommunityNotifications(
  token,
  communityProfileId,
  cb = data => {}
) {
  console.log('subscribeToCommunityNotifications -> token:', token);
  console.log(
    'subscribeToCommunityNotifications -> communityProfileId:',
    communityProfileId
  );
  // @ts-ignore
  const apiKey: string = process.env.VUE_APP_STREAM_CHAT_API_KEY;
  // @ts-ignore
  const appId: string = process.env.VUE_APP_STREAM_APP_ID;
  // console.log('stream connection apiKey = ', apiKey);
  // console.log('stream connection appId = ', appId);
  const { connect } = await import('getstream');
  const client = connect(apiKey, null, appId);
  const notificationFeed = client.feed(
    'notification',
    communityProfileId,
    token
  );

  function successCallback() {
    console.log(
      'Community notifications listener successfuly set up for ' +
        communityProfileId
    );
  }

  function failCallback(data) {
    console.log(
      'Failed to set up studio community notifications listener for ' +
        communityProfileId +
        ': ',
      data
    );
  }

  notificationFeed.subscribe(cb).then(successCallback, failCallback);
}
