<template>
  <div class="form-group hm-modal-footer">
    <div class="submit-group">
      <slot name="close">
        <HMButton color="outline-gray" @click="$emit('close')">
          {{ discardButton }}
        </HMButton>
      </slot>
      <slot name="submit">
        <HMButton
          v-if="submitButton"
          type="submit"
          color="brand"
          :disabled="disabled"
          :loading="loading"
        >
          {{ submitButton }}
        </HMButton>
      </slot>
    </div>
    <span class="invalid-feedback d-block" v-show="formError">
      {{ formError }}
    </span>
  </div>
</template>

<script>
import i18n from '@/utils/localization';

export default {
  name: 'HMModalFooter',
  props: {
    submitButton: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    formError: {
      type: String,
      default: null,
    },
    discardButton: {
      type: String,
      default: i18n.t('GENERAL.BUTTON.CANCEL'),
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-group {
  padding-top: 1rem;
  margin: 0;
}
</style>
