
/* HAVE TO BE AFTER MODAL PLUGIN */
import HMDialog from '~/components/common/HMDialog.vue';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('confirm', {
    mounted(el: HTMLElement, binding: any) {
      el.addEventListener('click', (event) => {
        event.stopPropagation()
        const { title, subtitle, text, confirmButton, confirm } = binding.value
        nuxtApp.$modal?.show(HMDialog, {
          title,
          subtitle,
          text,
          confirmButton,
          confirm,
        });
      })
    },
  })
})