import { useRouter } from 'vue-router';
import apiStudio from '~/api/studios';
import HOSTS_WITH_STUDIO_URL_IN_PATH from '~/utils/studio-host';

const isSubdomainsServer = process.env.VUE_APP_IS_SUBDOMAINS_SERVER === 'true';

function isDomainWithStudioInUrl(hostname: string) {
  return HOSTS_WITH_STUDIO_URL_IN_PATH.includes(hostname.toLowerCase());
}

const domainMap = {};
async function resolveStudioUrl(hostname: string, currentRoute: any) {
  if (isDomainWithStudioInUrl(hostname)) {
    return currentRoute.value.params.studio_url as string;
  } else {
    try {
      if (isSubdomainsServer) {
        const studioUrl = hostname.split('.')[0];
        console.log('matched subdomain: ', studioUrl);
        return studioUrl;
      } else {
        if (domainMap[hostname]) {
          console.log('matched cached custom domain: ', domainMap[hostname]);
          return domainMap[hostname];
        }
        const studioUrl = await apiStudio.getStudioUrlForCustomDomain(hostname);
        domainMap[hostname] = studioUrl;
        console.log('matched custom domain: ', studioUrl);
        return studioUrl;
      }
    } catch (error) {
      return undefined;
    }
  }
}


export default defineNuxtPlugin(async () => {
  const url = useRequestURL();
  const router = useRouter();
  const currentRoute = router.currentRoute;

  return {
    provide: {
      studioURL: await resolveStudioUrl(url.hostname, currentRoute)
    }
  }
})
  