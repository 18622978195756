import { mapState } from 'pinia';
import { useUserStore } from '@/stores';

const refreshData = {
  // computed: {
  //   ...mapState(useUserStore, {
  //     accessToken: 'accessToken',
  //   }),
  // },
  // watch: {
  //   accessToken() {
  //     this['loadData']();
  //   },
  // },
  mounted() {
    const accessToken = useCookie('accessToken');

    watch(accessToken, (newToken, oldToken) => {
      this['loadData']();
    });
  },
};

export default refreshData;