const templates = [
  {
    component: 'StudioHomeTemplateProductBanner',
    id: 'product_banner',
    name: 'Product Banner',
    description: 'Features full banner product cards.',
    image: 'https://dv05ui3l6dkej.cloudfront.net/TemplateProductBanner.png',
    config: {
      borderRadius: false,
      buttonColor: 'brand',
      studioBackgroundColorClass: 'bg-brand-color-01',
      hideFooterDivider: true,
      studioTopPadding: false,
    },
  },
  {
    component: 'StudioHomeTemplateBrandColor',
    id: 'brand_color',
    name: 'Brand Color',
    description: 'Features background brand color and modern elements.',
    image: 'https://dv05ui3l6dkej.cloudfront.net/TemplateBrandColor.png',
    config: {
      borderRadius: false,
      buttonColor: 'brand',
      studioBackgroundColorClass: 'bg-brand-color',
      hideFooterDivider: true,
      studioTopPadding: true,
    },
  },
  {
    component: 'StudioHomeTemplateCards',
    id: 'cards',
    name: 'Cards',
    description: 'Features card blocks for each section.',
    image: 'https://dv05ui3l6dkej.cloudfront.net/TemplateCards.png',
    config: {
      borderRadius: true,
      buttonColor: 'brand',
      studioBackgroundColorClass: 'bg-brand-color-01',
      hideFooterDivider: true,
      studioTopPadding: true,
    },
  },
  {
    component: 'StudioHomeTemplateModern',
    id: 'modern',
    name: 'Modern',
    description: 'Features full-bleed images.',
    image: 'https://dv05ui3l6dkej.cloudfront.net/TemplateModern.png',
    config: {
      borderRadius: false,
      buttonColor: 'brand',
      hideFooterDivider: true,
      studioTopPadding: true,
    },
  },
  {
    component: 'StudioHomeTemplateClassic',
    id: 'classic',
    name: 'Classic',
    description: 'Features rounded corners and classic sections.',
    image: 'https://dv05ui3l6dkej.cloudfront.net/TemplateClassic.png',
    config: {
      borderRadius: true,
      buttonColor: 'outline-gray',
      studioTopPadding: true,
    },
  },
];

export default templates;
