import HMToast from '@/components/common/HMToast.vue';

export function notificationToToast(data) {
  let timeout;
  if (data.duration === -1) {
    timeout = false;
  } else if (!data.duration) {
    timeout = 3000;
  } else {
    timeout = data.duration;
  }

  const icons = {
    'success': 'fas fa-check',
    'error': 'fas fa-exclamation',
  };

  return {
    content: {
      component: HMToast,
      props: {
        title: data.title,
        text: data.text,
      },
    },
    config: {
      timeout,
      position: "bottom-left",
      type: data.type,
      icon: icons[data.type],
    }
  };
}
