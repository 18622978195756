import { notificationToToast } from '@/utils/notify';
import { useToast } from "vue-toastification";

import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(Toast);

    nuxtApp.provide('notify', function(data) {
        const { content, config } = notificationToToast(data);
        const toast = useToast();

        toast(content, config);
    });
})