import { useWebsocketStore } from '@/stores';

let socket;

function listener(event: MessageEvent) {
  if (!event.data) return;
  const { event_type, payload } = JSON.parse(event.data);
  if (!event_type) return;

  const store = useWebsocketStore();
  store.eventReceived({ event_type, payload });
}

export const initSocket = () => {
  const wsUrl = process.env.VUE_APP_WEBSOCKET_URL;
  if (!wsUrl) {
    console.error('Websocket URL is not defined');
    return;
  }
  socket = new WebSocket(wsUrl);

  socket.addEventListener('message', listener);
};