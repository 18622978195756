import { defineNuxtRouteMiddleware, navigateTo, useRequestHeaders } from '#app'

export default defineNuxtRouteMiddleware((to) => {
  try {
    const headers = useRequestHeaders(['host'])
    const hostname = headers.host || ''
    const hostParts = hostname.split('.')

    // Check if we're on a subdomain (assuming at least 3 parts in the hostname)
    if (hostParts.length >= 3) {
      const subdomain = hostParts[0]
      
      // Handle case where path is '/' or empty
      if (to.path === '/' || to.path === '') {
        return;
      }

      // Get the first path segment
      const firstPathSegment = to.path.split('/')[1]

      // Check if subdomain matches the first path segment
      if (subdomain.toLowerCase() === firstPathSegment.toLowerCase()) {
        // Construct the new path by removing the first segment
        const newPath = '/' + to.path.split('/').slice(2).join('/')
        
        // Construct the new URL
        const newURL = `//${hostname}${newPath}`

        // Redirect to the new URL
        return navigateTo(newURL, { redirectCode: 301, external: true })
      }
    }
  } catch (e) {
    console.error('Error in subdomain redirect middleware:', e)
  }
})