import { defineStore } from 'pinia'

interface State {
  event: any;
}

export const useWebsocketStore = defineStore('websocket', {
  state: (): State => ({
    event: null,
  }),
  getters: {
  },
  actions: {
    eventReceived(event: any) {
      this.event = event;
    }
  },
});