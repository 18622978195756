import api from '~/api/students-solved-quizzes';
import { defineStore } from 'pinia';

export const useStudentQuizStore = defineStore('studentQuiz', {
  state: () => {
    return ({})
  },
  actions: {
    async getQuiz({ studioURL, quizId }): Promise<SimpleQuizResponse> {
      const quiz = await api.getQuiz(studioURL, quizId);
      return quiz;
    },
    async viewQuiz({ studioURL, quizId }): Promise<void> {
      await api.viewQuiz(studioURL, quizId);
    },
    async getSolvedQuizzes({ studioURL }): Promise<StudentSolvedQuizResponse> {
      const solvedQuizzes = await api.getSolvedQuizzes(studioURL);
      return solvedQuizzes;
    },
    async getSolvedQuiz({ studioURL, solvedQuizId }): Promise<StudentSolvedQuizResponse> {
      const solvedQuiz = await api.getSolvedQuiz(studioURL, solvedQuizId);
      return solvedQuiz;
    },
    async startQuiz({ studioURL, quizId }): Promise<StudentSolvedQuizResponse> {
      const solvedQuiz = await api.startQuiz(studioURL, quizId);
      return solvedQuiz;
    },
    async retakeQuiz({ studioURL, quizId }): Promise<void> {
      return await api.retakeQuiz(studioURL, quizId);
    },
    async finishQuiz({ studioURL, solvedQuizId }): Promise<void> {
      return await api.finishQuiz(studioURL, solvedQuizId);
    },
    async answerQuizQuestion({ studioURL, solvedQuizId, data }): Promise<void> {
      return await api.answerQuizQuestion(studioURL, solvedQuizId, data);
    },
    async addSolvedQuizAnswerFile({ studioURL, solvedQuizId, solvedQuizAnswerId, fileId }): Promise<void> {
      return await api.addSolvedQuizAnswerFile(
        studioURL,
        solvedQuizId,
        solvedQuizAnswerId,
        fileId
      );
    },
  },
});