import { useUserStore, useGlobalsStore, useStudioStore } from "@/stores";
import type { RouteLocationRaw } from "vue-router";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore();
  const studioStore = useStudioStore();
  const nuxtApp = useNuxtApp();
  const studioURL = nuxtApp.$studioURL;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const accessToken = useCookie('accessToken');

  if(to.name === 'studio-logout') {
    await userStore.logout();
    await userStore.$persist();
    return navigateTo({ name: 'studio-home-page' })
  }

  // handle magic_link code
  const magicCode = to.query.magic_code;
  if(process.server) {
    if (magicCode) {
      try {
        const { key } = await userStore.loginWithMagicCode(magicCode);

        const accessToken = useCookie('accessToken', {
          maxAge: 60 * 60 * 24 * 365,
        });
        accessToken.value = key;
        refreshCookie('accessToken');

        const nextQuery = { ...to.query };
        delete nextQuery.magic_code;

        const next = {
          name: to.name,
          params: to.params,
          query: nextQuery,
        } as RouteLocationRaw;
        
        return navigateTo(next);
      } catch (e) {
        console.error('magic code is invalid or expired!');
      }
    }
  }
  // let loadUserInfoPromise = store.state.user.userInfoPromise;

  // // if this route requires auth

  if (requiresAuth) {
  //   // if access token
    let accessTokenValue = accessToken.value;
    if (accessTokenValue) {
      return;
  //     // if loadUserInfo didn't yet started
  //     if (!loadUserInfoPromise) {
  //       loadUserInfoPromise = await store.dispatch('user/loadUserInfo');
  //     }
  //     await loadUserInfoPromise;
  //     const lastStudioUrl = store.getters['user/studentsLastStudioUrl'];
  //     const studioURL = getStudioURL(from) || lastStudioUrl;
  //     const role = store.getters['user/role'];
  //     if (role === 'teacher' || isStudioPage) next();
  //     // proceed if possible
  //     else next({ name: 'studio-home', params: { studio_url: studioURL } }); // redirect if user doesn't have access
    } else {
  //     // redirect if no access token
  //     if (isStudioPage) {
         useGlobalsStore().setShowLoginModal(true);
         return;
  //       await store.dispatch('globals/setShowLoginModal', true);
  //       next();
  //       // next({
  //       //   name: 'studio-home',
  //       //   params: { studio_url: getStudioURL(to) },
  //       // });
  //     } else {
  //       next({
  //         name: 'admin-login',
  //         query: {
  //           ...to.query,
  //           redirect: to.fullPath,
  //         },
  //       });
  //     }
    }
  } else return;// if this route doesn't required auth

})