import { defineStore } from 'pinia';
import api from '@/api/affiliates';
import _ from 'lodash-es';

interface State {
  affiliateCoupon: string | null;
}

export const useAffiliateStore = defineStore('affiliates', {
  state: ():State => ({
    affiliateCoupon: null,
  }),
  getters: {
  },
  actions: {
    async trackAffiliateLinkClick({ code, valid }) {
      return await api.trackAffiliateLinkClick(code, valid);
    },
    async setAffiliateCoupon(coupon: AffiliateCouponResponse) {
      this.affiliateCoupon = coupon;
    },
    async getAffiliateStats(_) {
      return await api.getAffiliateStats();
    },
  },
  // mutations: {
  //   [types.SET_AFFILIATE_COUPON](state: State, coupon: string) {
  //     state.affiliateCoupon = coupon;
  //   },
  // },
});
