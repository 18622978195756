// https://stackoverflow.com/a/4819886/947531
export function isTouchDevice() {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if (
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof window.DocumentTouch)
  ) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
    ''
  );
  return mq(query);
}

export function isMobile() {
  const ret = {} as any;
  const ua = window.navigator.userAgent;
  ret.Android = !!ua.match(/Android/i);
  ret.BlackBerry = !!ua.match(/BlackBerry/i);
  ret.iPhone = !!ua.match(/iPhone|iPod/i);
  ret.iPad = !!ua.match(/iPad/i);
  ret.iOS = ret.iPhone || ret.iPad;
  ret.Opera = !!ua.match(/Opera Mini/i);
  ret.Webkit = !!ua.match(/WebKit/i);
  ret.Windows = !!ua.match(/IEMobile/i);
  ret.iOSSafari = ret.iOS && ret.Webkit && !ua.match(/CriOS/i);
  ret.any =
    ret.Android || ret.BlackBerry || ret.iOS || ret.Opera || ret.Windows;
  return ret;
}

/*
isInWebAppiOS = (window.navigator.standalone == true);
isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);
*/
export const isStandalone = () => {
  const webappsProtocols = ['file:', 'cordova:', 'capacitor:'];
  return (
    window.matchMedia('(display-mode: standalone)').matches ||
    (window.location &&
      window.location.protocol &&
      webappsProtocols.indexOf(window.location.protocol) !== -1)
  );
};

export let deferedAddToHomescreen;
export function setDeferedAddToHomescreen(e) {
  e.preventDefault();
  deferedAddToHomescreen = e;
}
