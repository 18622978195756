import { api } from './api';

// Student requests
const STUDIOS_BASE_URL = 'api/studios';

async function getStudentPlaylists(
  studioURL: string,
  productId: number
): Promise<PlaylistResponse[]> {
  const result = await api.get<PlaylistResponse[]>(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/playlists`
  );
  return result.data;
}

async function getStudentPlaylist(
  studioURL: string,
  productId: number,
  playlistId: number
): Promise<PlaylistResponse> {
  const result = await api.get<PlaylistResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/playlists/${playlistId}`
  );
  return result.data;
}

async function getStudentProductsReviews(
  studioURL: string,
  productId: number,
  page: number | null
): Promise<StudioProductReviewResponse[]> {
  const data = {
    page,
  };
  const result = await api.get<StudioProductReviewResponse[]>(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/reviews`,
    { params: data }
  );
  return result.data;
}

async function studentSubmitReview(
  studioURL: string,
  productId: number,
  review: string,
  rating: number
): Promise<ProductReviewResponse> {
  const data = {
    review,
    rating,
  };
  const result = await api.post<ProductReviewResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/review`,
    data
  );
  return result.data;
}

async function getProductDetail(
  studioURL: string,
  productId: number
): Promise<ProductDetailResponse> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}`;
  const response = await api.get<ProductDetailResponse>(url);
  return response.data;
}

async function getProductBasic(
  studioURL: string,
  productId: number
): Promise<ProductDetailResponse> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/basic`;
  const response = await api.get<ProductDetailResponse>(url);
  return response.data;
}

async function getProductForPurchase(
  studioURL: string,
  productId: number
): Promise<ProductDetailResponse> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/purchase`;
  const response = await api.get<ProductDetailResponse>(url);
  return response.data;
}

async function getProductMedia(
  studioURL: string,
  productId: number,
  options?: number[],
  instructor?: number,
  mediaType?: string,
  q?: string,
  nextPage?: string
): Promise<PaginatedResponse<ProductMediaResponse>> {
  let url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/media?`;

  if (mediaType) url += `file_type=${mediaType}&`;
  if (instructor) url += `instructor=${instructor}&`;
  if (options && options.length > 0) url += `options=${options.join(',')}&`;
  if (q) url += `q=${q}`;

  const response = await api.get<PaginatedResponse<ProductMediaResponse>>(
    nextPage || url
  );
  return response.data;
}

async function getProductMediaPaginated(
  studioURL: string,
  productId: number,
  options?: number[],
  instructor?: number,
  mediaType?: string,
  q?: string,
  page?: number
): Promise<PaginatedResponse<ProductMediaResponse>> {
  let url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/media?`;

  if (page) url += `page=${page}&`;
  if (mediaType) url += `file_type=${mediaType}&`;
  if (instructor) url += `instructor=${instructor}&`;
  if (options && options.length > 0) url += `options=${options.join(',')}&`;
  if (q) url += `q=${q}`;

  const response = await api.get<PaginatedResponse<ProductMediaResponse>>(url);
  return response.data;
}

async function getProductMediaDetail(
  studioURL: string,
  productId: number,
  mediaId: number
): Promise<MediaDetailResponse> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/media/${mediaId}`;
  const response = await api.get<MediaDetailResponse>(url);
  return response.data;
}

async function getProductPlaylistMediaDetail(
  studioURL: string,
  productId: number,
  playlistId: number,
  mediaId: number
): Promise<MediaDetailResponse> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/playlists/${playlistId}/media/${mediaId}`;
  const response = await api.get<MediaDetailResponse>(url);
  return response.data;
}

async function trackProductView(
  studioURL: string,
  productId: number
): Promise<void> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/view`;
  await api.post(url, { id: productId });
}

async function getProductLesson(
  studioURL: string,
  productId: number,
  lessonId: number
): Promise<string> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/lessons/${lessonId}`;
  const response = await api.get(url);
  return response.data;
}

async function getProductLessons(
  studioURL: string,
  productId: number
): Promise<string> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/lessons`;
  const response = await api.get(url);
  return response.data;
}

async function checkLessonAsCompleted(
  studioURL: string,
  productId: number,
  lessonId: number
): Promise<string> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/lessons/${lessonId}/mark_completed`;
  const response = await api.post(url, { id: lessonId });
  return response.data;
}

async function checkLessonAsIncompleted(
  studioURL: string,
  productId: number,
  lessonId: number
): Promise<string> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/lessons/${lessonId}/mark_uncompleted`;
  const response = await api.post(url, { id: lessonId });
  return response.data;
}

async function likeMedia(
  studioURL: string,
  productId: number,
  mediaId: number
): Promise<boolean> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/media/${mediaId}/like`;
  const response = await api.post<boolean>(url, { id: mediaId });
  return response.data;
}

async function getFilterCategories(
  studioURL: string
): Promise<FilterCategoryResponse[]> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/categories`;
  const response = await api.get<FilterCategoryResponse[]>(url);
  return response.data;
}

async function getStudioInstructors(
  studioURL: string
): Promise<InstructorResponse[]> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/instructors`;
  const response = await api.get<InstructorResponse[]>(url);
  return response.data;
}

async function getStudioInstructor(
  studioURL: string,
  instructorId: number
): Promise<InstructorResponse[]> {
  const url = `${STUDIOS_BASE_URL}/${studioURL}/instructors/${instructorId}`;
  const response = await api.get<InstructorResponse[]>(url);
  return response.data;
}

// Teacher requests
const PRODUCTS_BASE_URL = 'api/products';
const COUPONS_BASE_URL = 'api/coupons-paginated';
const TAX_RATES_BASE_URL = 'api/tax-rates';
const PRODUCT_TAGS_BASE_URL = 'api/product-tags';

async function getTeacherProducts(): Promise<ProductResponse[]> {
  const result = await api.get<ProductResponse[]>(PRODUCTS_BASE_URL);
  return result.data;
}

async function getTeacherProductsWithPrivateSessions(): Promise<
  ProductResponse[]
> {
  const result = await api.get<ProductResponse[]>(
    `${PRODUCTS_BASE_URL}/with_private_sessions`
  );
  return result.data;
}

async function getTeacherProductDetail(id: number): Promise<ProductResponse> {
  const result = await api.get<ProductResponse>(`${PRODUCTS_BASE_URL}/${id}`);
  return result.data;
}

async function createProduct(
  data: CreateProductRequest
): Promise<ProductResponse> {
  const result = await api.post<ProductResponse>(PRODUCTS_BASE_URL, data);
  return result.data;
}

async function updateProduct(
  data: UpdateProductRequest
): Promise<ProductResponse> {
  const result = await api.put<ProductResponse>(
    `${PRODUCTS_BASE_URL}/${data.id}`,
    data
  );
  return result.data;
}

async function deleteProduct(id: number): Promise<void> {
  await api.delete(`${PRODUCTS_BASE_URL}/${id}`);
}

async function duplicateProduct(id: number): Promise<void> {
  await api.post(`${PRODUCTS_BASE_URL}/${id}/duplicate`);
}

async function getPendingContracts(studioURL: string, productId: number) {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/pending_contracts`
  );
  return result.data;
}

async function getProductGifts(
  id: number,
  page = 1,
  q: string | null,
  startDate: Date | null,
  endDate: Date | null,
  status: string | null
): Promise<void> {
  let url = `${PRODUCTS_BASE_URL}/${id}/gifts?page=${page}`;
  if (q) url += `&q=${encodeURIComponent(q)}`;
  if (startDate) {
    url += `&date_from=${startDate.toISOString()}`;
  }
  if (endDate) {
    url += `&date_to=${endDate.toISOString()}`;
  }
  if (status) {
    url += `&status=${status}`;
  }

  const result = await api.get(url);
  return result.data;
}

async function getWaitlistEmails(product_id: number, page = 1): Promise<void> {
  const url = `${PRODUCTS_BASE_URL}/${product_id}/waitlist-emails?page=${page}`;

  const result = await api.get(url);
  return result.data;
}

async function exportWaitlistEmails(product_id) {
  const result = await api.get(
    `${PRODUCTS_BASE_URL}/${product_id}/waitlist-emails/export_to_csv`
  );
  return result.data;
}

async function clearWaitlistEmails(product_id) {
  const result = await api.post(
    `${PRODUCTS_BASE_URL}/${product_id}/waitlist-emails/clear_waitlist`
  );
  return result.data;
}

async function addToWaitlist(studioURL, product_id, email) {
  const result = await api.post<void>(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${product_id}/add_to_waitlist`,
    { email }
  );
  return result.data;
}

async function getTeacherTaxRates(): Promise<TeacherTaxRateResponse[]> {
  const result = await api.get<TeacherTaxRateResponse[]>(
    `${TAX_RATES_BASE_URL}`
  );
  return result.data;
}

async function addTeacherTaxRate(
  taxRate: TeacherTaxRateResponse
): Promise<TeacherTaxRateResponse> {
  const result = await api.post<TeacherTaxRateResponse>(
    `${TAX_RATES_BASE_URL}`,
    taxRate
  );
  return result.data;
}

async function updateTeacherTaxRate(
  taxRate: TeacherTaxRateResponse
): Promise<TeacherTaxRateResponse> {
  const result = await api.put<TeacherTaxRateResponse>(
    `${TAX_RATES_BASE_URL}/${taxRate.id}`,
    taxRate
  );
  return result.data;
}

async function getProductTags(): Promise<TeacherProductTagResponse[]> {
  const result = await api.get<TeacherProductTagResponse[]>(
    `${PRODUCT_TAGS_BASE_URL}`
  );
  return result.data;
}

async function addProductTag(
  tag: TeacherProductTagResponse
): Promise<TeacherProductTagResponse> {
  const result = await api.post<TeacherProductTagResponse>(
    `${PRODUCT_TAGS_BASE_URL}`,
    tag
  );
  return result.data;
}

async function updateProductTag(
  tag: TeacherProductTagResponse
): Promise<TeacherProductTagResponse> {
  const result = await api.put<TeacherProductTagResponse>(
    `${PRODUCT_TAGS_BASE_URL}/${tag.id}`,
    tag
  );
  return result.data;
}

async function deleteProductTag(productTagId: number): Promise<void> {
  const result = await api.delete(`${PRODUCT_TAGS_BASE_URL}/${productTagId}`);
  return result.data;
}

async function getTeacherCoupons(
  page = 1,
  q: string,
  is_active: boolean | null
): Promise<PaginatedResponse<TeacherCouponsResponse>> {
  let url = `${COUPONS_BASE_URL}?page=${page}&q=${encodeURIComponent(q)}`;
  if (is_active !== null) {
    url = `${url}&is_active=${is_active}`;
  }
  const result = await api.get<PaginatedResponse<TeacherCouponsResponse>>(url);
  return result.data;
}

async function getTeacherCouponDetail(
  id: number
): Promise<TeacherCouponsResponse> {
  const result = await api.get<TeacherCouponsResponse>(
    `${COUPONS_BASE_URL}/${id}`
  );
  return result.data;
}

async function getTeacherCouponsStats(): Promise<CouponsStatsResponse> {
  const result = await api.get<CouponsStatsResponse>(
    `${COUPONS_BASE_URL}/stats`
  );
  return result.data;
}

async function createCoupon(
  data: CreateCouponRequest
): Promise<TeacherCouponsResponse> {
  const result = await api.post<TeacherCouponsResponse>(COUPONS_BASE_URL, data);
  return result.data;
}

async function updateCoupon(
  data: UpdateCouponRequest
): Promise<TeacherCouponsResponse> {
  const result = await api.put<TeacherCouponsResponse>(
    `${COUPONS_BASE_URL}/${data.id}`,
    data
  );
  return result.data;
}

async function deleteCoupon(id: number): Promise<void> {
  await api.delete(`${COUPONS_BASE_URL}/${id}`);
}

async function getCouponsUsage(id: number): Promise<CouponUsageResponse[]> {
  const result = await api.get<CouponUsageResponse[]>(
    `${COUPONS_BASE_URL}/${id}/usage`
  );
  return result.data;
}

async function getProductPurchases(
  id: number,
  page = 1,
  q: string,
  dateFrom?: Date,
  dateTo?: Date
): Promise<PaginatedResponse<ProductStudentCustomer>> {
  const result = await api.get<PaginatedResponse<ProductStudentCustomer>>(
    `${PRODUCTS_BASE_URL}/${id}/payments?page=${page}&q=${encodeURIComponent(
      q
    )}&date_from=${dateFrom?.toISOString() ?? ''}&date_to=${
      dateTo?.toISOString() ?? ''
    }`
  );
  return result.data;
}

async function getProductCustomers(
  id: number,
  page = 1,
  q: string,
  dateFrom?: Date,
  dateTo?: Date
): Promise<PaginatedResponse<ProductStudentCustomer>> {
  const result = await api.get<PaginatedResponse<ProductStudentCustomer>>(
    `${PRODUCTS_BASE_URL}/${id}/customers?page=${page}&q=${encodeURIComponent(
      q
    )}&date_from=${dateFrom?.toISOString() ?? ''}&date_to=${
      dateTo?.toISOString() ?? ''
    }`
  );
  return result.data;
}

async function getProductEvents(
  id: number,
  page = 1,
  q: string,
  dateFrom: Date | null,
  dateTo: Date | null,
  ordering: string | null
): Promise<PaginatedResponse<EventResponse[]>> {
  let url = `${PRODUCTS_BASE_URL}/${id}/events?page=${page}`;
  if (q) url += `&q=${encodeURIComponent(q)}`;
  if (dateFrom) url += `&date_from=${dateFrom.toISOString()}`;
  if (dateTo) url += `&date_to=${dateTo.toISOString()}`;
  if (ordering) url += `&ordering=${encodeURIComponent(ordering)}`;
  const result = await api.get<PaginatedResponse<EventResponse[]>>(url);
  return result.data;
}

async function getProductPrivateSpace(
  id: number,
  page = 1,
  q: string,
  dateFrom: Date | null,
  dateTo: Date | null,
  ordering: string | null
): Promise<PaginatedResponse<any[]>> {
  let url = `${PRODUCTS_BASE_URL}/${id}/private-space?page=${page}`;
  if (q) url += `&q=${encodeURIComponent(q)}`;
  if (dateFrom) url += `&date_from=${dateFrom.toISOString()}`;
  if (dateTo) url += `&date_to=${dateTo.toISOString()}`;
  if (ordering) url += `&ordering=${encodeURIComponent(ordering)}`;
  const result = await api.get<PaginatedResponse<any[]>>(url);
  return result.data;
}

async function getProductReviews(
  id: number,
  page = 1,
  q: string,
  dateFrom: Date | null,
  dateTo: Date | null,
  ordering: string | null
): Promise<PaginatedResponse<ProductReviewResponse[]>> {
  const url = `${PRODUCTS_BASE_URL}/${id}/reviews`;
  const params = {
    page,
    q,
    date_from: dateFrom?.toISOString(),
    date_to: dateTo?.toISOString(),
    ordering,
  };
  const result = await api.get<PaginatedResponse<ProductReviewResponse[]>>(
    url,
    { params }
  );
  return result.data;
}

async function hideProductReview(
  productId: number,
  reviewId: number
): Promise<void> {
  await api.post(`${PRODUCTS_BASE_URL}/${productId}/reviews/${reviewId}/hide`);
}

async function unhideProductReview(
  productId: number,
  reviewId: number
): Promise<void> {
  await api.post(
    `${PRODUCTS_BASE_URL}/${productId}/reviews/${reviewId}/unhide`
  );
}

async function registerProductCustomersToEvent(
  id: number,
  eventId: number
): Promise<void> {
  const result = await api.post(
    `${PRODUCTS_BASE_URL}/${id}/events/${eventId}/register_all_customers`
  );
  return result.data;
}

async function getProductStudents(
  productId: number
): Promise<StudentResponse[]> {
  const result = await api.get<StudentResponse[]>(
    `${PRODUCTS_BASE_URL}/${productId}/students`
  );
  return result.data;
}
// Playlists
async function getPlaylists(productId: number): Promise<PlaylistResponse[]> {
  const result = await api.get<PlaylistResponse[]>(
    `${PRODUCTS_BASE_URL}/${productId}/playlists`
  );
  return result.data;
}
async function createPlaylist(
  productId: number,
  playlist: any
): Promise<PlaylistResponse> {
  const payload = {
    ...playlist,
    content: (playlist.content || []).map(c => c.id || c),
    cover_file: playlist.cover_file?.id,
  };
  const result = await api.post<PlaylistResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/playlists`,
    payload
  );
  return result.data;
}

async function updatePlaylist(
  productId: number,
  playlist: any
): Promise<PlaylistResponse> {
  const payload = {
    ...playlist,
    content: (playlist.content || []).map(c => c.id || c),
    cover_file: playlist.cover_file?.id || null,
  };
  const result = await api.put<PlaylistResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/playlists/${playlist.id}`,
    payload
  );
  return result.data;
}

async function deletePlaylist(
  productId: number,
  playlistId: number
): Promise<void> {
  await api.delete(`${PRODUCTS_BASE_URL}/${productId}/playlists/${playlistId}`);
}

async function reorderPlaylistContent(
  productId: number,
  playlistId: number,
  content: ReorderContentRequest[]
): Promise<ReorderContentResponse[]> {
  return await api.post(
    `${PRODUCTS_BASE_URL}/${productId}/playlists/${playlistId}/content/change_position`,
    content
  );
}

async function deletePlaylistContent(
  productId: number,
  playlistId: number,
  contentId: number
): Promise<void> {
  return await api.delete(
    `${PRODUCTS_BASE_URL}/${productId}/playlists/${playlistId}/content/${contentId}`
  );
}

// Lessons
async function createLesson(
  productId: number,
  payload: LessonRequest
): Promise<LessonResponse> {
  const result = await api.post<LessonResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/lessons`,
    payload
  );
  return result.data;
}
async function updateLesson(
  productId: number,
  payload: LessonRequest
): Promise<LessonResponse> {
  const result = await api.put<LessonResponse[]>(
    `${PRODUCTS_BASE_URL}/${productId}/lessons/${payload.id}`,
    payload
  );
  return result.data[0];
}
async function removeLessonContent(
  productId: number,
  lessonId: number,
  contentId: number
): Promise<void> {
  await api.delete(
    `${PRODUCTS_BASE_URL}/${productId}/lessons/${lessonId}/content/${contentId}`
  );
}
async function reorderLesson(
  productId: number,
  lessonsId: number,
  content: ReorderContentRequest[]
): Promise<ReorderContentResponse[]> {
  const result = await api.post<ReorderContentResponse[]>(
    `${PRODUCTS_BASE_URL}/${productId}/lessons/${lessonsId}/content/change_position`,
    content
  );
  return result.data;
}
async function deleteLesson(
  productId: number,
  lessonId: number
): Promise<void> {
  await api.delete(`${PRODUCTS_BASE_URL}/${productId}/lessons/${lessonId}`);
}
// included products
async function getIncludedProducts(
  productId: number
): Promise<ProductResponse[]> {
  const result = await api.get<ProductResponse[]>(
    `${PRODUCTS_BASE_URL}/${productId}/included-products`
  );
  return result.data;
}
async function addIncludedProducts(
  productId: number,
  payload: any
): Promise<ProductResponse[]> {
  const result = await api.post<ProductResponse[]>(
    `${PRODUCTS_BASE_URL}/${productId}/included-products`,
    payload
  );
  return result.data;
}

async function reorderIncludedProducts(
  bundleId: number,
  products: any
): Promise<ProductResponse[]> {
  const result = await api.post<ProductResponse[]>(
    `${PRODUCTS_BASE_URL}/${bundleId}/included-products/change_position`,
    products
  );
  return result.data;
}

async function deleteIncludedProduct(
  bundleId: number,
  productId: number
): Promise<ProductResponse[]> {
  const result = await api.delete<ProductResponse[]>(
    `${PRODUCTS_BASE_URL}/${bundleId}/included-products/${productId}`
  );
  return result.data;
}

// content
async function getProductContent(
  productId: number,
  page = 1,
  fileType: string | null,
  instructor: number | null,
  options: number | null,
  q: string | null
): Promise<PaginatedResponse<ContentResponse>> {
  let url = `${PRODUCTS_BASE_URL}/${productId}/content?page=${page}`;
  if (fileType) url += `&file_type=${encodeURIComponent(fileType)}`;
  if (instructor) url += `&instructor=${instructor}`;
  if (options) url += `&options=${options}`;
  if (q) url += `&q=${q}`;
  const result = await api.get<PaginatedResponse<ContentResponse>>(url);
  return result.data;
}

async function createProductContent(
  productId: number,
  payload: CreateContentRequest
): Promise<PaginatedResponse<ContentResponse>> {
  const result = await api.post<PaginatedResponse<ContentResponse>>(
    `${PRODUCTS_BASE_URL}/${productId}/content`,
    payload
  );
  return result.data;
}
async function deleteProductContent(
  productId: number,
  contentId: number
): Promise<void> {
  await api.delete(`${PRODUCTS_BASE_URL}/${productId}/content/${contentId}`);
}
async function reorderProductContent(
  productId: number,
  content: ReorderContentRequest[]
): Promise<ReorderContentResponse[]> {
  return await api.post(
    `${PRODUCTS_BASE_URL}/${productId}/content/change_position`,
    content
  );
}
// product sales page
async function createProductSalesPage(
  productId: number,
  payload: CreateUpdateSalesPageRequest
): Promise<ProductSalesPageResponse> {
  const result = await api.post<ProductSalesPageResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/sales-page`,
    payload
  );
  return result.data;
}
async function updateProductSalesPage(
  productId: number,
  salesPageId: number,
  payload: CreateUpdateSalesPageRequest
): Promise<ProductSalesPageResponse> {
  const result = await api.put<ProductSalesPageResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/sales-page/${salesPageId}`,
    payload
  );
  return result.data;
}

async function createSalePageSection(
  productId: number,
  salesPageId: number,
  section: string,
  payload:
    | CreateUpdateSalesPageIntroductionRequest
    | CreateUpdateSalesPageSolutionRequest
    | CreateUpdateSalesPageSignUpBannerRequest
    | CreateUpdateSalesPageResultRequest
    | CreateUpdateSalesPageBonusRequest
    | CreateUpdateSalesPageIncludedContentRequest
    | CreateUpdateSalesPageIncludedEventsRequest
    | CreateUpdateSalesPageIncludedLessonsRequest
    | CreateUpdateSalesPageTestimonialsRequest
    | CreateUpdateSalesPageInstructorsRequest
    | CreateUpdateSalesPageFAQsRequest
): Promise<ProductSalesPageSectionResponse> {
  const result = await api.post<ProductSalesPageSectionResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/sales-page/${salesPageId}/${section}`,
    payload
  );
  return result.data;
}

async function updateSalePageSection(
  productId: number,
  salesPageId: number,
  section: string,
  id: number,
  payload:
    | CreateUpdateSalesPageIntroductionRequest
    | CreateUpdateSalesPageSolutionRequest
    | CreateUpdateSalesPageSignUpBannerRequest
    | CreateUpdateSalesPageResultRequest
    | CreateUpdateSalesPageBonusRequest
    | CreateUpdateSalesPageIncludedContentRequest
    | CreateUpdateSalesPageIncludedEventsRequest
    | CreateUpdateSalesPageIncludedLessonsRequest
    | CreateUpdateSalesPageTestimonialsRequest
    | CreateUpdateSalesPageInstructorsRequest
    | CreateUpdateSalesPageFAQsRequest
): Promise<ProductSalesPageSectionResponse> {
  const result = await api.put<ProductSalesPageSectionResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/sales-page/${salesPageId}/${section}/${id}`,
    payload
  );
  return result.data;
}

async function createProductsSalesPageFAQ(
  productId: number,
  salesPageId: number,
  faqsId: number,
  payload: CreateUpdateSalesPageFAQRequest
): Promise<ProductSalesPageFAQResponse> {
  const result = await api.post<ProductSalesPageFAQResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/sales-page/${salesPageId}/faqs/${faqsId}/faq`,
    payload
  );
  return result.data;
}
async function updateProductsSalesPageFAQ(
  productId: number,
  salesPageId: number,
  faqsId: number,
  faqId: number,
  payload: CreateUpdateSalesPageFAQRequest
): Promise<ProductSalesPageFAQResponse> {
  const result = await api.put<ProductSalesPageFAQResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/sales-page/${salesPageId}/faqs/${faqsId}/faq/${faqId}`,
    payload
  );
  return result.data;
}
async function removeProductsSalesPageFAQ(
  productId: number,
  salesPageId: number,
  faqsId: number,
  faqId: number
) {
  return await api.delete(
    `${PRODUCTS_BASE_URL}/${productId}/sales-page/${salesPageId}/faqs/${faqsId}/faq/${faqId}`
  );
}
async function createProductsSalesPageTestimonial(
  productId: number,
  salesPageId: number,
  testimonialsId: number,
  payload: CreateUpdateSalesPageTestimonialRequest
): Promise<ProductSalesPageTestimonialResponse> {
  const result = await api.post<ProductSalesPageTestimonialResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/sales-page/${salesPageId}/testimonials/${testimonialsId}/testimonial`,
    payload
  );
  return result.data;
}
async function updateProductsSalesPageTestimonial(
  productId: number,
  salesPageId: number,
  testimonialsId: number,
  testimonialId: number,
  payload: CreateUpdateSalesPageTestimonialRequest
): Promise<ProductSalesPageTestimonialResponse> {
  const result = await api.put<ProductSalesPageTestimonialResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/sales-page/${salesPageId}/testimonials/${testimonialsId}/testimonial/${testimonialId}`,
    payload
  );
  return result.data;
}
async function removeProductsSalesPageTestimonial(
  productId: number,
  salesPageId: number,
  testimonialsId: number,
  testimonialId: number
) {
  return await api.delete(
    `${PRODUCTS_BASE_URL}/${productId}/sales-page/${salesPageId}/testimonials/${testimonialsId}/testimonial/${testimonialId}`
  );
}

async function purchaseFreeProduct(
  studioURL: string,
  productId: number,
  reCaptchaToken: string
): Promise<ProductDetailResponse> {
  const content = {
    token: reCaptchaToken,
  };
  return await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/purchase`,
    content
  );
}

async function verifyIntent(
  studioURL: string,
  productId: number,
  paymentIntentId: string | null,
  setupIntentId: string | null
): Promise<StripeIntentResponse> {
  const content = {
    payment_intent_id: paymentIntentId,
    setup_intent_id: setupIntentId,
  };
  const result = await api.post<StripeIntentResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/verify_intent`,
    content
  );
  return result.data;
}

async function createStripeSetupIntent(
  studioURL: string,
  credentials: any
): Promise<StripeIntentResponse> {
  const content = {};
  const result = await api.post<StripeIntentResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/products/setupintent`,
    content
  );
  return result.data;
}

async function getStripeSetupIntent(
  studioURL: string,
  params: any
): Promise<StripeIntentResponse> {
  const result = await api.get<StripeIntentResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/products/setupintent`,
    { params }
  );
  return result.data;
}

async function createGiftStripeSetupIntent(
  studioURL: string,
  customerEmail: any
): Promise<StripeIntentResponse> {
  const content = {
    customer_email: customerEmail,
  };
  const result = await api.post<StripeIntentResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/products/gift_setupintent`,
    content
  );
  return result.data;
}

async function purchaseProduct(
  coupon: string,
  studioURL: string,
  productId: number,
  recurringOption: string,
  daypass: boolean,
  isPaymentPlan: boolean,
  reCaptchaToken: string,
  paymentPlanId: number | null,
  customAmount?: number | null,
  useSavedCard?: boolean | null,
  rewardfulReferralId?: string | null
): Promise<ProductDetailResponse> {
  const payload = {
    coupon_code: coupon || null,
    recurring_option: recurringOption,
    daypass: daypass,
    is_payment_plan: isPaymentPlan,
    payment_plan_id: paymentPlanId,
    custom_amount: customAmount,
    token: reCaptchaToken,
    use_saved_card: useSavedCard,
    rewardful_referral_id: rewardfulReferralId,
  };
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/purchase`,
    payload
  );
  return result.data;
}

async function purchaseUpsellProduct(
  studioURL: string,
  productId: number
): Promise<ProductDetailResponse> {
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/upsell_purchase`
  );
  return result.data;
}

async function purchaseProductAsGift(
  defaultPaymentMethod: string,
  stripeCustomerId: string,
  coupon: string,
  studioURL: string,
  productId: number,
  recurringOption: string,
  daypass: boolean,
  isPaymentPlan: boolean,
  reCaptchaToken: string,
  paymentPlanId: number | null,
  customAmount: number | null,
  giftPayload: any,
  rewardfulReferralId: string | null
): Promise<ProductDetailResponse> {
  const payload = {
    default_payment_method: defaultPaymentMethod,
    stripe_customer_id: stripeCustomerId,
    coupon_code: coupon || null,
    recurring_option: recurringOption,
    daypass: daypass,
    is_payment_plan: isPaymentPlan,
    payment_plan_id: paymentPlanId,
    custom_amount: customAmount,
    token: reCaptchaToken,
    rewardful_referral_id: rewardfulReferralId,
    ...giftPayload,
  };
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/purchase_gift`,
    payload
  );
  return result.data;
}

async function validateStudentCoupon(
  studioURL: string,
  productId: number,
  couponCode: string
): Promise<StudentCouponValidationResponse> {
  const content = {
    coupon_code: couponCode,
  };
  const result = await api.post<StudentCouponValidationResponse>(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/validate-coupon`,
    content
  );
  return result.data;
}

async function canAddPrivateSession(
  studioURL: string,
  productId: number
): Promise<CanAddPrivateSessionResponse> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/can_add_private_session`
  );
  return result.data;
}

async function getPrivateSessions(
  studioURL: string,
  productId: number
): Promise<EventResponse[]> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/private_sessions`
  );
  return result.data;
}

async function getPrivateSchedulingTimeSlots(
  studioURL: string,
  productId: number,
  startDate: Date | null,
  endDate: Date | null,
  timezone: string
): Promise<any> {
  const result = await api.get(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/get_time_slots?tz=${encodeURIComponent(
      timezone || ''
    )}&start=${startDate?.toISOString()}&end=${endDate?.toISOString()}`
  );
  return result.data;
}

async function bookPrivateSchedulingSlot(
  studioURL: string,
  productId: number,
  slot: Date,
  timezone: string
): Promise<any> {
  const payload = {
    slot: slot.toISOString(),
    timezone,
  };
  const result = await api.post(
    `${STUDIOS_BASE_URL}/${studioURL}/products/${productId}/book_private_session`,
    payload
  );
  return result.data;
}

async function exportTeacherProductCustomers(
  product: number | null,
  q: string | null
): Promise<BlobPart> {
  const result = await api.get(
    `${PRODUCTS_BASE_URL}/${product}/customers/export_to_csv`,
    {
      params: {
        q,
        ordering: '-created',
      },
    }
  );
  return result.data;
}

async function exportTeacherProductPurchases(
  product: number | null,
  q: string | null,
  dateFrom: Date | null,
  dateTo: Date | null
): Promise<BlobPart> {
  const result = await api.get(
    `${PRODUCTS_BASE_URL}/${product}/payments/export_to_csv`,
    {
      params: {
        q,
        date_from: dateFrom?.toISOString(),
        date_to: dateTo?.toISOString(),
        ordering: '-created',
      },
    }
  );
  return result.data;
}

async function addPaymentPlan(
  productId: number,
  paymentPlan: PaymentPlanResponse
): Promise<PaymentPlanResponse> {
  const result = await api.post<PaymentPlanResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/payment-plans`,
    paymentPlan
  );
  return result.data;
}

async function updatePaymentPlan(
  productId: number,
  paymentPlan: PaymentPlanResponse
): Promise<PaymentPlanResponse> {
  const result = await api.put<PaymentPlanResponse>(
    `${PRODUCTS_BASE_URL}/${productId}/payment-plans/${paymentPlan.id}`,
    paymentPlan
  );
  return result.data;
}

async function removePaymentPlan(
  productId: number,
  paymentPlan: PaymentPlanResponse
): Promise<void> {
  const result = await api.delete<void>(
    `${PRODUCTS_BASE_URL}/${productId}/payment-plans/${paymentPlan.id}`
  );
  return result.data;
}

async function awardFreeClassCardRedemptions(productId: number) {
  const result = await api.post(
    `${PRODUCTS_BASE_URL}/${productId}/award_free_redemptions`,
    {}
  );
  return result.data;
}

async function emailCustomers(
  productId: number,
  subject: string,
  body: string
) {
  const payload = {
    subject: subject,
    body: body,
  };
  const result = await api.post(
    `${PRODUCTS_BASE_URL}/${productId}/email_customers`,
    payload
  );
  return result.data;
}

async function generateProductPage(productId: number, tone: string) {
  const payload = {
    tone: tone,
  };
  const result = await api.post(
    `${PRODUCTS_BASE_URL}/${productId}/generate_page`,
    payload
  );
  return result.data;
}

export default {
  getStudentPlaylists,
  getStudentPlaylist,
  getStudentProductsReviews,
  studentSubmitReview,
  getProductDetail,
  getProductBasic,
  getProductForPurchase,
  getProductMedia,
  getProductMediaPaginated,
  getProductMediaDetail,
  getProductPlaylistMediaDetail,
  trackProductView,
  checkLessonAsCompleted,
  checkLessonAsIncompleted,
  getFilterCategories,
  getStudioInstructors,
  getStudioInstructor,
  likeMedia,
  getTeacherProducts,
  getTeacherProductsWithPrivateSessions,
  getTeacherProductDetail,
  createProduct,
  updateProduct,
  deleteProduct,
  duplicateProduct,
  getPendingContracts,
  getProductGifts,
  getWaitlistEmails,
  exportWaitlistEmails,
  clearWaitlistEmails,
  addToWaitlist,
  getTeacherTaxRates,
  addTeacherTaxRate,
  updateTeacherTaxRate,
  getProductTags,
  addProductTag,
  updateProductTag,
  deleteProductTag,
  getTeacherCoupons,
  getTeacherCouponDetail,
  getTeacherCouponsStats,
  createCoupon,
  updateCoupon,
  deleteCoupon,
  getCouponsUsage,
  getProductCustomers,
  getProductEvents,
  getProductPrivateSpace,
  getProductReviews,
  hideProductReview,
  unhideProductReview,
  registerProductCustomersToEvent,
  getProductPurchases,
  getProductStudents,
  getPlaylists,
  createPlaylist,
  updatePlaylist,
  deletePlaylist,
  reorderPlaylistContent,
  deletePlaylistContent,
  createLesson,
  updateLesson,
  removeLessonContent,
  reorderLesson,
  deleteLesson,
  getIncludedProducts,
  addIncludedProducts,
  reorderIncludedProducts,
  deleteIncludedProduct,
  getProductContent,
  createProductContent,
  deleteProductContent,
  reorderProductContent,
  purchaseFreeProduct,
  createProductSalesPage,
  updateProductSalesPage,
  createSalePageSection,
  updateSalePageSection,
  createProductsSalesPageFAQ,
  updateProductsSalesPageFAQ,
  removeProductsSalesPageFAQ,
  createProductsSalesPageTestimonial,
  updateProductsSalesPageTestimonial,
  removeProductsSalesPageTestimonial,
  purchaseProduct,
  purchaseUpsellProduct,
  purchaseProductAsGift,
  validateStudentCoupon,
  verifyIntent,
  createStripeSetupIntent,
  getStripeSetupIntent,
  createGiftStripeSetupIntent,
  canAddPrivateSession,
  getPrivateSessions,
  getPrivateSchedulingTimeSlots,
  bookPrivateSchedulingSlot,
  exportTeacherProductCustomers,
  exportTeacherProductPurchases,
  addPaymentPlan,
  updatePaymentPlan,
  removePaymentPlan,
  emailCustomers,
  awardFreeClassCardRedemptions,
  generateProductPage,
  getProductLesson,
  getProductLessons
};
